import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../../assets/images/admin/Reap_logo.png';
import AuthAdmin from '../AdminAuth/adminAuth';
import Modal from 'react-bootstrap/Modal';
import { Dropdown } from 'react-bootstrap';
import './style.css';

const Header = () => {
	const [mobmenuActive, setMobmenu] = useState(false);
	const { adminLogout } = AuthAdmin();

	const [openNotifi, setopenNotifi] = useState(false);
	// const notifiMenu = () => {
	// 	setopenNotifi(!openNotifi);
	// 	setArrowDatabase(!arrowDatabase);
	// };

	const dropdownRef = useRef(null);
	const toolMenuRef = useRef(null);
	const [show, setShow] = useState(false);
	const [currentPath, setCurrentPath] = useState('companies');
	const [arrowDatabase, setArrowDatabase] = useState(false);

	useEffect(() => {
		function handleClickOutside(event) {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				setopenNotifi(false);
			}
		}

		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [dropdownRef]);

	const mobileMenuFun = () => {
		setMobmenu(!mobmenuActive);
	};
	const MenuClose = () => {
		setMobmenu(!mobmenuActive);
	};

	const handleDatabaseClick = () => {
		setCurrentPath('databases');
		setopenNotifi(false);
		setArrowDatabase(false);
	};

	const logout = () => {
		adminLogout();
	};

	return (
		<div className="header">
			<div style={{ padding: '0px 30px' }}>
				{window.innerWidth > 768 ? (
					<div className="row">
						<div className="col-md-12 header-left-sec">
							{/* <Link to="/cms/companies">
								<img src={Logo} className="logo" />
							</Link> */}
							<ul
								className="list-inline float-end"
								style={{ padding: '5px 0px 0px 0px' }}
							>
								<li
									className="list-inline-item"
									onClick={() => setCurrentPath('companies')}
								>
									<Link
										to="/cms/companies"
										className="workspace"
										style={{
											color: currentPath == 'companies' ? '#294D8F' : 'gray',
										}}
									>
										Companies
									</Link>
								</li>
								{/* <li
									className="list-inline-item"
									onClick={() => setCurrentPath('tc')}
								>
									<Link
										to="/cms/tcs"
										className="workspace"
										style={{
											color: currentPath == 'tc' ? '#294D8F' : 'gray',
										}}
									>
										TCs
									</Link>
								</li> */}
								<li
									onClick={() => setCurrentPath('databases')}
									className="list-inline-item"
									ref={dropdownRef}
									style={{ textAlign: 'center' }}
								>
									{/* <button className="dropdown_btn" style={{}}>
										Databases
										
									</button> */}
									<Dropdown>
										<Dropdown.Toggle
											id="dropdown-basic"
											className="dropdown_btn custom-toggle"
											style={{
												fontSize: '18px',
												marginBottom: '5px',
												marginLeft: '6px',

												paddingRight: '0px',
												color: currentPath == 'databases' ? '#294D8F' : 'gray',
												background: 'none',
												border: 'none',
											}}
										>
											Databases
											{/* <span
												style={{ paddingLeft: '10px' }}
												className={arrowDatabase ? 'arrow_down' : 'arrow_up'}
											></span> */}
										</Dropdown.Toggle>

										<Dropdown.Menu
											className="mt-2"
											onClick={handleDatabaseClick}
										>
											<Dropdown.Item className="py-1">
												<Link style={{ color: 'gray' }} to="/cms/resource">
													Resources
												</Link>
											</Dropdown.Item>
											<Dropdown.Item className="py-1">
												<Link style={{ color: 'gray' }} to="/cms/process">
													Process
												</Link>
											</Dropdown.Item>
											<Dropdown.Item className="py-1">
												<Link style={{ color: 'gray' }} to="/cms/waste">
													Waste & Hazard
												</Link>
											</Dropdown.Item>
											<Dropdown.Item className="py-1">
												<Link style={{ color: 'gray' }} to="/cms/observation">
													Observations
												</Link>
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
									{/* {openNotifi ? (
										<ul className="dropdown_list_database">
											<li onClick={handleDatabaseClick}>
												<Link to="/cms/resource">Resources</Link>
											</li>
											<li onClick={handleDatabaseClick}>
												<Link to="/cms/process">Process</Link>
											</li>
											<li onClick={handleDatabaseClick}>
												<Link to="/cms/waste">Waste</Link>
											</li>
											<li onClick={handleDatabaseClick}>
												<Link to="/cms/observation">Observations</Link>
											</li>
										</ul>
									) : null} */}
								</li>

								<li
									className="list-inline-item"
									onClick={() => setCurrentPath('Activity')}
								>
									<Link
										className="workspace"
										to="/cms/activity-log"
										style={{
											color: currentPath == 'Activity' ? '#294D8F' : 'gray',
										}}
									>
										Activity Log
									</Link>
								</li>
								{/* <li
									className="list-inline-item"
									style={{ marginTop: '2.5px' }}
									onClick={() => setShow(true)}
								>
									<Link className="primary_btn">Log Out</Link>
								</li> */}
							</ul>
						</div>
					</div>
				) : (
					<div>
						<div className="col-md-6 header-left-sec">
							<ul className="list-inline ">
								<li className="list-inline-item">
									{/* <Link to="/cms/login">
										<img src={Logo} className="logo" />
									</Link> */}
								</li>
								<li>
									<div className="hambergerMenu" onClick={mobileMenuFun}>
										<div className="notifiRedicon"></div>
										<span></span>
										<span></span>
										<span></span>
									</div>
									<div
										className={
											mobmenuActive
												? 'mobileMenu mobileMenuActive'
												: 'mobileMenu '
										}
									>
										<div className="mobileMenudisplay">
											<div className="menuOpenwrapper">
												<div className="closeMenu">
													<div className="hambergerMenu" onClick={MenuClose}>
														<div className="notifiRedicon"></div>
														<span></span>
														<span></span>
														<span></span>
													</div>
												</div>
												<h2>Menu</h2>
											</div>
											<ul className="mobileMenu-cat">
												<li>
													<Link to="/">Home</Link>
												</li>
												<li>
													<Link to="/">Notifications</Link>
													<label className="notif-label">10</label>
												</li>
												<li>
													<Link to="/">Help</Link>
												</li>
												<li>
													<ul>
														<li>
															<Link to="/">View Profile</Link>
														</li>
														<li>
															<Link to="/">Change Language</Link>
														</li>
														<li>
															<Link to="/">Change Password</Link>
														</li>
													</ul>
												</li>
											</ul>
											<ul className="mobLogout">
												<li
													className="mobLogPadding"
													onClick={() => setShow(true)}
												>
													Logout
												</li>
												<li>
													<ul>
														<li>About RECO Wizard</li>
														<li>Version 1.0</li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				)}
			</div>
			{/* logout confirmation */}
			<Modal
				className="deactivateCompany"
				show={show}
				onHide={() => setShow(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="row">
						<div className="col-12 ">
							<h4 className="text-center">Do you really want to Log Out?</h4>

							<div style={{ textAlign: 'center', marginTop: '15px' }}>
								<button
									className="primary_btn small_btn"
									style={{ padding: '10px' }}
									onClick={logout}
								>
									Yes
								</button>
								<button
									className="primary_btn small_btn"
									onClick={() => setShow(false)}
								>
									No
								</button>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default Header;
