import React from 'react';
import './Note.css';

const NotePDF = ({ tcname, company, userSignature }) => {
	return (
		<div>
			<div className="custom_container_table scp_imprvoment">
				<div>
					<p className="note_txt">NOTE:</p>
					<p className="note_desc">
						The savings total shown above does not include the savings potential
						from several other possible measures that have been not quantified
						yet. Actual savings potential is higher than the total shown above;
						likewise, the total investment required may vary.
					</p>
					<p className="note_desc">
						Some of the savings have been estimated based on presently available
						data.
					</p>
					<p className="note_desc">
						In general, RECP team has been conservative in estimating the
						monetary savings. Where the resource saving is shown as a range, the
						lower figure is used for calculating monetary savings. The company
						will certainly be able to achieve higher monetary savings than shown
						in above table, by implementing all the listed recommendations.
					</p>
				</div>
				<div className="sign_box">
					<div className="submitd_box">
						<p className="submitdby_txt">Submitted by:</p>
						{/* <img src={project_implement_img1} style={{ width: "120px", height: "75px" }} alt="Sign" /> */}
						{/* {pdfDaTa && <img src={pdfDaTa} style={{ width: "120px", height: "50px" }} alt="Sign" />} */}
						{userSignature && (
							<img
								src={`data:image/jpg;base64,${userSignature}`}
								style={{ width: '120px', height: '50px' }}
								alt="Sign"
							/>
						)}
						<hr className="sign_line" />
						{tcname &&
							tcname.map((i, index) => (
								<p className="teconslt_txt" key={index}>
									Technical Consultant:{i.tc_name}
								</p>
							))}
					</div>
					<div className="submitd_box">
						<p className="submitdby_txt_right">Approved by:</p>

						{company &&
							company.map((i, index) => (
								<p className="ste_name" key={index}>
									{i.approved_by}
								</p>
							))}

						<hr className="sign_line" />
						<p className="ste_txt">Review Consultant</p>
						{company &&
							company.map((i, index) => (
								<p className="ste_txt" key={index}>
									Approval Date:{i.approved_date}
								</p>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};
export default NotePDF;
