// useNavigation.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useNavigation = (callback) => {
	const location = useLocation();

	useEffect(() => {
		// This effect will run whenever the route changes
		callback(location);
	}, [location, callback]);
};

export default useNavigation;
