import React from 'react';
import './ProcessFlow.css';
import sidewaysarrow from '../../../assets/pdf_images/sidewaysarrow.png';
import downarrow from '../../../assets/pdf_images/Main down arrow for process.png';

const ProcessFlowPDF = ({ pdfDataProcessflow, company }) => {
	return (
		<div
			style={{
				display: 'block',
				pageBreakBefore: 'always',
				position: 'relative',
			}}
		>
			<div>
				<p
					className="processflow_header"
					style={{ color: '#3B7E80', fontSize: '18px' }}
				>
					3. Process Flow Diagram with Resource Mapping
				</p>
				<p className="text_article_two_proces">
					The company's production process involves the following steps.
				</p>

				<div className="flowdiagram_header_diagram">
					<span className="flowdiagram_header processdiagram">
						<p className="flowdiagram_headertxt">Resources</p>
						<p className="flowdiagram_headertxt">Process</p>
						<p className="flowdiagram_headertxt">Waste & Hazards</p>
					</span>
					<div className="row">
						{pdfDataProcessflow?.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div className="col-3 mb-3">
										{JSON.parse(item?.resource_json).map((item, index) => {
											// console.log(item.resource_name)
											return (
												<p
													className="flowdiagram_left_txt_processflow"
													key={index}
												>
													{item?.resource_name}
												</p>
											);
										})}
									</div>
									{/* <p className="gg-arrow-long-right-processflow"></p> */}
									<img
										src={sidewaysarrow}
										style={{ width: '43px', height: '12px' }}
										className="arrows"
									/>
									{/* <img src={sideArrow} /> */}
									<div className="col-3 arrow mb-3">
										<p
											className="flowdiagram_mid_txt d-flex align-items-center justify-content-center"
											style={{ marginBottom: '15px', color: '#3B7E80' }}
										>
											{item.process_name}
										</p>

										{index == pdfDataProcessflow.length - 1 ? (
											''
										) : (
											<img src={downarrow} className="downarrow" />
										)}
									</div>
									{/* < p className="gg-arrow-long-right2-processflow" ></p> */}

									<img
										src={sidewaysarrow}
										style={{ width: '43px', height: '12px' }}
										className="arrows"
									/>
									{/* <img src={sideArrow} /> */}

									<div className="col-3 mb-3">
										{item.waste_json.map((waste, index) => {
											// console.log("item.waste_json", item.waste_json)
											return (
												<p
													className="flowdiagram_right_txt_processflow d-flex align-items-center text-align-center"
													key={index}
												>
													{' '}
													{waste.waste_name} <br />
												</p>
											);
										})}
									</div>
								</React.Fragment>
							);
						})}
					</div>
				</div>
				{/* {pdfDaTa && pdfDaTa.map((i) => {
                return (
                    <div className="flowdiagram_processflow flowdiagram_header_diagram">
                     
                        {resouce && resouce.map((i) => <p key={i.resource_id} className="flowdiagram_mid_txt_right">{i.resource_name}</p>)}
                      
                        <p className="gg-arrow-long-right-processflow"></p>
                       
                        <p className="flowdiagram_mid_txt">{i.process_name}</p>
                        <p className="gg-arrow-long-right2-processflow"></p>
                        
                        {waste && waste.map((i) => <p key={i.waste_id} className="flowdiagram_right_txt_processflow">{i.waste_name}<br /></p>)}
                        
                    </div>
                )
            })} */}

				{company &&
					company.map((i, index) => {
						return (
							<div key={index}>
								{i.process_caption && (
									<p className="fig_text">Figure 1: {i.process_caption}</p>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
};
export default ProcessFlowPDF;
