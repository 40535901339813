import React, { useEffect, useState, useRef, useContext } from 'react';
import AdminDataService from '../../../../services/AdminDataServices';
import ProcessExcel from '../../../../assets/excel_files/process.xlsx';
import Database from './database';
import { GlobalContext } from '../../../../context/GlobalState';

const Process = () => {
	const columnsArray = ['Process Name', 'Activation Status', 'Is Custom ?'];
	const adminApi = new AdminDataService();
	const [process, setProcess] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { currentLanguageId } = useContext(GlobalContext);

	const processRef = useRef({});

	useEffect(() => {
		document.title = 'Process | RECO Wizard';

		async function getResources() {
			let language_id = 2;
			if (localStorage.getItem('language_id')) {
				language_id = localStorage.getItem('language_id');
			} else {
				language_id = currentLanguageId;
			}
			try {
				const result = await adminApi.getProcessData(language_id);
				setProcess(result?.data?.process);
				setIsLoading(false);
				processRef.current = { ...processRef, 0: result?.data?.process };
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}
		setIsLoading(true);
		getResources();
	}, []);

	return (
		<Database
			themeArray={process}
			setThemeArray={setProcess}
			themeRef={processRef}
			columnsArray={columnsArray}
			type="process"
			excelFile={ProcessExcel}
			name="process_name"
			id="process_id"
			placeholder="Search by Process Name"
			api={adminApi.uploadProcessExcel}
			status="process_status"
			isLoading={isLoading}
			setIsLoading={setIsLoading}
		/>
	);
};

export default Process;
