import { useEffect } from 'react';

function useIframeResize() {
	useEffect(() => {
		const calculateAndSendHeight = (param) => {
			console.log(param);
			// Calculate the content height
			let contentHeight = document.body.scrollHeight;
			if (contentHeight < 500) {
				contentHeight = 660;
			}
			window.parent.postMessage(
				{ type: 'CONTENT_HEIGHT', height: contentHeight },
				'*'
			);
		};

		// Initial height calculation
		calculateAndSendHeight();

		// Add event listeners for more accurate height calculation
		window.addEventListener('load', calculateAndSendHeight('load'));
		window.addEventListener('resize', calculateAndSendHeight('resize'));

		// Clean up event listeners on component unmount
		return () => {
			window.removeEventListener('load', calculateAndSendHeight);
			window.removeEventListener('resize', calculateAndSendHeight);
		};
	}, []);
}

export default useIframeResize;
