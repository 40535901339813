//import React from 'react';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import clStyles from './clStyles.module.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useState } from 'react';
import Loader from '../../assets/images/loadin_gif.gif';
import { useLanguageChange } from '../customHooks/lan_hook';
import AuthUser from '../authUser/authuser';

const ChooseLanguage = ({ languageData }) => {
	const { t } = useTranslation();
	let navigate = useNavigate();
	const { logout, token, expiryT } = AuthUser();
	useLanguageChange();
	const [langParam, setLangParams] = useState(localStorage.getItem('lan'));
	const handleChangeLanguage = (lang_param) => {
		setLangParams(lang_param);
		i18next.changeLanguage(lang_param);
		localStorage.setItem('lan', lang_param);
		if (lang_param == 'ru') {
			i18next.changeLanguage(lang_param);
			localStorage.setItem('lanId', 2);
			window.location.reload(true);
		} else {
			localStorage.setItem('lanId', 2);
			i18next.changeLanguage(lang_param);
			window.location.reload(true);
		}
	};
	const languageChange = () => {
		i18next.changeLanguage(langParam);
		localStorage.setItem('lan', langParam);
		let exe = localStorage.getItem('expiry');
		if (new Date(exe) < Date.now()) {
			navigate('/login');
		} else {
			navigate('/dashboard');
		}

		//navigate('/dashboard');
	};
	return (
		<div
			className={'d-block' + ' ' + clStyles.chooseLanguage}
			style={{ height: '78.5vh' }}
		>
			<div className="container">
				<div className="row">
					<div className="col-12 mt-5 mb-5">
						<h1>{t('chooselanguageText')}</h1>
						<p>{t('chooseLanguageTextP')}</p>
					</div>
					<div className={'col-12 mb-5 text-center selectLang'}>
						{languageData.length != 0 ? (
							<ToggleButtonGroup
								type="radio"
								name="options"
								defaultValue={langParam}
							>
								{languageData.length != 0
									? languageData.map((item, index) => (
											<ToggleButton
												key={index}
												id={'tbg-radio-' + item.language_id}
												value={item.language_id == 1 ? 'ru' : 'en'}
												onClick={() =>
													handleChangeLanguage(
														item.language_id == 1 ? 'ru' : 'en'
													)
												}
											>
												{item.langauge_name}
											</ToggleButton>
									  ))
									: null}
							</ToggleButtonGroup>
						) : (
							<img src={Loader} alt="loader" style={{ width: '150px' }} />
						)}
					</div>
					<div className="col-12 text-center">
						<button
							type="submit"
							className={'primary_btn' + ' ' + clStyles.confirm}
							onClick={languageChange}
						>
							{t('Confirm')}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ChooseLanguage;
