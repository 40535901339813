import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DataService from '../../services/DataServices';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../authUser/authuser';
import { ErrorLog } from '../erroLog/errorLog';

const TcBreadCrumb = ({ title }) => {
	const { logout } = AuthUser();
	const navigate = useNavigate();
	const api = new DataService();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
	let currentPath = pathName.split('/');

	const [breadCrumb, setCompBreadCrumb] = useState({
		comp_name: '',
		comp_code: '',
		current_url: currentPath[1],
		url_level_two: currentPath[2],
	});
	const getCompanyDetailsFunc = async () => {
		let data = {
			company_id: com_id,
		};
		await api
			.getcompanybyid(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let com_data = response.data.company;
					setCompBreadCrumb({
						...breadCrumb,
						comp_name: com_data.length > 0 && com_data[0].company_name,
						comp_code: com_data.length > 0 && com_data[0].company_code,
					});
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let errMsg = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						let errorLogData = {
							api_name: 'getcompanybyid',
							error_code: errorCode,
							error_message: errMsg,
						};
						ErrorLog(errorLogData);
					}
				}
			});
	};
	const goBack = () => {
		navigate(-1);
	};
	useEffect(() => {
		getCompanyDetailsFunc();
	}, []);
	return (
		<div>
			{window.innerWidth > 768 ? (
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<nav>
								<ol className="breadcrumb">
									<li className="breadcrumb-item active">
										<Link to="/dashboard" style={{ color: '#5A5A5A' }}>
											Dashboard
										</Link>
									</li>
									{breadCrumb.comp_code && (
										<li className="breadcrumb-item active" aria-current="page">
											<Link
												to={'/CompanyDetails/' + com_id}
												style={{ color: '#5A5A5A' }}
											>
												{breadCrumb.comp_code}
											</Link>
										</li>
									)}
									{breadCrumb.current_url && (
										<li className="breadcrumb-item " aria-current="page">
											<Link
												to={
													breadCrumb.current_url === 'checkpoints' &&
													'/checkpoints/' + com_id
												}
												style={{
													color: '#5A5A5A',
													fontWeight: '500',
													textTransform: 'capitalize',
												}}
											>
												{breadCrumb.current_url}
											</Link>
										</li>
									)}
									{breadCrumb.url_level_two &&
									Number.isInteger(parseInt(breadCrumb.url_level_two)) ==
										false ? (
										<li className="breadcrumb-item active" aria-current="page">
											{breadCrumb.url_level_two}
										</li>
									) : null}
								</ol>
							</nav>
						</div>
						<div className="col-md-4 text-end breadcrumb_right_info">
							<h3>
								{breadCrumb.comp_name}
								<span>
									<Link
										to={'/CompanyDetails/' + com_id}
										style={{ fontSize: '10px' }}
									>
										Company's Full Details
									</Link>
								</span>
							</h3>
						</div>
					</div>
					{title ? (
						<div className="row mb-3">
							<div className="col-md-12">
								<h3
									className="mainTitle"
									style={{ color: '#294D8F', fontFamily: 'Roboto,Bold' }}
								>
									{title}
								</h3>
							</div>
						</div>
					) : null}
				</div>
			) : (
				<div className="container">
					<div className="row mb-3">
						<div className="col-md-12 px-0 breadcrumbMobile">
							<i className="fa fa-angle-left leftArrow" onClick={goBack}></i>
							<h3 className="mainTitlefront">{title}</h3>
							<h5 className="subTitle">{breadCrumb.comp_name}</h5>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
export default TcBreadCrumb;
