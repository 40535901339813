// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clStyles_chooseLanguage__DD8KS h1 {
	color: #294d8f;
	font-size: 24px;
	font-weight: 600;
	text-align: center;
}
.clStyles_chooseLanguage__DD8KS p {
	color: #6d6d6d;
	font-size: 14px;
	text-align: center;
}
button.clStyles_confirm__RkL\\+Y {
	width: 100%;
	max-width: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/components/chooseLanguage/clStyles.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,kBAAkB;AACnB;AACA;CACC,cAAc;CACd,eAAe;CACf,kBAAkB;AACnB;AACA;CACC,WAAW;CACX,gBAAgB;AACjB","sourcesContent":[".chooseLanguage h1 {\r\n\tcolor: #294d8f;\r\n\tfont-size: 24px;\r\n\tfont-weight: 600;\r\n\ttext-align: center;\r\n}\r\n.chooseLanguage p {\r\n\tcolor: #6d6d6d;\r\n\tfont-size: 14px;\r\n\ttext-align: center;\r\n}\r\nbutton.confirm {\r\n\twidth: 100%;\r\n\tmax-width: 300px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chooseLanguage": `clStyles_chooseLanguage__DD8KS`,
	"confirm": `clStyles_confirm__RkL+Y`
};
export default ___CSS_LOADER_EXPORT___;
