import React, { useState, useEffect, useContext, useRef } from 'react';
import TCStyles from './tc.module.css';
import plus from '../../../../assets/images/admin/plus.svg';
import Search from '../../../../assets/images/admin/search.png';
import Modal from 'react-bootstrap/Modal';
import { GlobalContext } from '../../../../context/GlobalState';
import { MemoizedTcRow } from './TcTable';
import AddTc from './AddTc';
import PaginationComponent from '../ActivityLog/Pagination';

const Tcs = () => {
	const columnsArray = [
		'Name of TC',
		'Region',
		'Country',
		'Email',
		'Phone Number',
		'Companies Handled',
	];

	useEffect(() => {
		document.title = 'TC | RECO Wizard';

		//getcountry and region list
	}, []);

	//importing context variable to set Success Message
	const { tcList, setTcList, tcListRef } = useContext(GlobalContext);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const tcref = useRef({});
	tcref.current = { ...tcListRef.current, 0: tcList };

	let key = '';

	const handleKeyDown = (e) => {
		key = e.key;
	};
	const filterTable = ({ target: { value } }) => {
		const upperCaseValue = value.toUpperCase();
		if (value.length > 0) {
			if (key === 'Backspace') {
				setTcList(tcListRef.current[value.length]);
			} else {
				const filteredTCList = tcList.filter(
					(tc) =>
						tc?.region_name?.toUpperCase().includes(upperCaseValue) ||
						tc?.country_name?.toUpperCase().includes(upperCaseValue) ||
						tc?.user_first_name?.toUpperCase().includes(upperCaseValue) ||
						tc?.user_email?.toUpperCase().includes(upperCaseValue) ||
						tc?.user_contact?.toUpperCase().includes(upperCaseValue)
				);
				setTcList(filteredTCList);
				tcListRef.current = {
					...tcListRef.current,
					[value.length]: filteredTCList,
				};
			}
		} else {
			setTcList(JSON.parse(localStorage.getItem('tclist')));
		}
	};

	//pagination set up
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// Logic to get the current items to display based on the current page
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = tcList?.slice(indexOfFirstItem, indexOfLastItem);

	// Function to handle page changes
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="deskWrapper">
			<div className="container">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						marginBottom: '30px',
						justifyContent: 'space-between',
					}}
				>
					<div>
						<div
							className={'input-group' + ' ' + TCStyles.searchBox}
							style={{
								display: 'flex',
								flexDirection: 'row',
								border: '4px solid #ced4da',
								borderRadius: '5px',
							}}
						>
							<span
								className={'input-group-text' + ' ' + TCStyles.searchIcon}
								style={{ border: 'none' }}
							>
								<img src={Search} alt="Search" />
							</span>
							<input
								type="text"
								className={TCStyles.search}
								placeholder="Search by Name of TC, Region, Country, Email"
								onKeyDown={(e) => handleKeyDown(e)}
								onChange={(e) => filterTable(e)}
								style={{ border: 'none' }}
							/>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<button
							className={'success_btn' + ' ' + TCStyles.addnewSTE}
							onClick={handleShow}
							style={{ border: '3px solid #294D8F' }}
						>
							<img
								src={plus}
								alt="Plus"
								style={{ margin: '0px 10px 4px 0px', width: '17px' }}
							/>
							<b style={{ fontSize: '16px' }}>Add New TC</b>
						</button>

						<Modal
							className="tclist"
							show={show}
							onHide={handleClose}
							size="xl"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header closeButton>
								<Modal.Title>
									<h2>Add New TC to Master List</h2>
								</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<AddTc show={show} setShow={setShow} />
							</Modal.Body>
						</Modal>
					</div>
				</div>
				{currentItems?.length > 0 ? (
					<div className="row">
						<div
							className={'col-md-12 mt-3' + ' ' + TCStyles.tableWrapper}
							style={{
								border: '4px solid #b8b8b8',
								borderBottom: '1px solid #b8b8b8',
							}}
						>
							<table className={TCStyles.iarTable}>
								<thead>
									<tr className={TCStyles.tr} style={{ height: '60px' }}>
										{columnsArray.map((column, index) => (
											<th
												className={TCStyles.th}
												key={index}
												style={{ fontSize: '18px', whiteSpace: 'nowrap' }}
											>
												{column}
											</th>
										))}
										<th className={TCStyles.th} />
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((tc, index) => (
										<MemoizedTcRow tc={tc} index={index} key={index} />
									))}
								</tbody>
							</table>
						</div>
						<PaginationComponent
							itemsPerPage={itemsPerPage}
							totalItems={tcList?.length}
							paginate={paginate}
							activePage={currentPage}
						/>
					</div>
				) : (
					<div
						style={{
							textAlign: 'center',
							fontSize: '24px',
							fontWeight: '500',
						}}
					>
						There is no data to show at the moment.
					</div>
				)}
			</div>
		</div>
	);
};
export default Tcs;
