import { useState, useEffect } from 'react';
import i18next from 'i18next';

export const useLanguageChange = () => {
	const [lang, setlang] = useState('en');
	const [lanId, setLangId] = useState();

	useEffect(() => {
		const setlanguageFunc = (lang) => {
			i18next.changeLanguage(lang);
			setlang(lang);
			setLangId(2);
		};
		setlanguageFunc(lang);
	}, [lang, lanId]);

	return { lang, lanId };
};
