import React, { useEffect, useState } from 'react';
import IARStyles from './iar.module.css';
import DataService from '../../services/DataServices';
import { useLocation } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import AuthUser from '../authUser/authuser';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';
import Modal from 'react-bootstrap/Modal';
import useIframeResize from '../customHooks/useIframeResize';
import useNavigation from '../customHooks/useLocationHook';
import { useCookies } from 'react-cookie';

const OnsitePhotographs = ({ tabActive }) => {
	useIframeResize();
	const { logout } = AuthUser();
	const perChunk = 2;
	const { t } = useTranslation();
	useLanguageChange();
	const [, setCookie] = useCookies(['lastLocation']);
	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;

		setCookie('lastLocation', currentPath, {
			path: '/', // Set the cookie to be accessible across the domain
			// domain: 'localhost',
			domain: 'sustent.in', // Replace with your actual domain
			secure: true, // Set to true for secure cookies (HTTPS)
			sameSite: 'none',
		});
		// You can store the currentPath in state or perform other actions here
	});
	const api = new DataService();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
	const [onSitegallery, setOnSitegallery] = useState([]);
	const [Loader, setLoader] = useState(false);
	const [InternetError, setInternetError] = useState('');
	const [Errorpopup, setErrorpopup] = useState(false);

	const GetOnsiteGalleryData = async () => {
		setLoader(true);
		let data = {
			company_id: comp_url,
		};
		await api
			.getOnsiteGallery(data)
			.then((res) => {
				if (res.status == 200 || res.status == 201) {
					let gallery = res.data.Contents;
					setOnSitegallery(gallery);
					setLoader(false);
				}
			})
			.catch((error) => {
				if (error.code != 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let errMsg = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						setOnSitegallery([]);
						setLoader(false);
						let errorLogData = {
							api_name: 'getonsitegallerydata',
							error_code: errorCode,
							error_message: errMsg,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setErrorpopup(true);
					setInternetError(
						error.message + '. Please check your internet connection.'
					);
					setLoader(false);
				}
			});
	};
	const result =
		onSitegallery &&
		onSitegallery.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk);

			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []; // start a new chunk
			}

			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);

	let tabeleContent = '';
	tabeleContent = (
		<>
			{result &&
				result.map((item, index) => (
					<tr key={index}>
						{item.map((subItem, idx) => (
							<td key={idx} className={IARStyles.imageOnsite}>
								<img
									src={subItem.gallery_url}
									alt={subItem.gallery_caption}
									className="img-fluid "
								/>
								{console.log(subItem.gallery_name, subItem.gallery_caption)}
								<p className="mt-3">
									<span>{subItem.gallery_name}: </span>
									{subItem.gallery_caption}
								</p>
							</td>
						))}
					</tr>
				))}
		</>
	);
	useLayoutEffect(() => {
		if (tabActive === 'osp') {
			GetOnsiteGalleryData();
		}
	}, [tabActive]);

	return (
		<div className="login">
			<div className="container">
				<div className="row">
					<div
						className="col-md-12 text-center scrollCustom"
						style={{ height: 'calc(100vh - 344px)', overflowY: 'auto' }}
					>
						{Loader == false ? (
							<div className={IARStyles.iarTablecurv}>
								<table
									className={
										'table customBordered onsitePhoto' +
										' ' +
										IARStyles.iarTable
									}
								>
									{onSitegallery.length > 0 &&
									Object.keys(onSitegallery[0]).length !== 0 ? (
										<tbody>{tabeleContent}</tbody>
									) : (
										<tbody>
											<tr>
												<td>{t('noImage')}</td>
											</tr>
										</tbody>
									)}
								</table>
							</div>
						) : (
							<img src={LoaderImg} alt="loader" style={{ width: '180px' }} />
						)}
					</div>
				</div>
			</div>
			<Modal
				className="newObservation removeConform"
				show={Errorpopup}
				onHide={() => setErrorpopup(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{InternetError}</h4>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default OnsitePhotographs;
