// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companydetail_companydetail_txt__pZ0DV {
	font-family: 'Robotomedium';
	color: #294d8f;
}

.companydetail_company_name__pcm1P {
	font-size: 20px;
	font-family: 'Robotomedium';
}

.companydetail_company_details_subtxt__8fTqR {
	font-size: 14px;
	font-family: 'Robotomedium';
}

.companydetail_company_adress__XKaHl {
	font-family: 'Roboto';
	font-size: 13px;
	color: #5a5a5a;
}

.companydetail_contact_persons_details__DUNOo {
	margin-top: 40px;
}

.companydetail_contact_person_txt__zky8X {
	font-size: 15px;
	font-family: 'Robotomedium';
	color: #327072;
}

.companydetail_contact_person_lhs__D5tu3 {
	font-family: 'Robotomedium';
	color: #294d8f;
	font-size: 12px;
}

.companydetail_contact_person_rhs__ZhzH2 {
	font-size: 11px;
	padding-left: 8px;
}
.companydetail_productsCom__3eGXg {
	line-height: 15px;
	font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/companyDetails/companydetail.module.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,cAAc;AACf;;AAEA;CACC,eAAe;CACf,2BAA2B;AAC5B;;AAEA;CACC,eAAe;CACf,2BAA2B;AAC5B;;AAEA;CACC,qBAAqB;CACrB,eAAe;CACf,cAAc;AACf;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,2BAA2B;CAC3B,cAAc;AACf;;AAEA;CACC,2BAA2B;CAC3B,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,iBAAiB;AAClB;AACA;CACC,iBAAiB;CACjB,eAAe;AAChB","sourcesContent":[".companydetail_txt {\r\n\tfont-family: 'Robotomedium';\r\n\tcolor: #294d8f;\r\n}\r\n\r\n.company_name {\r\n\tfont-size: 20px;\r\n\tfont-family: 'Robotomedium';\r\n}\r\n\r\n.company_details_subtxt {\r\n\tfont-size: 14px;\r\n\tfont-family: 'Robotomedium';\r\n}\r\n\r\n.company_adress {\r\n\tfont-family: 'Roboto';\r\n\tfont-size: 13px;\r\n\tcolor: #5a5a5a;\r\n}\r\n\r\n.contact_persons_details {\r\n\tmargin-top: 40px;\r\n}\r\n\r\n.contact_person_txt {\r\n\tfont-size: 15px;\r\n\tfont-family: 'Robotomedium';\r\n\tcolor: #327072;\r\n}\r\n\r\n.contact_person_lhs {\r\n\tfont-family: 'Robotomedium';\r\n\tcolor: #294d8f;\r\n\tfont-size: 12px;\r\n}\r\n\r\n.contact_person_rhs {\r\n\tfont-size: 11px;\r\n\tpadding-left: 8px;\r\n}\r\n.productsCom {\r\n\tline-height: 15px;\r\n\tfont-size: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companydetail_txt": `companydetail_companydetail_txt__pZ0DV`,
	"company_name": `companydetail_company_name__pcm1P`,
	"company_details_subtxt": `companydetail_company_details_subtxt__8fTqR`,
	"company_adress": `companydetail_company_adress__XKaHl`,
	"contact_persons_details": `companydetail_contact_persons_details__DUNOo`,
	"contact_person_txt": `companydetail_contact_person_txt__zky8X`,
	"contact_person_lhs": `companydetail_contact_person_lhs__D5tu3`,
	"contact_person_rhs": `companydetail_contact_person_rhs__ZhzH2`,
	"productsCom": `companydetail_productsCom__3eGXg`
};
export default ___CSS_LOADER_EXPORT___;
