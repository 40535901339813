// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input {
    position: relative;
}

.phone-code {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    color: #999;
    pointer-events: none;
    /* prevent clicking on the code */
}

.phone-input input[type="tel"] {
    padding-left: 3.5rem;

    /* leave space for the code */
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/components/tc/addTc.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,SAAS;IACT,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,oBAAoB;IACpB,iCAAiC;AACrC;;AAEA;IACI,oBAAoB;;IAEpB,6BAA6B;IAC7B,WAAW;AACf","sourcesContent":[".phone-input {\r\n    position: relative;\r\n}\r\n\r\n.phone-code {\r\n    position: absolute;\r\n    left: 0;\r\n    top: 0;\r\n    bottom: 0;\r\n    padding: 0.5rem;\r\n    display: flex;\r\n    align-items: center;\r\n    color: #999;\r\n    pointer-events: none;\r\n    /* prevent clicking on the code */\r\n}\r\n\r\n.phone-input input[type=\"tel\"] {\r\n    padding-left: 3.5rem;\r\n\r\n    /* leave space for the code */\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
