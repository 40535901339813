import React, { useEffect, useState } from 'react';
import Table from '../table/Table';
//import approved from '../../assets/images/approved.png';

const ActiveCompanies = ({ companies, steList, isLoading }) => {
	const columnsArray = [
		'Date IA Visit',
		'Company Code',
		'Company Name',
		'TC',
		'RC',
		'Status',
		'Date of Creation',
	];

	const [viewDetails, setviewDetails] = useState(false);
	const viewDetailsBTN = () => {
		setviewDetails(!viewDetails);
	};

	if (isLoading) {
		return <div className="loader"></div>;
	}
	return (
		<div className="executiveSummary">
			<Table
				columnsArray={columnsArray}
				companyType="active companies"
				dataArray={companies}
				steList={steList}
			/>
		</div>
	);
};
export default ActiveCompanies;
