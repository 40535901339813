import React from 'react';
import Table from '../table/Table';

const ApprovedCompanies = ({ companies, steList }) => {
	const columnsArray = [
		'Date IA Visit',
		'Company Code',
		'Company Name',
		'TC',
		'RC',
		'IAR Version',
		'Date of Approval',
	];

	const dataArray = [
		{
			'Date of IA Visit': '15/08/2022',
			'Company Code': 'uz-tz-542',
			'Company Name': 'Promeat Corporation FSOWWQAGH Group',
			TC: 'Urooj Mohd',
			RC: 'Rishabh Tyagi',

			'IAR Version': 'UZ-TJ542-Draft IAR_v6',
			'Date of Approval': '7/1/2023',
		},
	];

	return (
		<div className="login">
			<Table
				columnsArray={columnsArray}
				companyType="approved companies"
				dataArray={companies}
				steList={steList}
			/>
		</div>
	);
};
export default ApprovedCompanies;
