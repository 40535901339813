// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainers{
    width:50%;
    margin-top: 20px;
}
.mainParagraph{
    
                color:gray; margin-bottom: 30px;font-size: 18px;
         
}
.accordionHelp{
    margin-left: 15px;
}
 .cardBodHelp {
  
     font-size: 18px;
 }

@media screen and (max-width: 768px){
    .mainContainers{
        width: 100%;
       
    }
        .accordionHelp {
            padding:10px 0px;
                margin-left: 0px;
            
        }
        .mainParagraph {
    
      margin-bottom:15px;
            font-size: 16px;
    
        }
        .cardHelp{
            margin-left: 0px;
            padding: 0px;
            margin:15px 0px;
        }
        .cardHeader{
            padding: 5px 0px;

        }
        .cardBodHelp{
            padding:10px 0px;
            font-size: 16px;
        }
}`, "",{"version":3,"sources":["webpack://./src/components/Help/style.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,gBAAgB;AACpB;AACA;;gBAEgB,UAAU,EAAE,mBAAmB,CAAC,eAAe;;AAE/D;AACA;IACI,iBAAiB;AACrB;CACC;;KAEI,eAAe;CACnB;;AAED;IACI;QACI,WAAW;;IAEf;QACI;YACI,gBAAgB;gBACZ,gBAAgB;;QAExB;QACA;;MAEF,kBAAkB;YACZ,eAAe;;QAEnB;QACA;YACI,gBAAgB;YAChB,YAAY;YACZ,eAAe;QACnB;QACA;YACI,gBAAgB;;QAEpB;QACA;YACI,gBAAgB;YAChB,eAAe;QACnB;AACR","sourcesContent":[".mainContainers{\r\n    width:50%;\r\n    margin-top: 20px;\r\n}\r\n.mainParagraph{\r\n    \r\n                color:gray; margin-bottom: 30px;font-size: 18px;\r\n         \r\n}\r\n.accordionHelp{\r\n    margin-left: 15px;\r\n}\r\n .cardBodHelp {\r\n  \r\n     font-size: 18px;\r\n }\r\n\r\n@media screen and (max-width: 768px){\r\n    .mainContainers{\r\n        width: 100%;\r\n       \r\n    }\r\n        .accordionHelp {\r\n            padding:10px 0px;\r\n                margin-left: 0px;\r\n            \r\n        }\r\n        .mainParagraph {\r\n    \r\n      margin-bottom:15px;\r\n            font-size: 16px;\r\n    \r\n        }\r\n        .cardHelp{\r\n            margin-left: 0px;\r\n            padding: 0px;\r\n            margin:15px 0px;\r\n        }\r\n        .cardHeader{\r\n            padding: 5px 0px;\r\n\r\n        }\r\n        .cardBodHelp{\r\n            padding:10px 0px;\r\n            font-size: 16px;\r\n        }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
