import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';
import './Pagination.css';
import useIframeResize from '../../../customHooks/useIframeResize';

const PaginationComponent = ({
	itemsPerPage,
	totalItems,
	paginate,
	activePage,
}) => {
	useIframeResize();
	const pageNumbers = [];
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	// Logic to determine the range of page numbers to display
	let startPage = activePage <= 5 ? 1 : activePage - 4;
	let endPage = startPage + 9;
	if (endPage > totalPages) {
		endPage = totalPages;
		if (totalPages >= 9) {
			startPage = endPage - 9;
		} else {
		}
	}
	if (startPage <= 0) {
		startPage = 1;
	}
	// Create an array of page numbers to display
	for (let i = startPage; i <= endPage; i++) {
		pageNumbers.push(i);
	}

	if (pageNumbers < 2) {
		return;
	}
	return (
		<div style={{ margin: '20px auto' }}>
			<Pagination>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						margin: 'auto',
					}}
				>
					<Pagination.First
						onClick={() => paginate(1)}
						disabled={activePage === 1}
					>
						Start
					</Pagination.First>
					<Pagination.Prev
						onClick={() => paginate(activePage - 1)}
						disabled={activePage === 1}
						className="prev-icon"
					/>

					{pageNumbers?.map((number) => (
						<Pagination.Item
							key={number}
							active={number === activePage}
							onClick={() => paginate(number)}
							style={{
								backgroundColor: 'blue',
								color: number === activePage ? '#FFFFFF' : '#000000',
							}}
						>
							{number}
						</Pagination.Item>
					))}

					<Pagination.Next
						onClick={() => paginate(activePage + 1)}
						disabled={activePage === Math.ceil(totalItems / itemsPerPage)}
						className="prev-icon"
					/>
					<Pagination.Last
						onClick={() => paginate(totalPages)}
						disabled={activePage === Math.ceil(totalItems / itemsPerPage)}
					>
						End
					</Pagination.Last>
				</div>
			</Pagination>
		</div>
	);
};

export default PaginationComponent;
