import Style from './SCPImprovementPreview.module.css';
import logo from '../../../assets/pdf_images/logo.png';
import plasticLogo from '../../../assets/pdf_images/Picture1.jpg';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import footerPP from '../../../assets/pdf_images/footerPP.png';
import PartnerLogoNew from '../../../assets/pdf_images/PartnerLogosNew.png';
import LogowithoutDescription from '../../../assets/pdf_images/LogoWithoutDescription.png';
import switchAsiaNew from '../../../assets/pdf_images/SwitchAsiaNew.png';

const PageFivePreview = ({ scpImrovment }) => {
	//function stripHtmlTags(htmlString) { const regex = /<[^>]+>/g; return htmlString.replace(regex, ''); }
	return (
		<div style={{ margin: '20px 100px' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					gap: '20px',
					alignItems: 'center',
					textAlign: 'end',
					paddingLeft: '13%',
					marginBottom: '20px',
					opacity: '0.7',
				}}
			>
				{' '}
				<span style={{ color: '#50AB92' }}>Project implemented by</span>
				<img src={PartnerLogoNew} width="350px" alt="headerImg" />
			</div>
			<div className={Style.custom_container_table}>
				<p className="table_header" style={{ color: '#50AB92' }}>
					4. SCP Improvement Options
				</p>
				<div className={Style.SCPImprovment}>
					<table
						style={{
							tableLayout: 'fixed',
							border: '1px solid #3B7E80',
							width: '100%',
						}}
					>
						<tbody>
							<tr
								style={{ border: '0.5px solid #3B7E80' }}
								className="teble_head"
							>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '10%',
									}}
								>
									Work Area or Process
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '10%',
									}}
								>
									SI No.
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '20%',
									}}
								>
									Description of the present situation and the problem observed
									<sup>1</sup>
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '30%',
									}}
								>
									Description of improvement action
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '10%',
									}}
								>
									Description and amount of the savings and other benefits
									<sup>2</sup>
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '10%',
									}}
								>
									Investment costs<sup>3</sup>
								</th>
								<th
									style={{
										border: '0.5px solid #3B7E80',
										borderCollapse: 'collapse',
										width: '10%',
									}}
								>
									Simple payback period<sup>4</sup>
								</th>
							</tr>
							{scpImrovment &&
								scpImrovment.map((i, index) => {
									return (
										<tr key={index}>
											<td
												style={{
													border: '1px solid black',
													borderCollapse: 'collapse',
													fontWeight: 'bold',
													width: '10%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
											>
												{i.work_area_name}
											</td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '10%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
											>
												{index + 1}
											</td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '20%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
												dangerouslySetInnerHTML={{
													__html: i.observation_master_name,
												}}
											></td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '30%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
												dangerouslySetInnerHTML={{ __html: i.recommendation }}
											></td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '10%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
											>
												{i.amount_of_savings_benefits}
											</td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '10%',
													textOverflow: 'initial',
													wordWrap: 'break-word',
													whiteSpace: 'normal',
												}}
											>
												{i.investments_costs}
											</td>
											<td
												style={{
													border: '1px solid #3B7E80',
													borderCollapse: 'collapse',
													width: '10%',
												}}
											>
												{i.simple_payback_period}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
				<div className={Style.bottom_div}>
					<div>
						<hr className={Style.hr_line} />
					</div>
					<div className={Style.footer_points_scp}>
						<li>
							<sup>1</sup> Technical options, organisational (responsibility,
							documentation, procedure, measurement, etc.) options
						</li>
						<li>
							<sup>2</sup> Materials, energy, time, costs minus additional costs
							(energy for the new equipment, maintenance, etc.)
						</li>
						<li>
							<sup>3</sup> Equipment, piping, insulation, preparation of the
							area, control equipment, etc.
						</li>
						<li>
							<sup>4</sup> Simple payback period (in months) = 12 * Investment
							in improvement actions / Annual savings
						</li>
					</div>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'end',
					margin: '20px auto',
					opacity: '0.6',
				}}
			>
				<img src={LogowithoutDescription} width="120px" />
				<img src={switchAsiaNew} width="200px" />
			</div>
		</div>
	);
};
export default PageFivePreview;
