import React, { useState } from 'react';
import pfdObTabStyles from './pfdObTab.module.css';
import { Link } from 'react-router-dom';
import notes from '../../assets/images/notes.png';
import gallery from '../../assets/images/gallery.png';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import PfDiagramMob from './pfDiagramMob';
import ObservationMob from './observationsMob';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import useNavigation from '../customHooks/useLocationHook';

const PfdObTab = () => {
	const { t } = useTranslation();
	useLanguageChange();
	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;
		document.cookie = currentPath;
		localStorage.setItem('enablerLocation', currentPath);

		// You can store the currentPath in state or perform other actions here
	});

	const [DefaultTab, setDefaultTab] = useState(
		localStorage.getItem('defaultTabMobile')
			? localStorage.getItem('defaultTabMobile')
			: 'MobilePro'
	);
	const tabClick = (key) => {
		let defaultTab = key.target.dataset.rrUiEventKey;
		setDefaultTab(defaultTab);
		localStorage.setItem('defaultTabMobile', defaultTab);
	};
	return (
		<div className="">
			<div className={'tabStyleMob'}>
				<Tabs
					defaultActiveKey={DefaultTab}
					justify
					onClick={(key) => tabClick(key)}
				>
					<Tab eventKey="MobilePro" title={t('ProcessFlowDiagram_Mobile')}>
						<PfDiagramMob />
					</Tab>
					<Tab eventKey="MobileObs" title={t('Observations')}>
						<ObservationMob />
					</Tab>
				</Tabs>
			</div>
		</div>
	);
};
export default PfdObTab;
