// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dropdown {
	background-color: #294d8f;
	border: 1px solid #294d8f;
	width: 110px;
	text-align: left;
	display: flex;
	gap: 12px;
	align-items: center;
	text-transform: capitalize;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/components/database/database.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,yBAAyB;CACzB,YAAY;CACZ,gBAAgB;CAChB,aAAa;CACb,SAAS;CACT,mBAAmB;CACnB,0BAA0B;AAC3B","sourcesContent":["#dropdown {\r\n\tbackground-color: #294d8f;\r\n\tborder: 1px solid #294d8f;\r\n\twidth: 110px;\r\n\ttext-align: left;\r\n\tdisplay: flex;\r\n\tgap: 12px;\r\n\talign-items: center;\r\n\ttext-transform: capitalize;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
