import React from 'react';
import Logo from '../../assets/images/admin/Reap_logo.png';

const HeaderWithLogo = () => {
	const isMobile = window.innerWidth < 768;

	return (
		<div
			className="header header_tc_web"
			style={{
				fontWeight: '900',
				fontSize: '23px',
				color: '#294D8F',
				fontFamily: 'Roboto,Extrabold',
				textAlign: 'center',
				paddingTop: '16px',
				height: '70px',
			}}

			// style={{ paddingLeft: !isMobile ? '50px' : '10px' }}
		>
			{/* <img src={Logo} width="120px" height="70px"  alt="REAP logo" style={{objectFit:"contain"}}/> */}
			RECO Wizard
		</div>
	);
};

export default HeaderWithLogo;
