// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateofiavisit_datepickr_txt__RwYtG {
	font-family: 'Robotomedium';
	color: #294d8f;
}

.dateofiavisit_secondary_date_btn__zkjvR {
	background-color: #294d8f;
	color: #ffffff;
	border: #294d8f 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 250px;
}

.dateofiavisit_date_btn__nr8ws {
	text-align: center;
}

.dateofiavisit_date_picker__Zf8jI {
	margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/components/dateofIAvisit/dateofiavisit.module.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,cAAc;AACf;;AAEA;CACC,yBAAyB;CACzB,cAAc;CACd,yBAAyB;CACzB,iBAAiB;CACjB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,WAAW;CACX,kBAAkB;CAClB,qBAAqB;CACrB,kBAAkB;CAClB,gBAAgB;AACjB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".datepickr_txt {\r\n\tfont-family: 'Robotomedium';\r\n\tcolor: #294d8f;\r\n}\r\n\r\n.secondary_date_btn {\r\n\tbackground-color: #294d8f;\r\n\tcolor: #ffffff;\r\n\tborder: #294d8f 1px solid;\r\n\tpadding: 8px 15px;\r\n\tfont-size: 14px;\r\n\tborder-radius: 6px;\r\n\tfont-weight: 500;\r\n\tline-height: 1.5;\r\n\tmargin: 2px;\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\ttext-align: center;\r\n\tmin-width: 250px;\r\n}\r\n\r\n.date_btn {\r\n\ttext-align: center;\r\n}\r\n\r\n.date_picker {\r\n\tmargin-bottom: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"datepickr_txt": `dateofiavisit_datepickr_txt__RwYtG`,
	"secondary_date_btn": `dateofiavisit_secondary_date_btn__zkjvR`,
	"date_btn": `dateofiavisit_date_btn__nr8ws`,
	"date_picker": `dateofiavisit_date_picker__Zf8jI`
};
export default ___CSS_LOADER_EXPORT___;
