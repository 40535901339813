import axios from 'axios';
import { baseUrl, apiPath, app_key, app_name } from '../constant';

const getHeaders = () => {
	let get_token = JSON.parse(window.localStorage.getItem('token'));
	let token = 'bearer ' + get_token;
	return {
		'Content-Type': 'application/json',
		Authorization: token,
		'app-key': app_key,
		'app-name': app_name,
		'accept-language': 2,
	};
};
let languageId = window.localStorage.getItem('lanId');

const LoginHeaders = {
	'content-type': 'application/json',
	'app-key': app_key,
	'app-name': app_name,
	'accept-language': languageId == null ? 1 : languageId,
};
export default class DataService {
	constructor() {
		this.client = null;
		this.init();
	}
	init = () => {
		this.client = axios.create({
			baseURL: baseUrl,
			withCredentials: true,
		});
	};
	getLanguages = () => {
		return this.client.get(apiPath.tcLanguage, {
			headers: LoginHeaders,
		});
	};
	// LOGIN API CALLS -------------------
	validateLogin = (formData) => {
		return this.client.post(apiPath.tcLogin, formData, {
			headers: LoginHeaders,
		});
	};
	//DASHBOARD MANAGEMENT API CALLS
	getTCdashboard = () => {
		return this.client.get(apiPath.tcDashboard, {
			headers: getHeaders(),
		});
	};
	addNewCompany = (formData) => {
		return this.client.post(apiPath.tcAddcompany, formData, {
			headers: getHeaders(),
		});
	};
	getMaterDataList = () => {
		return this.client.get(apiPath.tcMasterDataList, {
			headers: getHeaders(),
		});
	};
	saveObservation = (data) => {
		return this.client.post(apiPath.tcAddnewObservation, data, {
			'content-type': 'application/json',
		});
	};
	getExecutiveSummary = (data) => {
		return this.client.post(apiPath.getExecutiveSummary, data, {
			headers: getHeaders(),
		});
	};
	addExecutiveSummary = (formData) => {
		return this.client.post(apiPath.addExecutiveSummary, formData, {
			headers: getHeaders(),
		});
	};
	getProcessFlowData = (data) => {
		return this.client.post(apiPath.getProcessFlow, data, {
			headers: getHeaders(),
		});
	};
	addProcessFlowData = (data) => {
		return this.client.post(apiPath.addProcessFlow, data, {
			headers: getHeaders(),
		});
	};

	getNotifications = () => {
		return this.client.post(
			apiPath.getNotifications,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	getcompanybyid = (data) => {
		return this.client.post(apiPath.getcompanybyid, data, {
			headers: getHeaders(),
		});
	};
	addIAvisitDate = (data) => {
		return this.client.post(apiPath.addiavisitdate, data, {
			headers: getHeaders(),
		});
	};
	uploadsignature = (data) => {
		return this.client.post(apiPath.uploadsignature, data, {
			headers: getHeaders(),
		});
	};
	addCheckPoint = (data) => {
		return this.client.post(apiPath.addCheckPointStatus, data, {
			headers: getHeaders(),
		});
	};
	addDeviceInformation = (data) => {
		return this.client.post(apiPath.addDeviceInfo, data, {
			headers: getHeaders(),
		});
	};
	pdfdata = (data) => {
		return this.client.post(apiPath.pdfdata, data, {
			headers: getHeaders(),
		});
	};
	getObservationDataById = (data) => {
		return this.client.post(apiPath.getObservationById, data, {
			headers: getHeaders(),
		});
	};
	addObservationDataById = (data) => {
		return this.client.post(apiPath.addObservationById, data, {
			headers: getHeaders(),
		});
	};
	deleteObservation = (data) => {
		return this.client.post(apiPath.deleteObservation, data, {
			headers: getHeaders(),
		});
	};

	getSCPimprovements = (data) => {
		return this.client.post(apiPath.getScpImprovements, data, {
			headers: getHeaders(),
		});
	};
	getGallery = (data) => {
		return this.client.post(apiPath.getGalleryByID, data, {
			headers: getHeaders(),
		});
	};
	updateSCPdata = (data) => {
		return this.client.post(apiPath.updateSCP, data, {
			headers: getHeaders(),
		});
	};
	getOnsiteGallery = (data) => {
		return this.client.post(apiPath.OnsiteGallery, data, {
			headers: getHeaders(),
		});
	};
	//getting logged in TC details
	getTcDetails = () => {
		return this.client.get(apiPath.getTcDetails, {
			headers: getHeaders(),
		});
	};
	//reset password
	resetPassword = (data) => {
		return this.client.post(apiPath.resetPassword, data, {
			headers: getHeaders(),
		});
	};
	saveGallerydata = (data) => {
		return this.client.post(apiPath.saveGallery, data, {
			headers: getHeaders(),
		});
	};
	deleteGallerydata = (data) => {
		return this.client.post(apiPath.deleteGallery, data, {
			headers: getHeaders(),
		});
	};
	forgotPasswordData = (data) => {
		return this.client.post(apiPath.forgotPassword, data, {
			headers: getHeaders(),
		});
	};
	getNotesData = (data) => {
		return this.client.post(apiPath.getNotes, data, {
			headers: getHeaders(),
		});
	};
	addNotes = (data) => {
		return this.client.post(apiPath.addNote, data, {
			headers: getHeaders(),
		});
	};
	getReadAllNotification = () => {
		return this.client.post(
			apiPath.readAllNotification,
			{},
			{
				headers: getHeaders(),
			}
		);
	};
	serverErrorLog = (data) => {
		return this.client.post(apiPath.errorLog, data, {
			headers: getHeaders(),
		});
	};
}
