import './SCPImprovement.css';

const SCPImprovementPDF = ({ scpImrovment = [] }) => {
	const chunkArray = (myArray, chunk_size) => {
		let index = 0;
		const arrayLength = myArray.length;
		const tempArray = [];

		for (index = 0; index < arrayLength; index += chunk_size) {
			const myChunk = myArray.slice(index, index + chunk_size);
			tempArray.push(myChunk);
		}

		return tempArray;
	};

	const scpImprovmentChunks = chunkArray(scpImrovment, 3);

	return (
		<div
			style={{
				display: 'block',
				pageBreakBefore: 'always',
				position: 'relative',
			}}
		>
			<div className="custom_container_table SCP_section">
				<p
					className="table_header"
					style={{ color: '#3B7E80', fontSize: '18px' }}
				>
					4. SCP Improvement Options
				</p>
				{scpImprovmentChunks.map((chunk, chunkIndex) => (
					<table
						cellSpacing={0}
						cellPadding={5}
						style={{
							width: '100%',
							border: '1px solid black',
							borderCollapse: 'collapse',
							borderSpacing: '0',
							marginBottom: '5px',
							pageBreakAfter: 'always',
						}}
						key={chunkIndex}
					>
						<tbody className="scp">
							<tr
								style={{ border: '0.5px solid black' }}
								className="teble_head"
							>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '10%',
									}}
								>
									Work Area or Process
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '2%',
									}}
								>
									S. No.
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '28%',
									}}
								>
									Description of the present situation and the problem observed
									<sup>1</sup>
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '30%',
									}}
								>
									Description of improvement action
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '10%',
									}}
								>
									Description and amount of the savings and other benefits
									<sup>2</sup>
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '10%',
									}}
								>
									Investment costs<sup>3</sup>
								</th>
								<th
									style={{
										border: '0.5px solid black',
										borderCollapse: 'collapse',
										fontSize: '14px',
										textAlign: 'center',
										width: '10%',
									}}
								>
									Simple payback period<sup>4</sup>
								</th>
							</tr>
							{chunk.map((i, index) => (
								<tr key={i.scp_improvement_options_id}>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '10%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{i.work_area_name}
									</td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '2%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{chunkIndex * 3 + index + 1}
									</td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '28%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
										dangerouslySetInnerHTML={{
											__html: i.observation_master_name,
										}}
									></td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '30%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
										dangerouslySetInnerHTML={{ __html: i.recommendation }}
									></td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '10%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{i.amount_of_savings_benefits}
									</td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '10%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{i.investments_costs}
									</td>
									<td
										style={{
											border: '1px solid black',
											borderCollapse: 'collapse',
											fontSize: '13px',
											padding: '10px',
											color: 'black',
											width: '10%',
											textOverflow: 'initial',
											wordWrap: 'break-word',
											whiteSpace: 'normal',
										}}
									>
										{i.simple_payback_period}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				))}
				<div className="bottom_div">
					<div>
						<hr className="hr_line" />
					</div>
					<div className="footer_points">
						<li>
							<sup>1</sup> Technical options, organisational (responsibility,
							documentation, procedure, measurement, etc.) options
						</li>
						<li>
							<sup>2</sup> Materials, energy, time, costs minus additional costs
							(energy for the new equipment, maintenance, etc.)
						</li>
						<li>
							<sup>3</sup> Equipment, piping, insulation, preparation of the
							area, control equipment, etc.
						</li>
						<li>
							<sup>4</sup> Simple payback period (in months) = 12 * Investment
							in improvement actions / Annual savings
						</li>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SCPImprovementPDF;
