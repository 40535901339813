export const toolsList = [
	{
		name: 'Solar Roof Top Potential Estimator',
		detail:
			'Quickly estimate the solar PV plant capacity, annual energy generation potential, cost of installing solar PV plant and cost saving potential.',
		url: 'https://enabler.sustent.in/partners/solarCalculator',
	},
	{
		name: 'Rainwater Harvesting Potential Estimator',
		detail:
			'Quickly estimate the rainwater collection potential, cost of installing rainwater harvesting system and cost saving potential.',
		url: 'https://enabler.sustent.in/partners/RainHarvestingPotential',
	},
	{
		name: 'Heat Loss and Insulation Potential Estimator',
		detail:
			'Quickly estimate the energy loss due to missing insulation, cost of insulation and the cost saving potential through added insulation.',
		url: 'https://enabler.sustent.in/partners/heatloss',
	},
	{
		name: 'Unit Convertor',
		detail:
			'Convert figures from one unit of measure to another across twelve parameters used frequently in various aspects of environment management.',
		url: 'https://enabler.sustent.in/partners/unit-convertor',
	},
	{
		name: 'Compressed Air Pressure Reduction Calculator',
		detail:
			'Calculate the energy saving of by reducing the pressure of compressed air.',
		url: 'https://enabler.sustent.in/partners/compressedAirPressure',
	},
	{
		name: 'Compressed Free Air Delivery Calculator',
		detail:
			'Calculate the efficiency of the compressor at a specific company facility.',
		url: 'https://enabler.sustent.in/partners/CompressedFreeAirDelivery',
	},
	{
		name: 'Compressed Air Leakage Calculator',
		detail:
			'Calculate the percentage leakage in the compressed air system at a specific company facility.',
		url: 'https://enabler.sustent.in/partners/CompressedAirLeakage',
	},
	{
		name: 'Boiler Efficiency Calculator',
		detail: 'This is a very short description of the tools purpose',
		url: 'https://enabler.sustent.in/partners/boilerEfficiency',
	},
];
