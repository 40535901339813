import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import DataService from '../../services/DataServices';
import { useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import AuthUser from '../authUser/authuser';
import LoaderImage from '../../assets/images/loadin_gif.gif';
import { useTranslation } from 'react-i18next';
import { ErrorLog } from '../erroLog/errorLog';
import useNavigation from '../customHooks/useLocationHook';
import { useCookies } from 'react-cookie';

const SCPImprovement = () => {
	let api = new DataService();
	const [, setCookie] = useCookies(['lastLocation']);
	//setting the location of the page in cookie for enabler to pick up
	useNavigation((location) => {
		const currentPath = location.pathname;
		document.cookie = currentPath;
		localStorage.setItem('enablerLocation', currentPath);
		setCookie('lastLocation', currentPath, {
			path: '/', // Set the cookie to be accessible across the domain
			// domain: 'localhost',
			domain: 'sustent.in', // Replace with your actual domain
			secure: true, // Set to true for secure cookies (HTTPS)
			sameSite: 'none',
		});
		// You can store the currentPath in state or perform other actions here
	});

	const { logout } = AuthUser();
	let navigate = useNavigate();
	const { t } = useTranslation();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
	const [notes, setNotes] = useState([]);
	const [conformPopup, setConformPopup] = useState(false);
	const [successMessage, setSuccessMessage] = useState(false);
	const [Message, setMessage] = useState('');
	const [Loader, setLoader] = useState(true);
	const [LoaderSave, setLoaderSave] = useState(false);
	const handleClose = () => {
		setConformPopup(false);
		setSuccessMessage(false);
		setMessage();
	};
	const getNotesFunction = async () => {
		let getBucketdata = localStorage.getItem('companyNotesBucket');
		let BucketJson = JSON.parse(getBucketdata);
		let filteredBucket =
			BucketJson &&
			BucketJson.filter((company) => company.company_id == comp_url);
		if (filteredBucket == null || filteredBucket.length == 0) {
			let data = {
				company_id: comp_url,
			};
			try {
				await api.getNotesData(data).then((response) => {
					let responseData = response.data;
					let notesdata = response.data.notes;
					setNotes(notesdata[0]);
					let notesBucketArray = [];
					notesBucketArray.push(responseData);
					localStorage.setItem(
						'companyNotesBucket',
						JSON.stringify(notesBucketArray)
					);
					if (filteredBucket != null) {
						let notesBucketArray = [...BucketJson];
						notesBucketArray.push(responseData);
						localStorage.setItem(
							'companyNotesBucket',
							JSON.stringify(notesBucketArray)
						);
						setNotes(notesdata[0]);
					} else {
						let notesBucketArray = [];
						notesBucketArray.push(responseData);
						localStorage.setItem(
							'companyNotesBucket',
							JSON.stringify(notesBucketArray)
						);
						setNotes(notesdata[0]);
					}
				});
			} catch (error) {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let errMsg = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						let errorLogData = {
							api_name: 'getnote',
							error_code: errorCode,
							error_message: errMsg,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setSuccessMessage(true);
					setMessage(
						error.message + '. Please check your internet connection.'
					);
				}
			}
		} else {
			let filteredBucketTwo =
				BucketJson &&
				BucketJson.filter((company) => company.company_id == comp_url);
			setNotes(filteredBucketTwo[0].notes[0]);
		}
	};
	const noteHandleChange = (content, editor) => {
		let updateNote = content;
		let data = {
			note_id: 0,
			note_description: updateNote,
		};
		let allData = {
			company_id: comp_url,
			notes: [data],
		};
		let getBucketdata = localStorage.getItem('companyNotesBucket');
		let BucketJson = JSON.parse(getBucketdata);
		let filterData = BucketJson.filter((item) => item.company_id != comp_url);
		filterData.push(allData);
		localStorage.setItem('companyNotesBucket', JSON.stringify(filterData));
		setNotes(data);
	};
	const saveNoteFunction = () => {
		setConformPopup(true);
	};
	const saveNoteConformation = (status) => {
		if (status == 'yes') {
			let data = {
				company_id: comp_url,
				notes: [notes],
			};
			if (data.notes[0] !== undefined && notes.note_description.length != 0) {
				saveNotedata();
			} else {
				setConformPopup(false);
				setSuccessMessage(true);
				setMessage(t('Pleasefillyournote'));
			}
		} else {
			setConformPopup(false);
		}
	};
	const saveNotedata = async () => {
		setLoaderSave(true);
		let data = {
			company_id: comp_url,
			notes: [notes],
		};
		await api
			.addNotes(data)
			.then((res) => {
				if (res.status == 200 || res.status == 201) {
					let message = res.data.message;
					setConformPopup(false);
					setSuccessMessage(true);
					setMessage(message);
					setTimeout(() => {
						navigate(-1);
					}, 2000);
					setTimeout(() => {
						setLoaderSave(false);
					}, 1000);
					let getBucketdata = localStorage.getItem('companyNotesBucket');
					let BucketJson = JSON.parse(getBucketdata);
					let filterData = BucketJson.filter(
						(item) => item.company_id != comp_url
					);
					localStorage.setItem(
						'companyNotesBucket',
						JSON.stringify(filterData)
					);
					getNotesFunction();
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let errMsg = error.response.data.error.message;
					if (errorCode == 401) {
						setConformPopup(false);
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						setTimeout(() => {
							setMessage(errMsg);
							setLoaderSave(false);
						}, 1000);
						let errorLogData = {
							api_name: 'addnote',
							error_code: errorCode,
							error_message: errMsg,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setSuccessMessage(true);
					setMessage('Please check your internet connection.');
				}
			});
	};
	const LoaderFunc = () => {
		setLoader(false);
	};
	useEffect(() => {
		getNotesFunction();
	}, []);

	return (
		<div className="deskWrapper">
			{window.innerWidth > 768 ? <TcBreadCrumb title={t('AddNotes')} /> : null}
			<div className="container">
				<div className="row mb-3">
					<div className="col-md-12">
						{Loader == true ? (
							<img src={LoaderImage} alt="loader" style={{ width: '200px' }} />
						) : null}
						<Editor
							apiKey="kzlcxizndqi4y53qmkg78gj3kg9xzq2orptfwisr6cddlpnc"
							onLoadContent={() => LoaderFunc()}
							init={{
								placeholder: t('Typeyournote'),
								branding: false,
								height: 400,
								menubar: false,
								plugins: ['lists'],
								toolbar:
									'bold italic underline| aligncenter ' +
									'alignleft alignright alignjustify | bullist numlist| ',
								content_style:
									'body { font-family:Roboto, Helvetica,Arial,sans-serif; font-size:14px }',
							}}
							value={notes && notes.note_description}
							onEditorChange={noteHandleChange}
						/>
					</div>
				</div>
				{Loader === false ? (
					<div className="row">
						<div className="col-md-12 mb-3">
							<Link className="secondary_btn" onClick={saveNoteFunction}>
								{t('saveAndExit')}
							</Link>
						</div>
					</div>
				) : null}
			</div>
			<Modal
				className="newObservation removeConform"
				show={conformPopup}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{t('DoyouWantsaveConform')}</h4>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveNoteConformation('no')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveNoteConformation('yes')}
						>
							{t('Yes')}
						</button>
						{LoaderSave === true ? (
							<div>
								<img src={LoaderImage} alt="loader" width="130" />
							</div>
						) : (
							<div className="mt-2 form-text text-danger">
								{Message && Message}
							</div>
						)}
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={successMessage}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						{LoaderSave === true ? (
							<div>
								<img src={LoaderImage} alt="loader" width="130" />
							</div>
						) : (
							<h4 className="mb-4">{Message && Message}</h4>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default SCPImprovement;
