import React, { useState } from 'react';
import Styles from './style.module.css';
import DataService from '../../services/DataServices';
import { BsCheckLg } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import AuthUser from '../authUser/authuser';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';

const ChangePassword = () => {
	const { logout } = AuthUser();
	let navigate = useNavigate();
	const { t } = useTranslation();
	useLanguageChange();
	const api = new DataService();
	const [ErrorValue, setErrorValue] = useState({});
	const [sucessMsg, setSucessMsg] = useState(false);
	const [Msg, setMsg] = useState('');
	const [ConformPopup, setConformPopup] = useState(false);
	const handleClose = () => {
		setSucessMsg(false);
	};
	const [inputValues, setInputValues] = useState({
		currentPassword: '',
		newPassWord: '',
		reEnterPassword: '',
	});
	const [checkError, setCheckError] = useState({
		characterValue: false,
		numericValue: false,
		specialCharvalue: false,
	});
	const handlePasswordChange = (e) => {
		const { name, value } = e.target;
		if (name === 'currentPassword') {
			setInputValues({
				...inputValues,
				currentPassword: value,
			});
		}
		if (name === 'newPassWord') {
			let regex = /[@#$%^&*]/;

			setInputValues({
				...inputValues,
				newPassWord: value,
			});
			let specialvalue = regex.test(value);
			let PassLength = value.length >= 8;
			let numericList = /[0-9]/;
			let numericValue = numericList.test(value);
			setCheckError({
				...checkError,
				specialCharvalue: specialvalue,
				characterValue: PassLength,
				numericValue: numericValue,
			});
		}
		if (name === 'reEnterPassword') {
			setInputValues({
				...inputValues,
				reEnterPassword: value,
			});
		}
	};
	const validationError = (values) => {
		let error = {};
		if (!values.currentPassword) {
			error.currentPassword = t('Pleaseentercurrentpassword');
		}
		if (!values.newPassWord) {
			error.newPassWord = t('Pleaseenternewpassword');
		}
		if (!values.reEnterPassword) {
			error.reEnterPassword = t('Pleasereenterpassword');
		}
		if (values.reEnterPassword !== values.newPassWord) {
			error.reEnterPassword = t('Passwordnotmatching');
		}
		return error;
	};
	const PasswordResetSubmit = () => {
		let errorValue = validationError(inputValues);
		setErrorValue(errorValue);
		if (Object.keys(errorValue).length === 0) {
			if (
				checkError.characterValue != false &&
				checkError.specialCharvalue != false &&
				checkError.numericValue != false
			) {
				setConformPopup(true);
			} else {
				setErrorValue({
					...ErrorValue,
					reEnterPassword: t('Passwordnotvalid'),
				});
			}
		} else {
			setConformPopup(false);
		}
	};
	const conformPopupFunction = (status) => {
		if (status == 'Yes') {
			callChangePassword();
			setConformPopup(false);
		} else {
			setConformPopup(false);
		}
	};
	const callChangePassword = async () => {
		let data = {
			user_password: btoa(
				unescape(encodeURIComponent(inputValues.newPassWord))
			),
			current_password: btoa(
				unescape(encodeURIComponent(inputValues.currentPassword))
			),
		};
		await api
			.resetPassword(data)
			.then((res) => {
				if (res.status == 200 || res.status == 201) {
					let resMessage = res.data.message;
					setSucessMsg(true);
					setMsg(resMessage);
					setTimeout(() => {
						logout();
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						let errorValue = error.response.data.error.message;
						setErrorValue({ ...ErrorValue, currentPassword: errorValue });
						let errorLogData = {
							api_name: 'resetpassword',
							error_code: errorCode,
							error_message: errorValue,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setSucessMsg(true);
					setMsg('Please check your internet connection.');
				}
			});
	};
	return (
		<div className="deskWrapper">
			<div className="container">
				<div className="row">
					<div style={{ marginTop: '30px' }} className="col-12 col-lg-6">
						<h2 className={Styles.headerText}>{t('ChangePassword')}</h2>
						<div className={Styles.inputContainer}>
							<div style={{ marginBottom: '14px', marginTop: '20px' }}>
								<label className="form-label">
									{t('CurrentPassword')}
									<span className="star">*</span>
								</label>
								<input
									type="email"
									name="currentPassword"
									value={inputValues.currentPassword}
									onChange={handlePasswordChange}
									className="form-control"
									placeholder={t('EnterCurrentPassword')}
									autoComplete="off"
								/>
								<div className="form-text text-danger">
									{ErrorValue.currentPassword}
								</div>
								<div className="form-text text-danger">
									{/* {validateInput.passwordMismatch} */}
								</div>
							</div>

							<div className="mb-3">
								<label className="form-label">
									{t('NewPassword')}
									<span className="star">*</span>
								</label>
								<input
									type="text"
									name="newPassWord"
									value={inputValues.newPassWord}
									onChange={handlePasswordChange}
									className="form-control"
									placeholder={t('EnterNewPassword')}
									autoComplete="off"
								/>
								<div className="form-text text-danger">
									{ErrorValue.newPassWord}
								</div>
								<ul className={Styles.passWordStrongList}>
									<li
										style={{
											color:
												checkError.characterValue == true ? '#294D8F' : 'red',
										}}
									>
										<span>*{t('MustHaveChar')}</span>
										<span>
											{checkError.characterValue == true ? (
												<BsCheckLg />
											) : (
												<MdClose />
											)}
										</span>
									</li>
									<li
										style={{
											color:
												checkError.specialCharvalue == true ? '#294D8F' : 'red',
										}}
									>
										<span style={{ width: '90%' }}>
											*{t('MustContaineSpecial')} {'{*&#}'}{' '}
										</span>
										<span>
											{checkError.specialCharvalue == true ? (
												<BsCheckLg />
											) : (
												<MdClose />
											)}
										</span>
									</li>
									<li
										style={{
											color:
												checkError.numericValue == true ? '#294D8F' : 'red',
										}}
									>
										<span>*{t('MustHaveNumeric')} </span>
										<span>
											{checkError.numericValue == true ? (
												<BsCheckLg />
											) : (
												<MdClose />
											)}
										</span>
									</li>
								</ul>
							</div>

							<div className="mb-3">
								<label className="form-label">
									{t('ReenterNewPassword')}
									<span className="star">*</span>
								</label>
								<input
									type="text"
									name="reEnterPassword"
									value={inputValues.reEnterPassword}
									onChange={handlePasswordChange}
									className="form-control"
									placeholder={t('ReenterNewPassword')}
									autoComplete="off"
								/>
								<div className="form-text text-danger">
									{ErrorValue.reEnterPassword}
								</div>
								<button
									onClick={PasswordResetSubmit}
									className="secondary_btn"
									style={{
										backgroundColor: '#294D8F',
										width: '100%',
										color: 'white',
										marginTop: '30px',
									}}
								>
									{t('Confirm')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				className="newObservation removeConform"
				show={sucessMsg}
				onHide={() => setSucessMsg(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h5>{Msg && Msg}</h5>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={ConformPopup}
				onHide={() => setConformPopup(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<p>{t('YouwantchangPassword')}</p>
						<button
							className="secondary_btn small_btn"
							onClick={() => conformPopupFunction('No')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => conformPopupFunction('Yes')}
						>
							{t('Yes')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ChangePassword;
