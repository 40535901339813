import Style from './ExecutiveSummeryPreview.module.css';
import logo from '../../../assets/pdf_images/logo.png';
import plasticLogo from '../../../assets/pdf_images/Picture1.jpg';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import project_implement_img8 from '../../../assets/pdf_images/Partner_Logos_Combined.png';
import footerPP from '../../../assets/pdf_images/footerPP.png';
import PartnerLogoNew from '../../../assets/pdf_images/PartnerLogosNew.png';
import LogowithoutDescription from '../../../assets/pdf_images/LogoWithoutDescription.png';
import switchAsiaNew from '../../../assets/pdf_images/SwitchAsiaNew.png';

const ExecutiveSummeryPreview = ({ executiveData, company }) => {
	return (
		<div className={Style.demo}>
			<div className={Style.logoHeader}>
				{/* <img className={Style.logo} src={plasticLogo} alt="headerImg" /> */}

				{/* <div className={Style.switchImgTxt}>
						<p>Project Funded by</p>
					</div> */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						gap: '20px',
						alignItems: 'center',
						textAlign: 'end',
						paddingLeft: '13%',
						opacity: '0.7',
					}}
				>
					{' '}
					<span style={{ color: '#50AB92' }}>Project implemented by</span>
					<img src={PartnerLogoNew} width="350px" alt="headerImg" />
				</div>
			</div>
			<div className={Style.header}>
				<span>1.Executive Summary</span>
			</div>
			<div className={Style.executiveWraper}>
				<div className={Style.executiveContent}>
					{company &&
						company.map((i, index) => {
							return (
								<p style={{ marginTop: '20px' }} key={index}>
									The Resource Efficient Cleaner Production (RECP) approach was
									used during on-site consulting visits to
									<span>
										{' '}
										<b>{i.company_name}</b>
									</span>
									&nbsp; on <b>{i.company_iav_date}</b>&nbsp; RECP team members
									studied the process of production at the company to identify
									opportunities for reducing the resource use at the company.
									The focus was on identifying low cost or no cost options for
									improvements and for reducing resource use at the source
								</p>
							);
						})}

					<p>
						A summary of the identified improvement opportunities is provided in
						the tables to help the company owners or top management to obtain a
						quick overview of savings potential identified at this stage. The
						improvement opportunities are described in brief in section 3 of
						this report. RECP team members may be contacted for any
						clarification or technical support. The company management is urged
						to study this report in detail and to select those options they wish
						to implement
					</p>
					<p>
						The management is also requested to assign clear responsibilities to
						internal team members for early implementation of the selected
						options. Based on the selected areas or options, RECP team will
						prepare more detailed steps explaining the steps to be taken for the
						implementation of the selected options, where needed
					</p>
				</div>
				<div>
					<p className={Style.secondHeader}>
						2. Summary of Resource Saving Potential
					</p>
					<p className={Style.sumryTxt}>
						A summary of resource and cost saving potential along with
						estimation of investment is provided in Table 1.
					</p>
					<p className={Style.tableheader}>
						Table 1 Summary of resource saving potential
					</p>
				</div>

				<div className={Style.executivesumryTable}>
					<table cellSpacing={0} cellPadding={5}>
						<tbody>
							<tr
								style={{
									border: '0.5px solid black',
									background: '#dadada',
									color: 'black',
								}}
							>
								<th rowSpan="2">S.No</th>
								<th rowSpan="2">Resources</th>
								<th rowSpan="2">Unit Cost</th>
								<th colSpan="2">Potential Saving </th>
								<th colSpan="1">Estimated Investment</th>
							</tr>

							{company &&
								company.map((i, index) => {
									return (
										<tr
											style={{
												border: '0.5px solid black',
												background: '#dadada',
												color: 'black',
											}}
											key={index}
										>
											<th>Quantity</th>
											<th>Monetary ({i.currency})</th>
											<th>{i.currency}</th>
										</tr>
									);
								})}

							{executiveData &&
								executiveData.map((i, index) => {
									return (
										<tr key={index}>
											<td style={{ padding: '10px' }}>{index + 1}</td>
											<td style={{ padding: '10px' }}>
												{i.executive_summary_resource_name}
											</td>
											<td style={{ padding: '10px' }}>
												{i.executive_summary_unit_cost}
											</td>
											<td style={{ padding: '10px' }}>
												{i.executive_summary_quantity}
											</td>
											<td style={{ padding: '10px' }}>
												{i.executive_summary_monetary}
											</td>
											<td style={{ padding: '10px' }}>
												{i.executive_summary_estimated_investment}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>

			<div>
				{/* <div>
					<img
						src={footerPP}
						style={{ width: '800px', marginTop: '120px', marginLeft: '5px' }}
						alt="footerImg"
					/>
				</div> */}
				{/* <div
					style={{
						backgroundColor: '#294D8F',
						marginTop: '18px',
						width: '814px',
					}}
				>
					<p
						className={Style.pageNumber}
						style={{
							color: 'white',
							textAlign: 'center',
							fontSize: '12px',
							marginBottom: '0',
						}}
					></p>
				</div> */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'end',
						margin: '20px 90px',
						opacity: '0.6',
					}}
				>
					<img src={LogowithoutDescription} width="120px" />
					<img src={switchAsiaNew} width="200px" />
				</div>
			</div>
		</div>
	);
};
export default ExecutiveSummeryPreview;
