import { useState } from 'react';
import uploadSign from './uploadSign.module.css';
import plus from '../../assets/images/plus.svg';
import DataService from '../../services/DataServices';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import AuthUser from '../authUser/authuser';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';
import Compressor from 'compressorjs';
import useIframeResize from '../customHooks/useIframeResize';

const UploadSignature = () => {
	useIframeResize();
	// useLanguageChange();
	const { t } = useTranslation();
	let location = useLocation();
	let currentPath = location.pathname.split('/')[1];
	const { logout } = AuthUser();
	const api = new DataService();
	const [Loader, setLoader] = useState(false);
	const navigate = useNavigate();
	const [Message, setMessage] = useState();
	const [savePopup, setSavePopup] = useState(false);
	const [ImageSizeError, setImageSizeError] = useState('');
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);
	const [errorMessage, setErrorMessage] = useState(false);

	const handleImageUpload = (imageList) => {
		let imageFile = imageList.target.files[0];
		let megaBytes = 1500000;
		setLoader(true);
		new Compressor(imageFile, {
			quality: 0.8,
			success: (compressedResult) => {
				let FileSize = compressedResult.size;
				if (FileSize < megaBytes) {
					if (compressedResult) {
						setPicture(compressedResult);
						const reader = new FileReader();
						reader.addEventListener('load', () => {
							setTimeout(() => {
								setLoader(false);
								setImgData(reader.result);
							}, 2000);
						});
						reader.readAsDataURL(compressedResult);
					}
				} else {
					setTimeout(() => {
						setLoader(false);
						setImageSizeError(t('imageSizevalidationOneMB'));
					}, 2000);
				}
			},
		});
	};
	const handleClose = () => {
		setSavePopup(false);
	};
	const saveSignatureStatus = (status) => {
		if (status == 'yes') {
			saveSignature();
			setSavePopup(false);
			setTimeout(() => {
				setLoader(false);
			}, 2000);
		} else {
			setSavePopup(false);
		}
	};
	const saveSignature = async () => {
		setLoader(true);
		let data = {
			user_signature: imgData,
		};
		await api
			.uploadsignature(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let res = response.data;
					setTimeout(() => {
						setMessage(res.message);
						setLoader(false);
					}, 1000);
					setTimeout(() => {
						navigate('/dashboard');
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let message = error.response.data.error.message;
					if (errorCode == 400 || errorCode == 500) {
						setLoader(true);
						setTimeout(() => {
							setMessage(message);
							setLoader(false);
						}, 2000);
						let errorLogData = {
							api_name: 'uploadsignature',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
					}
					if (errorCode == 401) {
						logout();
					}
				} else {
					setErrorMessage(true);
					setMessage(
						error.message + '. Please check your internet connection.'
					);
				}
			});
	};
	return (
		<div className={uploadSign.upload}>
			<div className="container">
				<div className="row">
					<div className="col-md-6 col-lg-5 px-0">
						<div className="row">
							<div>
								{currentPath != 'viewProfie' && (
									<h1>{t('UploadSignatureBtn')}</h1>
								)}
								{currentPath != 'viewProfie' && (
									<p>{t('UploadSignatureParaOne')}</p>
								)}
								<p>{t('UploadSignatureParaTwo')}</p>
							</div>

							<div style={{ margin: '10px' }}>
								<b>i.</b> Your signature will be used to sign the reports
								prepared by you.
							</div>

							<div className="col-12 text-center">
								<label
									className={'secondary_date_btn' + ' ' + uploadSign.signature}
								>
									<input
										type="file"
										name="file-uploader"
										onInput={(e) => handleImageUpload(e)}
										style={{ display: 'none' }}
										accept=".jpg,.png"
									/>
									<img src={plus} alt="Plus" />
									{t('UploadSignatureBtn')}
								</label>
							</div>
							{imgData != null ? (
								<div className="text-center">
									<img
										src={imgData}
										alt="prev"
										style={{ marginTop: '10px', width: '200px' }}
									/>
									<div style={{ marginTop: '10px' }}>
										<button
											onClick={() => setSavePopup(true)}
											className="secondary_btn"
										>
											{t('SaveBtn')}
										</button>
									</div>
								</div>
							) : null}

							<div className="form-text text-danger">
								{ImageSizeError && ImageSizeError}
							</div>
							{Loader == true ? (
								<img
									src={LoaderImg}
									alt="loader"
									style={{ width: '200px', margin: 'auto' }}
								/>
							) : null}
							<div style={{ marginTop: '8px', textAlign: 'center' }}>
								{Message ? Message : null}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal
				className="newObservation removeConform"
				show={savePopup}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{t('DoyouWantsaveConform')}</h4>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveSignatureStatus('no')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveSignatureStatus('yes')}
						>
							{t('Yes')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={errorMessage}
				onHide={() => setErrorMessage(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{Message && Message}</h4>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default UploadSignature;
