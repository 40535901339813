import React, { useState, useRef, useContext } from 'react';
import AdminDataService from '../../../../services/AdminDataServices';
import { GlobalContext } from '../../../../context/GlobalState';
import { Alert } from 'react-bootstrap';
import { DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import Search from '../../../../assets/images/admin/search.png';
import TCStyles from './database.module.css';
import { handleApiError } from '../../../utils/apiErrorHandler';
import { useEffect } from 'react';

const DatabaseHeader = ({
	setThemeArray,
	name,
	themeArray,
	excelFile,
	themeRef,
	placeholder,
	api,
	secondName,
	thirdName,
	fourthName,
	type,
	setIsLoading,
}) => {
	const adminApi = new AdminDataService();
	const {
		setMessage,
		setGlobalNetworkError,
		changeCurrentLanguage,
		currentLanguageId,
	} = useContext(GlobalContext);
	const [file, setFile] = useState(null);
	const fileInputRef = useRef(null);
	const [error, setError] = useState('');
	const [selectedLanguage, setSelectedLanguage] = useState('English');
	let key = '';
	const [ErrorMessage, SetErrorMessage] = useState('');
	const [fileName, setFileName] = useState('');

	// useEffect(() => {
	// 	if (localStorage.getItem('language_id')) {
	// 		localStorage.getItem('language_id') == 2
	// 			? setSelectedLanguage('English')
	// 			: setSelectedLanguage('Russian');
	// 	} else {
	// 		if (localStorage.getItem('languages')) {
	// 			JSON.parse(localStorage.getItem('languages')).map((item) => {
	// 				if (item.language_id === currentLanguageId) {
	// 					setSelectedLanguage(item.langauge_name);
	// 				}
	// 			});
	// 		}
	// 	}
	// }, []);

	const handleKeyDown = (e) => {
		key = e.key;
	};
	const filterTable = ({ target: { value } }) => {
		const upperCaseValue = value.toUpperCase();

		if (value.length > 0) {
			if (key === 'Backspace') {
				if (themeRef.current[value.length] == undefined) {
					setThemeArray(themeArray);
				} else {
					setThemeArray(themeRef.current[0]);
				}
			} else {
				const filteredResources = themeArray.filter(
					(resource) =>
						resource?.[name]?.toUpperCase().includes(upperCaseValue) ||
						resource?.[secondName]?.toUpperCase().includes(upperCaseValue) ||
						resource?.[thirdName]?.toUpperCase().includes(upperCaseValue) ||
						resource?.[fourthName]?.toUpperCase().includes(upperCaseValue)
				);
				setThemeArray(filteredResources);
				themeRef.current = {
					...themeRef.current,
					[value.length]: filteredResources,
				};
			}
		} else {
			setThemeArray(themeRef.current[0]);
		}
	};

	const handleFileChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			setFile(selectedFile);
			setMessage(
				'File selected successfully. Please click on the Upload Excel Button to upload the file'
			);
			setFileName(selectedFile.name);
		} else {
			SetErrorMessage('Please select a file to upload');
		}
	};

	const handleFileUpload = async () => {
		if (!file) {
			setError('You must select a File before uploading');
			return;
		}
		setIsLoading(true);
		try {
			const result = await api(file);
			setIsLoading(false);
			setMessage('File uploaded successfully');
			setFileName('');
			setFile(null);
			window.location.reload();
		} catch (error) {
			setIsLoading(false);
			console.log('Error uploading file:', error);
			if (
				error.code == 'ERR_BAD_REQUEST' &&
				error.response.data.error.message
			) {
				SetErrorMessage(error.response.data.error.message);
				return;
			}
			if (
				error.code == 'ERR_BAD_REQUEST' &&
				error.response.data.error.message
			) {
				SetErrorMessage(
					'File Uploading Failed ! Please make sure the Excel file is in correct format as the file that you have downloaded from Download Excel Template Button'
				);
			}
		}
	};

	const handleLanguageSelect = async (language_id) => {
		localStorage.setItem('language_id', language_id);
		setIsLoading(true);
		switch (type) {
			case 'resource':
				api = adminApi.getResourceData;
				break;
			case 'process':
				api = adminApi.getProcessData;
				break;
			case 'waste':
				api = adminApi.getWasteData;
				break;
			case 'observation':
				api = adminApi.getObservationMasterList;
				break;
			case 'workarea':
				api = adminApi.getWorkAreaMasterList;
				break;
			case 'subTheme':
				api = adminApi.getSubThemeMasterList;
				break;

			default:
				break;
		}
		changeCurrentLanguage(language_id);

		JSON.parse(localStorage.getItem('languages')).map((item) => {
			if (item.language_id === language_id) {
				setSelectedLanguage(item.langauge_name);
			}
		});
		try {
			const result = await api(language_id);
			setIsLoading(false);

			setThemeArray(result?.data?.[type]);
			themeRef.current = {};
			themeRef.current = { ...themeRef, 0: result?.data?.[type] };
		} catch (error) {
			setIsLoading(false);
			handleApiError(error, setGlobalNetworkError, setError);
			console.log(error);
		}
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					marginBottom: '30px',
					justifyContent: 'space-between',
				}}
			>
				<div style={{ display: 'flex', flexShrink: 0, gap: '10px' }}>
					<div>
						<div
							className={'input-group' + ' ' + TCStyles.searchBox}
							style={{
								display: 'flex',
								flexDirection: 'row',
								border: '4px solid #ced4da',
								borderRadius: '5px',
							}}
						>
							<span
								className={'input-group-text' + ' ' + TCStyles.searchIcon}
								style={{ border: 'none' }}
							>
								<img src={Search} alt="Search" />
							</span>
							<input
								type="text"
								className={TCStyles.search}
								placeholder={placeholder}
								onKeyDown={(e) => handleKeyDown(e)}
								onChange={(e) => filterTable(e)}
								style={{
									border: 'none',
									width: type == 'observation' ? '415px' : 'auto',
									minWidth: '250px',
								}}
							/>
						</div>
					</div>

					{/* <DropdownButton
						title={selectedLanguage}
						className={TCStyles.dropdown}
						id="dropdown"
					>
						{localStorage.getItem('languages') &&
							JSON.parse(localStorage.getItem('languages')).map(
								(language, index) => (
									<Dropdown.Item
										style={{ fontSize: '18px' }}
										onClick={() => handleLanguageSelect(language.language_id)}
										eventKey="option-1"
										value={language.language_id}
										key={index}
										active={
											currentLanguageId == language.language_id ? true : false
										}
									>
										{language.langauge_name}
									</Dropdown.Item>
								)
							)}
					</DropdownButton> */}
					{/* <Dropdown>
						<Dropdown.Toggle id="dropdown">
							{selectedLanguage || 'Select an item'}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{localStorage.getItem('languages') &&
								JSON.parse(localStorage.getItem('languages')).map(
									(language, index) => (
										<Dropdown.Item
											style={{ fontSize: '18px' }}
											onClick={() => handleLanguageSelect(language.language_id)}
											eventKey="option-1"
											value={language.language_id}
											key={index}
											active={
												currentLanguageId == language.language_id ? true : false
											}
										>
											{language.langauge_name}
										</Dropdown.Item>
									)
								)}
						</Dropdown.Menu>
					</Dropdown> */}
				</div>
				<div>
					{type !== 'workarea' && type !== 'subTheme' && (
						<div
							style={{
								display: 'flex',
								gap: '10px',
							}}
						>
							<div style={{ position: 'relative' }}>
								<label
									htmlFor="file-input"
									style={{
										cursor: 'pointer',
										fontSize: '16px',
										marginRight: '4px',
									}}
									onClick={() => {
										setError('');
									}}
								>
									Choose file
								</label>
								<input
									id="file-input"
									type="file"
									accept=".xlsx, .xls"
									onChange={handleFileChange}
									ref={fileInputRef}
									style={{ display: 'none' }}
								/>

								<button
									className={'success_btn' + ' ' + TCStyles.addnewSTE}
									onClick={handleFileUpload}
									style={{
										width: '200px',
										padding: '6px 0px',
									}}
								>
									<b
										style={{
											fontSize: '16px',
											paddingRight: '20px',
											paddingLeft: '10px',
										}}
									>
										{' '}
										<i
											className="fa fa-arrow-up"
											style={{
												fontSize: '20px',
												marginRight: '10px',
											}}
										></i>
										Upload Excel File
									</b>
								</button>
								{!!fileName && (
									<span
										style={{
											position: 'absolute',
											top: '42px',
											left: '86px',
										}}
									>
										Selected File : <b>{fileName}</b>
									</span>
								)}
							</div>

							<div>
								<a
									href={excelFile}
									download={`${type}.xlsx`}
									style={{ fontSize: '16px', color: 'white' }}
								>
									<button
										style={{
											backgroundColor: '#294D8F',
											color: 'white',
											border: '2px solid #294D8F',
											padding: '7px',
											borderRadius: '5px',
										}}
									>
										<i
											className="fa fa-arrow-down"
											style={{ fontSize: '20px', marginRight: '5px' }}
										></i>
										<b>Download Excel Template</b>
									</button>
								</a>
							</div>
						</div>
					)}
				</div>
			</div>
			{error && (
				<Alert
					variant="danger"
					style={{
						fontSize: '20px',
						fontWeight: 'bold',
						textAlign: 'center',
					}}
				>
					{error}
				</Alert>
			)}
			<Modal
				className="newObservation removeConform"
				show={!!ErrorMessage}
				onHide={() => SetErrorMessage(false)}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{ErrorMessage}</h4>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default DatabaseHeader;
