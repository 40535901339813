// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_login__hZu51 {
	padding: 67px 20px 157px 0px;
}
.login_login__hZu51 h1 {
	font-weight: 700;
	font-size: 24px;
	color: #294d8f;
}
.login_login_form__UV9yM label {
	font-weight: 600;
	font-size: 14px;
	color: #294d8f;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/components/AdminLogin/login.module.css"],"names":[],"mappings":"AAAA;CACC,4BAA4B;AAC7B;AACA;CACC,gBAAgB;CAChB,eAAe;CACf,cAAc;AACf;AACA;CACC,gBAAgB;CAChB,eAAe;CACf,cAAc;AACf","sourcesContent":[".login {\r\n\tpadding: 67px 20px 157px 0px;\r\n}\r\n.login h1 {\r\n\tfont-weight: 700;\r\n\tfont-size: 24px;\r\n\tcolor: #294d8f;\r\n}\r\n.login_form label {\r\n\tfont-weight: 600;\r\n\tfont-size: 14px;\r\n\tcolor: #294d8f;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `login_login__hZu51`,
	"login_form": `login_login_form__UV9yM`
};
export default ___CSS_LOADER_EXPORT___;
