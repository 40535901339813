import React, { useState, useEffect, useContext, useRef } from 'react';
import companiesStyles from './companies.module.css';
import ActiveCompanies from '../iar/activeCompanies';
import ApprovedCompanies from '../iar/approvedCompanies';
import DeactivatedCompanies from '../iar/deactivatedCompanies';
import plus from '../../../../assets/images/admin/plus.svg';
import Search from '../../../../assets/images/admin/search.png';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import AdminDataServicve from '../../../../services/AdminDataServices';
import { GlobalContext } from '../../../../context/GlobalState';
import { useNavigate } from 'react-router-dom';
import useIframeResize from '../../../customHooks/useIframeResize';

const Companies = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const navigate = useNavigate();
	//search input
	const [search, setSearch] = useState('');
	const [steList, setSteList] = useState([]);
	//ste data
	const [steName, setSteName] = useState('');
	const [allCompanies, setAllCompanies] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const adminApi = new AdminDataServicve();
	const {
		setMessage,
		deactivatedCompanies,
		setDeactivatedCompanies,
		iarApprovedComapnies,
		setIarApprovedCompanies,
		activeCompanies,
		setActiveCompanies,
		currentTab,
		setCurrentTab,
		getCurrentCompany,
		setCurrentCompany,
		companiesRef,
	} = useContext(GlobalContext);

	const style = {
		padding: '13px 0px 8px 0px',
		backgroundColor: '#FFF4D0',
		border: '3px solid #294D8F',
		fontSize: '18px',
	};

	const style2 = {
		backgroundColor: '#FFFCF4',
		border: '3px solid #B8B8B8',
		padding: '8px 0px 10px 0px',

		fontSize: '15px',
	};

	useEffect(() => {
		//getting all companies
		document.title = 'Companies | RECO Wizard';
		async function fetchCompanies() {
			try {
				setIsLoading(true);
				const companies = await adminApi.getAllCompanies();
				filterCompanies(companies?.data[0]);
				setAllCompanies(companies?.data[0]);
				setIsLoading(false);
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		}

		//getting Ste list
		async function getSteList() {
			try {
				const ste = await adminApi.getSteList();
				setSteList(ste.data.ste);
			} catch (error) {
				console.log(error);
			}
		}
		getSteList();

		fetchCompanies();
	}, []);

	let key = '';

	const handleKeyDown = (e) => {
		key = e.key;
	};

	const filterCompanies = (companyArray) => {
		try {
			setActiveCompanies(
				JSON.parse(companyArray.ACTIVE_COMPANY_ARRAY)?.sort(sortByDate)
			);
			setIarApprovedCompanies(JSON.parse(companyArray.IAR_APPROVED_COMPANY));
			setDeactivatedCompanies(JSON.parse(companyArray.DEACTIVATED_COMPANY));
			if (currentTab == 'tab1') {
				companiesRef.current = {
					...companiesRef,
					0: JSON.parse(companyArray.ACTIVE_COMPANY_ARRAY)?.sort(sortByDate),
				};
			} else if (currentTab == 'tab2') {
				companiesRef.current = {
					...companiesRef,
					0: JSON.parse(companyArray.IAR_APPROVED_COMPANY)?.sort(sortByDate),
				};
			} else {
				companiesRef.current = {
					...companiesRef,
					0: JSON.parse(companyArray.DEACTIVATED_COMPANY)?.sort(sortByDate),
				};
			}
		} catch (error) {
			console.log(error);
		}
	};

	//SEARCH TABLE
	const searchTable = ({ target: { value } }) => {
		setSearch(value);
		let upperCaseValue = value.toUpperCase();
		const currentCompany = getCurrentCompany();
		if (value.length > 0) {
			if (key === 'Backspace') {
				setCurrentCompany(companiesRef.current[value.length]);
			} else {
				const filteredCompanyArray = currentCompany.filter(
					(company) =>
						company?.company_code?.toUpperCase().includes(upperCaseValue) ||
						company?.company_name?.toUpperCase().includes(upperCaseValue) ||
						company?.ste_name?.toUpperCase().includes(upperCaseValue) ||
						String(company?.company_status)
							.toUpperCase()
							.includes(upperCaseValue) ||
						company?.user_first_name?.toUpperCase().includes(upperCaseValue) ||
						String(company?.company_iav_date)
							?.toUpperCase()
							.includes(upperCaseValue) ||
						String(company?.company_created_date)
							.toUpperCase()
							.includes(upperCaseValue)
				);
				setCurrentCompany(filteredCompanyArray);
				companiesRef.current = {
					...companiesRef.current,
					[value.length]: filteredCompanyArray,
				};
			}
		} else {
			try {
				currentTab == 'tab1'
					? setActiveCompanies(
							JSON.parse(allCompanies?.ACTIVE_COMPANY_ARRAY)?.sort(sortByDate)
					  )
					: currentTab == 'tab2'
					? setIarApprovedCompanies(
							JSON.parse(allCompanies?.IAR_APPROVED_COMPANY).sort(sortByDate)
					  )
					: setDeactivatedCompanies(
							JSON.parse(allCompanies?.DEACTIVATED_COMPANY).sort(sortByDate)
					  );
			} catch (error) {
				console.error('Error parsing JSON:', error);
			}
		}
	};

	const addNewSTE = async () => {
		try {
			await adminApi.addNewSte({ ste_name: steName });
			setShow(false);
			const ste_id =
				steList?.length > 0 ? steList[steList.length - 1].ste_id + 1 : 1;
			const newSteList = [...steList, { ste_id: ste_id, ste_name: steName }];
			setSteList(newSteList);
			setMessage('New RC Added Successfully');
			setSteName('');
		} catch (error) {
			console.log(error);
		}
	};

	const changeTabs = async (tabname) => {
		setCurrentTab(tabname);
	};

	const comapaniesCount = (count) => {
		return count > 0 ? count : 0;
	};

	//sorting the companies by date
	function sortByDate(a, b) {
		let dateType =
			currentTab == 'tab1'
				? 'company_iav_date'
				: currentTab == 'tab2'
				? 'approval_date'
				: 'deactivation_date';

		const dateA = a[dateType] ? new Date(a[dateType].split(' ')[0]) : null;
		const dateB = b[dateType] ? new Date(b[dateType].split(' ')[0]) : null;

		// Check for null values
		if (dateA === null && dateB === null) {
			return 0;
		}
		if (dateA === null) {
			return -1;
		}
		if (dateB === null) {
			return 1;
		}

		// Sort by date in descending order (latest first)
		if (dateA > dateB) {
			return -1;
		}
		if (dateA < dateB) {
			return 1;
		}
		return 0;
	}

	return (
		<div className="deskWrapper">
			<div className="container">
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						flexDirection: 'row',
					}}
				>
					<div>
						<div
							className={'input-group mb-3' + '' + companiesStyles.searchBox}
							style={{
								display: 'flex',
								flexDirection: 'row',
								border: '4px solid #ced4da',
								borderRadius: '5px',
							}}
						>
							<span
								className={
									'input-group-text' + ' ' + companiesStyles.searchIcon
								}
								style={{ border: 'none' }}
							>
								<img src={Search} alt="Search" />
							</span>
							<input
								type="text"
								className={companiesStyles.search}
								style={{ border: 'none', width: '433px' }}
								placeholder="Search by Company Code, Name, RC, TC, Date IA Visit and Status"
								value={search}
								onKeyDown={(e) => handleKeyDown(e)}
								onChange={(e) => searchTable(e)}
							/>
						</div>
					</div>
					<div className="col-md-6 text-end">
						<button
							className={'success_btn' + ' ' + companiesStyles.addnewSTE}
							onClick={handleShow}
							style={{ border: '3px solid #294D8F' }}
						>
							<img
								src={plus}
								alt="Plus"
								style={{ margin: '0px 10px 4px 0px', width: '17px' }}
							/>
							<b style={{ fontSize: '16px' }}>Add New RC</b>
						</button>

						<Modal
							className="deactivateCompany"
							show={show}
							onHide={handleClose}
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header closeButton></Modal.Header>
							<Modal.Body>
								<div className="row">
									<div className="col-12 nameSTE">
										<Form>
											<Form.Group className="mb-3" controlId="formBasicEmail">
												<Form.Label
													style={{ fontWeight: 'bold', fontSize: '20px' }}
												>
													Please mention Full Name of RC
												</Form.Label>
												<Form.Control
													type="text"
													placeholder="Enter full name"
													style={{ padding: '12px 10px' }}
													value={steName}
													onChange={(e) => setSteName(e.target.value)}
												/>
												<div
													className="primary_btn small_btn"
													onClick={addNewSTE}
													style={{
														marginLeft: '15px',
														marginTop: '9px',
													}}
												>
													Save
												</div>
											</Form.Group>
										</Form>
									</div>
								</div>
							</Modal.Body>
						</Modal>
					</div>
				</div>
				<div className="row mt-5">
					<div className={companiesStyles.tab}>
						<span
							className={companiesStyles.tablinks}
							onClick={() => changeTabs('tab1')}
							style={currentTab == 'tab1' ? style : style2}
						>
							Active Companies{' '}
							<span
								className={companiesStyles.numberOfCompanies}
								style={{ bottom: currentTab == 'tab1' ? '9px' : '7px' }}
							>
								{comapaniesCount(activeCompanies?.length)}
							</span>{' '}
						</span>

						<span
							className={companiesStyles.tablinks}
							onClick={() => changeTabs('tab2')}
							style={currentTab == 'tab2' ? style : style2}
						>
							IAR Approved Companies
							<span
								className={companiesStyles.numberOfCompanies}
								style={{ bottom: currentTab == 'tab2' ? '9px' : '7px' }}
							>
								{comapaniesCount(iarApprovedComapnies?.length)}
							</span>
						</span>
						<span
							className={companiesStyles.tablinks}
							onClick={() => changeTabs('tab3')}
							style={currentTab == 'tab3' ? style : style2}
						>
							Deactivated Companies
							<span
								className={companiesStyles.numberOfCompanies}
								style={{ bottom: currentTab == 'tab3' ? '9px' : '7px' }}
							>
								{comapaniesCount(deactivatedCompanies?.length)}
							</span>
						</span>
					</div>
					<div className={companiesStyles.tabsContainer}>
						{currentTab == 'tab1' ? (
							<ActiveCompanies
								companies={activeCompanies}
								steList={steList}
								isLoading={isLoading}
							/>
						) : currentTab == 'tab2' ? (
							<ApprovedCompanies
								companies={iarApprovedComapnies?.sort(sortByDate)}
								steList={steList}
								isLoading={isLoading}
							/>
						) : (
							<DeactivatedCompanies
								companies={deactivatedCompanies?.sort(sortByDate)}
								steList={steList}
								isLoading={isLoading}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Companies;
