import { useEffect, useState, useRef } from 'react';
import DataService from '../../services/DataServices';
import GalleryStyle from '../iar/tc_gallery/gallery_style.module.css';
import { useLocation } from 'react-router-dom';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import Modal from 'react-bootstrap/Modal';
import AuthUser from '../authUser/authuser';
import { RiDeleteBinLine } from 'react-icons/ri';
import Loader from '../../assets/images/loadin_gif.gif';
import { FaPlus } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';
import Compressor from 'compressorjs';
import useNavigation from '../customHooks/useLocationHook';

const AddGalleryPage = () => {
	let { lanId } = useLanguageChange();
	const { t } = useTranslation();
	const { logout } = AuthUser();
	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;
		document.cookie = currentPath;
		localStorage.setItem('enablerLocation', currentPath);

		// You can store the currentPath in state or perform other actions here
	});

	const inputRef = useRef();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
	const [images, setImages] = useState([]);
	const [imageUploadPopup, setImageUploadPopup] = useState(false);
	const [imageDetails, setImageDetails] = useState({
		caption: '',
		company_code: '',
		company_id: '',
		image_name: '',
		imageBinary: '',
	});
	const [Error, setError] = useState({});
	const [serverError, SetServerError] = useState('');
	const maxNumber = 1;
	const api = new DataService();
	const [GalleryData, setGalleryData] = useState();
	const [SaveConform, setSaveConform] = useState(false);
	const [SaveSuccessPopup, setSaveSuccessPopup] = useState(false);
	const [Message, setMessage] = useState();
	const [deleteConformPopup, SetdeleteConformPopup] = useState(false);
	const [galleryId, setGalleryId] = useState();
	const [LoaderImg, setLoaderImg] = useState(true);
	const [saveLoaderImg, setsaveLoaderImg] = useState(false);
	const [deleteLoaderImg, setDeleteLoader] = useState(false);
	const [picture, setPicture] = useState(null);
	const [imgData, setImgData] = useState(null);
	const handleClose = () => {
		setImageUploadPopup(false);
		setSaveSuccessPopup(false);
		SetdeleteConformPopup(false);
		setImageDetails({ ...imageDetails, caption: '' });
		setImgData(null);
		setError({});
	};

	const handleCloseConform = () => {
		setMessage('');
		setSaveConform(false);
		SetdeleteConformPopup(false);
	};
	const GetgalleryData = async () => {
		let data = {
			company_id: comp_url,
		};
		await api
			.getGallery(data)
			.then((response) => {
				console.log(response);
				if (response.status == 200 || response.status == 201) {
					let gallerydata = response.data;
					setTimeout(() => {
						setGalleryData(gallerydata.GalleryList);
						setLoaderImg(false);
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let message = error.response.data.error.message;
					let errorCode = error.response.status;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						setTimeout(() => {
							setLoaderImg(false);
						}, 2000);
						let errorLogData = {
							api_name: 'getgalleryData',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setLoaderImg(false);
				}
			});
	};
	const getCompanyDetails = async () => {
		let data = {
			company_id: comp_url,
		};
		await api
			.getcompanybyid(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let com_data = response.data.company;
					setImageDetails({
						...imageDetails,
						company_code: com_data.length > 0 && com_data[0].company_code,
						company_id: com_data.length > 0 && com_data[0].company_id,
					});
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let message = error.response.data.error.message;
					let errorCode = error.response.status;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						SetServerError(error.response.data.error.message);
						setTimeout(() => {
							setLoaderImg(false);
						}, 2000);
						let errorLogData = {
							api_name: 'getcompanybyid',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
					}
				}
			});
	};
	const openGalleryPopup = () => {
		setImageDetails({
			caption: '',
			company_code: imageDetails.company_code,
			company_id: imageDetails.company_id,
			image_name: '',
			imageBinary: '',
		});
		setImageUploadPopup(true);
		setTimeout(() => {
			inputRef.current.focus();
		}, 10);
	};
	const onChange = (imageList) => {
		let imageFile = imageList.target.files[0];
		let imageName = imageList.target.files[0].name;
		let megaBytes = 4500000;
		setLoaderImg(true);
		new Compressor(imageFile, {
			quality: 0.8,
			success: (compressedResult) => {
				let FileSize = compressedResult.size;
				if (FileSize < megaBytes) {
					if (compressedResult) {
						setPicture(compressedResult);
						const reader = new FileReader();
						reader.addEventListener('load', () => {
							setTimeout(() => {
								setError({ ...Error, imageBinary: '' });
								setImageDetails({
									...imageDetails,
									image_name: imageName,
									imageBinary: reader.result,
								});
								setLoaderImg(false);
								setImgData(reader.result);
							}, 2000);
						});
						reader.readAsDataURL(compressedResult);
					}
				} else {
					setTimeout(() => {
						setLoaderImg(false);
						setError({
							...Error,
							imageBinary: t('imageSize'),
						});
					}, 2000);
				}
			},
		});
	};
	const inputHandleChange = (e) => {
		const { name, value } = e.target;
		setImageDetails({ ...imageDetails, caption: value });
	};
	const saveImageStatus = (status) => {
		if (status == 'yes') {
			setSaveConform(false);
			saveImage();
		} else {
			setSaveConform(false);
		}
	};
	const saveImage = async () => {
		let errorValue = validation(imageDetails);
		setError(errorValue);
		setsaveLoaderImg(true);
		setLoaderImg(true);
		if (Object.keys(errorValue).length === 0) {
			let data = imageDetails;
			try {
				await api.saveGallerydata(data).then((res) => {
					if (res.status == 200 || res.status == 201) {
						let message = res.data.message;
						setMessage(message);
						setImageUploadPopup(false);
						setSaveSuccessPopup(true);
						GetgalleryData();
						setImgData(null);
						setTimeout(() => {
							setsaveLoaderImg(false);
							setLoaderImg(false);
						}, 2000);
					}
				});
			} catch (error) {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let message = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						setTimeout(() => {
							SetServerError(message);
							setsaveLoaderImg(false);
							setLoaderImg(false);
						}, 2000);
						let errorLogData = {
							api_name: 'savegalleryData',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setImageUploadPopup(false);
					setSaveSuccessPopup(true);
					setLoaderImg(false);
					setsaveLoaderImg(false);
					setMessage(
						error.message + '. Please check your internet connection.'
					);
				}
			}
		} else {
			setLoaderImg(false);
		}
	};
	const validation = (value) => {
		let error = {};
		if (!value.caption) {
			error.caption = t('captionValidation');
		}
		if (!value.imageBinary) {
			error.imageBinary = t('imageType');
		}
		return error;
	};
	//galleryId
	const DeletePopupFunc = (galleryId) => {
		let gallery_id = galleryId;
		setGalleryId(gallery_id);
		SetdeleteConformPopup(true);
	};
	const deleteImageStatus = (status) => {
		if (status == 'yes') {
			setLoaderImg(true);
			SetdeleteConformPopup(false);
			deleteImage();
		} else {
			SetdeleteConformPopup(false);
		}
	};
	const deleteImage = async () => {
		setDeleteLoader(true);
		let data = {
			gallery_id: galleryId,
		};
		await api
			.deleteGallerydata(data)
			.then((res) => {
				if (res.status == 200 || res.status == 201) {
					setMessage(res.data.message);
					setSaveSuccessPopup(true);
					GetgalleryData();
					setTimeout(() => {
						setDeleteLoader(false);
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let message = error.response.data.error.message;
					if (errorCode == 401) {
						SetServerError(message);
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						let errorLogData = {
							api_name: 'deleteimage',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setTimeout(() => {
						setDeleteLoader(false);
					}, 2000);
					setSaveSuccessPopup(true);
					setMessage(
						error.message + '. Please check your internet connection.'
					);
				}
			});
	};
	useEffect(() => {
		GetgalleryData();
		getCompanyDetails();
	}, []);
	return (
		<div className="container ">
			<div className="mt-4">
				{window.innerWidth > 768 ? <TcBreadCrumb title={t('Gallery')} /> : null}
				<div className="col-12 px-2">
					<button className="secondary_btn" onClick={() => openGalleryPopup()}>
						<FaPlus style={{ marginRight: '5px', marginBottom: '2px' }} />{' '}
						{t('Addphotosmobilelaptop')}
					</button>
				</div>
				<div
					className={
						'selectGalleryImg px-2 mt-3' + ' ' + GalleryStyle.galleryFlex
					}
					style={{ height: '450px' }}
				>
					{GalleryData != undefined && GalleryData.length > 0 ? (
						GalleryData.map((item, index) => (
							<div key={index} className="">
								<label
									htmlFor={item.gallery_id}
									className={GalleryStyle.labelcheck}
								>
									<img
										src={item?.media_url}
										alt={item?.gallery_caption}
										width={'100px'}
									/>
									<div>
										<RiDeleteBinLine
											className={GalleryStyle.deleteImage}
											onClick={() => DeletePopupFunc(item.gallery_id)}
										/>
									</div>
									<div className={GalleryStyle.textOverflow}>
										{item.gallery_caption}
									</div>
								</label>
							</div>
						))
					) : LoaderImg == true ? (
						<img
							src={Loader}
							style={{
								width: '180px',
								height: '50px',
								border: 'none',
								padding: '5px',
							}}
							alt="loader"
						/>
					) : (
						<div className="widthGallery">{t('noImage')}</div>
					)}
				</div>
			</div>
			<Modal
				className="newObservation removeConform"
				show={imageUploadPopup}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0">
						<p className="mb-1">{t('AddimageCaption')}</p>
						<input
							type="text"
							name="caption"
							className={GalleryStyle.imageCaption}
							value={imageDetails.caption}
							onChange={(e) => inputHandleChange(e)}
							ref={inputRef}
							onBlur={() => setError({ ...Error, caption: '' })}
						/>
						{LoaderImg == true ? (
							<img
								src={Loader}
								style={{ width: '120px', height: '32px', border: 'none' }}
								alt="loader"
							/>
						) : null}
						<div className="form-text text-danger">{Error.caption}</div>

						<div className="mt-3">
							{imgData == null ? (
								<div className="col-12">
									<label className={'primary_btn'}>
										<input
											type="file"
											name="file-uploader"
											onInput={(e) => onChange(e)}
											style={{ display: 'none' }}
											accept=".jpg,.png"
										/>

										{t('selectImage')}
									</label>
								</div>
							) : null}

							{imgData != null ? (
								<div>
									<img
										src={imgData}
										alt=""
										className={GalleryStyle.galleryImage}
									/>
								</div>
							) : null}
							{imgData != null ? (
								<div className="col-12 mt-2">
									<label className="primary_btn">
										<input
											type="file"
											name="file-uploader"
											onInput={(e) => onChange(e)}
											style={{ display: 'none' }}
											accept=".jpg,.png"
										/>
										{t('Update')}
									</label>
									<button
										onClick={() => setSaveConform(true)}
										className="primary_btn"
									>
										{t('saveAndExit')}
									</button>
								</div>
							) : null}

							<div className="form-text text-danger">{Error.imageBinary}</div>
							<div className="form-text text-danger">
								{serverError && serverError}
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={SaveConform}
				onHide={handleCloseConform}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{t('DoyouWantsaveConform')}</h4>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveImageStatus('no')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => saveImageStatus('yes')}
						>
							{t('Yes')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={SaveSuccessPopup}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						{saveLoaderImg == true ? (
							<img
								src={Loader}
								style={{ width: '180px', height: '50px', border: 'none' }}
								alt="loader"
							/>
						) : null}
						{deleteLoaderImg == true ? (
							<img
								src={Loader}
								style={{ width: '180px', height: '50px', border: 'none' }}
								alt="loader"
							/>
						) : null}
						{deleteLoaderImg == false && saveLoaderImg == false ? (
							<>
								<h4 className="mb-4">{Message && Message}</h4>
								<button
									className="secondary_btn small_btn"
									onClick={() => setSaveSuccessPopup(false)}
								>
									{t('Ok')}
								</button>
							</>
						) : null}
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={deleteConformPopup}
				onHide={handleCloseConform}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{t('DoyouWantDelete')}</h4>
						<div className="form-text text-danger">
							{serverError && serverError}
						</div>
						<button
							className="secondary_btn small_btn"
							onClick={() => deleteImageStatus('no')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => deleteImageStatus('yes')}
						>
							{t('Yes')}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default AddGalleryPage;
