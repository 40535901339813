import React, { useState } from 'react';
import Style from './companydetail.module.css';
import DataService from '../../services/DataServices';
import { useEffect } from 'react';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import { useLocation } from 'react-router-dom';
import AuthUser from '../authUser/authuser';
import Loader from '../../assets/images/loadin_gif.gif';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import { ErrorLog } from '../erroLog/errorLog';
import Nodata from '../../assets/images/noData.png';
import useNavigation from '../customHooks/useLocationHook';

const CompanyDetails = () => {
	const { logout } = AuthUser();
	const { t } = useTranslation();
	useLanguageChange();

	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;
		document.cookie = currentPath;
		localStorage.setItem('enablerLocation', currentPath);

		// You can store the currentPath in state or perform other actions here
	});

	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
	const api = new DataService();
	const [comapnyDetail, setCompanydetail] = useState();
	const [companyProducts, setProducts] = useState([]);
	const [LoaderImg, setLoaderImg] = useState(true);
	const GetCompanyDetails = async () => {
		setLoaderImg(true);
		let data = {
			company_id: com_id,
		};
		await api
			.getcompanybyid(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let responsedata = response.data.company[0];
					let products =
						responsedata != undefined && responsedata.company_product_json;
					setCompanydetail(responsedata);
					setProducts(JSON.parse(products));
					setTimeout(() => {
						setLoaderImg(false);
					}, 2000);
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let message = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						let errorLogData = {
							api_name: 'getcompanybyid',
							error_code: errorCode,
							error_message: message,
						};
						ErrorLog(errorLogData);
						setTimeout(() => {
							setLoaderImg(false);
						}, 2000);
					}
				}
			});
	};
	useEffect(() => {
		GetCompanyDetails();
	}, []);
	return (
		<div className="mobileWrapper">
			{window.innerWidth > 768 ? (
				<TcBreadCrumb title="Company Details" />
			) : null}
			{comapnyDetail != undefined ? (
				<div className="container">
					<div>
						<div className={Style.company_name}>
							{comapnyDetail && comapnyDetail.company_name}
							<br />
							{companyProducts &&
								companyProducts.map((item, index) => (
									<div key={index} className={Style.productsCom}>
										<span className={Style.company_details_subtxt}>
											{item.name}
										</span>
									</div>
								))}
						</div>
						<div>
							<p className={Style.company_adress} style={{ fontSize: '16px' }}>
								<br />
								{comapnyDetail && comapnyDetail.company_address_line1}
								<br />
								{comapnyDetail && comapnyDetail.company_address_line2}
								<br />
								{comapnyDetail && comapnyDetail.region_name}
								<br />
								{comapnyDetail && comapnyDetail.country_name}
							</p>
						</div>
						<div className={Style.contact_persons_details}>
							<p
								className={Style.contact_person_txt}
								style={{ color: '#6c6c6c', fontSize: '19px' }}
							>
								{t('PersonalDetails')}
							</p>
							<p>
								<span
									className={Style.contact_person_lhs}
									style={{ fontSize: '16px' }}
								>
									{t('Name')}:
								</span>
								<span
									style={{ fontSize: '16px', textTransform: 'capitalize' }}
									className={Style.contact_person_rhs}
								>
									{comapnyDetail && comapnyDetail.company_contact_person_name}
								</span>
							</p>
							<p>
								<span
									style={{ fontSize: '16px' }}
									className={Style.contact_person_lhs}
								>
									{t('Designation')}:
								</span>
								<span
									style={{ fontSize: '16px', textTransform: 'capitalize' }}
									className={Style.contact_person_rhs}
								>
									{comapnyDetail && comapnyDetail.company_contact_person_desg}
								</span>
							</p>
							<p>
								<span
									style={{ fontSize: '16px' }}
									className={Style.contact_person_lhs}
								>
									{t('PhoneNumber')}:
								</span>
								<span
									style={{ fontSize: '16px' }}
									className={Style.contact_person_rhs}
								>
									{comapnyDetail && comapnyDetail.company_contact_person_phone}
								</span>
							</p>
							<p>
								<span
									style={{ fontSize: '16px' }}
									className={Style.contact_person_lhs}
								>
									{t('Email')}:
								</span>
								<span
									style={{ fontSize: '16px' }}
									className={Style.contact_person_rhs}
								>
									{comapnyDetail && comapnyDetail.company_contact_person_email}
								</span>
							</p>
						</div>
					</div>
				</div>
			) : LoaderImg == true ? (
				<div className="container">
					<img src={Loader} alt="loader" style={{ width: '150px' }} />
				</div>
			) : (
				<div className="nodataFound">
					<img src={Nodata} alt="no data" style={{ width: '60px' }} />{' '}
					{t('noCompanyDetails')}
				</div>
			)}
		</div>
	);
};
export default CompanyDetails;
