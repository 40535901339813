// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uploadSign_upload__jZ\\+Ig {
	padding: 35px 20px;
}

.uploadSign_upload__jZ\\+Ig h1 {
	font-weight: 700;
	font-size: 24px;
	color: #294d8f;
}

.uploadSign_upload__jZ\\+Ig p {
	color: #6d6d6d;
	font-size: 12px;
}

.uploadSign_signature__ayaPf {
	background-color: #ffffff;
	color: #294d8f;
	border: #294d8f 2px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 10px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 300px;
	font-family: 'Robotomedium';
}

input[type='file'] {
	display: none;
}

.uploadSign_upload__jZ\\+Ig img {
	width: 14px;
	margin-right: 10px;
}
@media screen and (max-width: 450px) {
	.uploadSign_signature__ayaPf {
		min-width: 80px;
		padding: 8px 50px;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/uploadSignature/uploadSign.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;AACnB;;AAEA;CACC,gBAAgB;CAChB,eAAe;CACf,cAAc;AACf;;AAEA;CACC,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,yBAAyB;CACzB,cAAc;CACd,yBAAyB;CACzB,iBAAiB;CACjB,eAAe;CACf,mBAAmB;CACnB,gBAAgB;CAChB,gBAAgB;CAChB,WAAW;CACX,kBAAkB;CAClB,qBAAqB;CACrB,kBAAkB;CAClB,gBAAgB;CAChB,2BAA2B;AAC5B;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,WAAW;CACX,kBAAkB;AACnB;AACA;CACC;EACC,eAAe;EACf,iBAAiB;CAClB;AACD","sourcesContent":[".upload {\r\n\tpadding: 35px 20px;\r\n}\r\n\r\n.upload h1 {\r\n\tfont-weight: 700;\r\n\tfont-size: 24px;\r\n\tcolor: #294d8f;\r\n}\r\n\r\n.upload p {\r\n\tcolor: #6d6d6d;\r\n\tfont-size: 12px;\r\n}\r\n\r\n.signature {\r\n\tbackground-color: #ffffff;\r\n\tcolor: #294d8f;\r\n\tborder: #294d8f 2px solid;\r\n\tpadding: 8px 15px;\r\n\tfont-size: 14px;\r\n\tborder-radius: 10px;\r\n\tfont-weight: 500;\r\n\tline-height: 1.5;\r\n\tmargin: 2px;\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\ttext-align: center;\r\n\tmin-width: 300px;\r\n\tfont-family: 'Robotomedium';\r\n}\r\n\r\ninput[type='file'] {\r\n\tdisplay: none;\r\n}\r\n\r\n.upload img {\r\n\twidth: 14px;\r\n\tmargin-right: 10px;\r\n}\r\n@media screen and (max-width: 450px) {\r\n\t.signature {\r\n\t\tmin-width: 80px;\r\n\t\tpadding: 8px 50px;\r\n\t}\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `uploadSign_upload__jZ+Ig`,
	"signature": `uploadSign_signature__ayaPf`
};
export default ___CSS_LOADER_EXPORT___;
