import React from 'react';
import Style from './Gallery.module.css';

const GalleryPDF = ({ gallery = [] }) => {
	const chunkImages = (images, perChunk) => {
		return images.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk);
			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = [];
			}
			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);
	};

	const chunks = chunkImages(gallery, 2);

	return (
		<div style={{ pageBreakBefore: 'always' }}>
			<div>
				<p className="table_header" style={{ color: '#50AB92' }}>
					4. On-site Photographs
				</p>
				<div>
					<table style={{ width: '100%' }}>
						<tbody>
							{chunks.map((chunk, index) => (
								<tr key={index}>
									{chunk.map((subItem, idx) => (
										<td
											key={idx}
											className={Style.imageOnsite}
											style={{ border: '1px solid black', width: '50%' }}
										>
											<img
												src={`data:image/jpg;base64,${subItem.linkedImg}`}
												alt={subItem.gallery_caption}
												className="img-fluid"
												style={{
													width: '100%',
													objectFit: 'contain',
													padding: '10px',
													height: '475px',
												}}
											/>
											<p className="mt-3">
												<span>{subItem.gallery_name}: </span>
												{subItem.gallery_caption}
											</p>
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default GalleryPDF;
