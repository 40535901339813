import React, { useEffect, useLayoutEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useMasterdata } from '../customHooks/masterDatahook';
import { useLocation } from 'react-router-dom';
import DataService from '../../services/DataServices';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguageChange } from '../customHooks/lan_hook';
import AuthUser from '../authUser/authuser';
import Loader from '../../assets/images/loadin_gif.gif';
import { ErrorLog } from '../erroLog/errorLog';
import useNavigation from '../customHooks/useLocationHook';

const AddNewObsMob = () => {
	const { logout } = AuthUser();
	const { t } = useTranslation();
	useLanguageChange();
	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;
		document.cookie = currentPath;
		localStorage.setItem('enablerLocation', currentPath);

		// You can store the currentPath in state or perform other actions here
	});

	const navigate = useNavigate();
	const api = new DataService();
	const [cutomWorkarea, setCustomworkArea] = useState(false);
	const [cutomTheme, setCustomTheme] = useState(false);
	const [cutomObservation, setCutomObservation] = useState(false);
	const { workArea, SubTheme, observation } = useMasterdata();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let comp_url = pathName.substring(pathName.lastIndexOf('/') + 1);
	const [saveConformModel, setSaveConformModel] = useState(false);
	const [popUpMessage, setPopupMessage] = useState(false);
	const [message, setMessage] = useState('');
	const [errorValue, setErrorValue] = useState({});
	const [NavigateStatus, setNavigateStatus] = useState('');
	const [LoaderSave, setLoaderSave] = useState(false);
	const [ErroMessage, setErroMessage] = useState('');
	const handleClose = () => {
		setSaveConformModel(false);
		setPopupMessage(false);
		setMessage('');
		setErroMessage('');
	};
	const getLocalItemsWorkArea = () => {
		let localWorkArea = localStorage.getItem('currentWorkArea');
		if (localWorkArea != null) {
			return JSON.parse(localStorage.getItem('currentWorkArea'));
		}
	};
	const getLocalItemsSubtheme = () => {
		let localWorkArea = localStorage.getItem('currentSubtheme');
		if (localWorkArea != null) {
			return JSON.parse(localStorage.getItem('currentSubtheme'));
		}
	};
	const getLocalItemsObservation = () => {
		let localWorkArea = localStorage.getItem('currentObservation');
		if (localWorkArea != null) {
			return JSON.parse(localStorage.getItem('currentObservation'));
		}
	};
	const [workAreaInput, setWorkAreaInput] = useState(
		getLocalItemsWorkArea() != undefined ? getLocalItemsWorkArea() : []
	);
	const [subThemeInput, setSubThemeInput] = useState(
		getLocalItemsSubtheme() != undefined ? getLocalItemsSubtheme() : []
	);
	const [observationInput, setObservationInput] = useState(
		getLocalItemsObservation() != undefined ? getLocalItemsObservation() : []
	);
	const [filterSubtheme, setFilterSubtheme] = useState();
	const [FilterObservation, setFilterObservation] = useState();
	const [disabledSelectSubtheme, SetdisabledSelectSubtheme] = useState(true);
	const [disabledSelectObs, SetdisabledSelectObs] = useState(true);
	// const DefaultViewWorkarea = () => {
	//     let currentWorkarea = document.querySelector(".workAreaclass").id;
	//     if (currentWorkarea == -1) {
	//         setCustomworkArea(false)
	//     }
	//     else if (currentWorkarea == 0) {
	//         setCustomworkArea(true)
	//     }
	//     else {
	//         setCustomworkArea(false)
	//     }

	// }
	// const DefaultviewSubtheme = () => {
	//     let currentSubtheme = document.querySelector(".subthemeClass").id;
	//     if (currentSubtheme == -1) {
	//         setCustomTheme(false)
	//     }
	//     else if (currentSubtheme == 0) {
	//         setCustomTheme(true)
	//     }
	//     else {
	//         setCustomTheme(false)
	//     }
	// }
	// const DefaultviewObservation = () => {
	//     let currentObservation = document.querySelector(".observation").id;
	//     if (currentObservation == -1) {
	//         setCutomObservation(false)
	//     }
	//     else if (currentObservation == 0) {
	//         setCutomObservation(true)
	//     }
	//     else {
	//         setCutomObservation(false)
	//     }
	// }
	const handleSelectChange = (e) => {
		let { name, value } = e.target;
		console.log(name, value);
		var options = e.target.options;
		if (name == 'work_area') {
			if (value == 'customWorkarea') {
				setCustomworkArea(true);
				// setCustomTheme(true);
				// setCutomObservation(true);
				setWorkAreaInput([
					{
						work_area_id: 0,
						work_area_name: '',
					},
				]);
				// setSubThemeInput([
				// 	{
				// 		sub_theme_id: 0,
				// 		sub_theme_name: '',
				// 	},
				// ]);
				// setObservationInput([
				// 	{
				// 		observation_id: 0,
				// 		observation_name: '',
				// 	},
				// ]);
				return;
			} else {
				setCustomworkArea(false);
				setCustomTheme(false);
				setCutomObservation(false);
				SetdisabledSelectSubtheme(false);
				var workId = options[options.selectedIndex].id;
				let FilterSubtheme =
					SubTheme && SubTheme.filter((item) => workId == item.work_area_id);
				setFilterSubtheme(FilterSubtheme);
				setWorkAreaInput([
					{
						work_area_id: workId,
						work_area_name: value,
					},
				]);
			}
		}

		if (name == 'sub_theme') {
			if (value == 'customTheme') {
				setCustomworkArea(false);
				setCustomTheme(true);
				setCutomObservation(true);
				setSubThemeInput([
					{
						sub_theme_id: 0,
						sub_theme_name: '',
					},
				]);
				setObservationInput([
					{
						observation_id: 0,
						observation_name: '',
					},
				]);
			} else {
				var subId = options[options.selectedIndex].id;
				setCustomTheme(false);
				setCutomObservation(false);
				// setCustomworkArea(false);
				SetdisabledSelectObs(false);
				let FilterObser =
					observation &&
					observation.filter(
						(item) => item.work_area_id && item.sub_theme_id == subId
					);
				setFilterObservation(FilterObser);
				setSubThemeInput([
					{
						sub_theme_id: subId,
						sub_theme_name: value,
					},
				]);
				setObservationInput([
					{
						observation_id: 0,
						observation_name: '',
					},
				]);
			}
		}
		if (name == 'observation') {
			if (value == 'customObservation') {
				setCustomworkArea(false);
				setCustomTheme(false);
				setCutomObservation(true);
				setObservationInput([
					{
						observation_id: 0,
						observation_name: '',
					},
				]);
			} else {
				var obsId = options[options.selectedIndex].id;
				setCutomObservation(false);
				setObservationInput([
					{
						observation_id: obsId,
						observation_name: value,
					},
				]);
			}

			//setCutomObservation(true)
		}
	};
	const handleCustomInput = (e) => {
		let { name, value } = e.target;
		if (name == 'work_area') {
			setWorkAreaInput([
				{
					work_area_id: 0,
					work_area_name: value,
				},
			]);
		}
		if (name == 'sub_theme') {
			setSubThemeInput([
				{
					sub_theme_id: 0,
					sub_theme_name: value,
				},
			]);
		}
		if (name == 'observation') {
			setObservationInput([
				{
					observation_id: 0,
					observation_name: value,
				},
			]);
		}
	};
	const errorValidation = (workArea, subTheme, observation) => {
		let error = {};
		if (workArea.length == 0 || !workArea[0].work_area_name) {
			error.workName = t('WorkAreavalidation');
		}
		if (subTheme.length == 0 || !subTheme[0].sub_theme_name) {
			error.subName = t('subThemevalidation');
		}
		if (observation.length == 0 || !observation[0].observation_name) {
			error.obsName = t('observationValidation');
		}
		return error;
	};
	const saveConformation = (e, navigateStatus) => {
		e.preventDefault();
		setMessage('');
		setNavigateStatus(navigateStatus);
		let errorAllValue = errorValidation(
			workAreaInput,
			subThemeInput,
			observationInput
		);
		setErrorValue(errorAllValue);
		if (Object.keys(errorAllValue).length === 0) {
			setSaveConformModel(true);
			// navigate('/dashboard');
		}
	};
	const confromationFunction = (status) => {
		setMessage('');
		if (status == 'yes') {
			saveAllInputdata();
		} else {
			setSaveConformModel(false);
		}
	};
	const saveAllInputdata = async () => {
		setLoaderSave(true);
		console.log('gs');
		let data = {
			company_id: comp_url,
			observation_json: [
				{
					work_area: workAreaInput,
					sub_theme: subThemeInput,
					observation: observationInput,
				},
			],
		};
		await api
			.addObservationDataById(data)
			.then((response) => {
				if (response.status == 200 || response.status == 201) {
					let messageText = response.data.message;
					setPopupMessage(true);
					setSaveConformModel(false);
					setMessage(messageText);
					localStorage.removeItem('currentWorkArea');
					localStorage.removeItem('currentSubtheme');
					localStorage.removeItem('currentObservation');

					let getBucketdata = localStorage.getItem('obsevationBucket');
					let BucketJson = JSON.parse(getBucketdata);
					let filterData = BucketJson.filter(
						(item) => item.company_id != comp_url
					);
					localStorage.setItem('obsevationBucket', JSON.stringify(filterData));
					setWorkAreaInput([]);
					setSubThemeInput([]);
					setObservationInput([]);
					setTimeout(() => {
						setLoaderSave(false);
						setTimeout(() => {
							setPopupMessage(false);
						}, 1000);
					}, 2000);
					if (NavigateStatus == 'navigate') {
						setTimeout(() => {
							setLoaderSave(false);
						}, 1000);
						setTimeout(() => {
							navigate('/checkpoints/IARPreparation/' + comp_url);
						}, 2000);
					}
				}
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					let errormsg = error.response.data.error.message;
					if (errorCode == 401) {
						logout();
					}
					if (errorCode == 400 || errorCode == 500) {
						setTimeout(() => {
							setErroMessage(errormsg);
							setLoaderSave(false);
						}, 1000);

						let errorLogData = {
							api_name: 'addobservation',
							error_code: errorCode,
							error_message: errormsg,
						};
						ErrorLog(errorLogData);
					}
				} else {
					setSaveConformModel(false);
					setPopupMessage(true);
					setMessage(
						error.message + '. Please check your internet connection.'
					);
				}
			});
	};
	// useEffect(() => {
	//     // DefaultViewWorkarea();
	//     // DefaultviewSubtheme();
	//     // DefaultviewObservation();
	//     localStorageWorkarea(workAreaInput)
	//     localStorageSubtheme(subThemeInput)
	//     localStorageObservation(observationInput)
	// }, [workAreaInput, subThemeInput, observationInput])

	return (
		<div className="mobileWrapper">
			<div className="container">
				<form className="row AddNewObservation addNewObsMob pt-3">
					<div className="col-12 mb-4">
						<Form.Group className="mb-3">
							<Form.Label htmlFor="Select">
								{t('WorkArea')} <span className="star">*</span>
							</Form.Label>
							<Form.Select
								name="work_area"
								onChange={(e) => handleSelectChange(e)}
								value={
									workAreaInput.length > 0
										? workAreaInput[0].work_area_name
										: ''
								}
								className="workAreaclass"
								// id={workAreaInput.length != 0 ? workAreaInput.work_area_id : -1}
							>
								<option>{t('SelectWorkArea')}</option>
								<option value="customWorkarea">{t('AddCustomWork')}</option>

								{workArea &&
									workArea.map((wkArea, index) => (
										<option
											key={index}
											id={wkArea.work_area_id}
											value={wkArea.work_area_name}
										>
											{wkArea.work_area_name}
										</option>
									))}
							</Form.Select>
						</Form.Group>

						{cutomWorkarea == true ? (
							<Form.Control
								type="text"
								name="work_area"
								placeholder={t('EnterCustomWork')}
								value={
									workAreaInput.length > 0
										? workAreaInput[0].work_area_name
										: ''
								}
								onChange={(e) => handleCustomInput(e)}
							/>
						) : null}
						<div className="form-text text-danger">{errorValue.workName}</div>
					</div>
					<div className="col-12 mb-4">
						<Form.Group className="mb-3">
							<Form.Label htmlFor="Select">
								{t('SubTheme')} <span className="star">*</span>
							</Form.Label>
							<Form.Select
								name="sub_theme"
								onChange={(e) => handleSelectChange(e)}
								value={
									subThemeInput.length > 0
										? subThemeInput[0].sub_theme_name
										: ''
								}
								className="subthemeClass"
								// disabled={disabledSelectSubtheme}
							>
								<option>{t('SelectSubTheme')}</option>
								<option value="customTheme">{t('AddCustomSubtheme')}</option>
								{!cutomWorkarea
									? filterSubtheme &&
									  filterSubtheme.map((subThemeitem, index) => (
											<option key={index} id={subThemeitem.sub_theme_id}>
												{subThemeitem.sub_theme_name}
											</option>
									  ))
									: SubTheme &&
									  SubTheme.map((subThemeitem, index) => (
											<option key={index} id={subThemeitem.sub_theme_id}>
												{subThemeitem.sub_theme_name}
											</option>
									  ))}
							</Form.Select>
						</Form.Group>
						{cutomTheme == true ? (
							<Form.Control
								type="text"
								name="sub_theme"
								placeholder={t('EnterCustomSubTheme')}
								value={
									subThemeInput.length > 0
										? subThemeInput[0].sub_theme_name
										: ''
								}
								onChange={(e) => handleCustomInput(e)}
							/>
						) : null}
						<div className="form-text text-danger">{errorValue.subName}</div>
					</div>
					<div className="col-12 mb-4">
						<Form.Group className="mb-3">
							<Form.Label htmlFor="Select">
								{t('Observation')} <span className="star">*</span>
							</Form.Label>
							<Form.Select
								name="observation"
								onChange={(e) => handleSelectChange(e)}
								className="observation"
								// disabled={disabledSelectObs}
								//id={observationInput.length > 0 ? observationInput[0].observation_id : -1}
								value={
									observationInput.length > 0
										? observationInput[0].observation_name
										: ''
								}
							>
								<option>{t('SelectObservation')}</option>
								<option value="customObservation">
									{t('AddCustomObservation')}
								</option>
								{FilterObservation &&
									FilterObservation.map((obsItem, index) => (
										<option key={index} id={obsItem.observation_master_id}>
											{obsItem.observation_master_name}
										</option>
									))}
							</Form.Select>
						</Form.Group>
						{cutomObservation == true ? (
							<Form.Group>
								<Form.Control
									className="hide customObservationbox"
									as="textarea"
									name="observation"
									rows={3}
									placeholder={t('EnterCustomObservation')}
									value={
										observationInput.length > 0
											? observationInput[0].observation_name
											: ''
									}
									onChange={(e) => handleCustomInput(e)}
								/>
							</Form.Group>
						) : null}
						<div className="form-text text-danger">{errorValue.obsName}</div>
					</div>

					<div className="col-12 d-grid mx-auto mb-2">
						<button
							type="submit"
							className="secondary_btn"
							onClick={(e) => saveConformation(e, 'no_navigate')}
						>
							{t('SaveandAddnew')}
						</button>
					</div>
					<div className="col-12 d-grid mb-2 mx-auto">
						<button
							type="submit"
							className="secondary_btn"
							onClick={(e) => saveConformation(e, 'navigate')}
						>
							{t('saveAndExit')}
						</button>
					</div>
				</form>
			</div>
			<Modal
				className="newObservation removeConform"
				show={saveConformModel}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						<h4 className="mb-4">{t('DoyouWantsaveConform')}</h4>
						<button
							className="secondary_btn small_btn"
							onClick={() => confromationFunction('no')}
						>
							{t('No')}
						</button>
						<button
							className="secondary_btn small_btn"
							onClick={() => confromationFunction('yes')}
						>
							{t('Yes')}
						</button>
						{LoaderSave == true ? (
							<img src={Loader} alt="loader" width="130" />
						) : null}
					</div>
					<div className="form-text text-danger text-center">
						{ErroMessage && ErroMessage}
					</div>
				</Modal.Body>
			</Modal>
			<Modal
				className="newObservation removeConform"
				show={popUpMessage}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header></Modal.Header>
				<Modal.Body>
					<div className="P-3 pt-0 text-center">
						{LoaderSave == true ? (
							<div>
								<img src={Loader} alt="loader" width="130" />
							</div>
						) : (
							<h4 className="mb-4">{message && message}</h4>
						)}
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default AddNewObsMob;
