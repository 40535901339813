import Style from './FrontPagePreview.module.css';
import logo from '../../../assets/pdf_images/logo.png';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import IAR_firstpage_logos from '../../../assets/pdf_images/IAR_firstpage_logos.png';
// import plasticsLogo from '../../../assets/images/plasticsLogo.png';
import plasticsLogo from '../../../assets/pdf_images/LogoWithDescription.png';
import switchAsiaLogo from '../../../assets/pdf_images/SwitchAsiaNew.png';

const FrontPagePreview = ({ company }) => {
	return (
		<div
			className={Style.demo}
			style={{ textAlign: 'left', padding: '100px 100px' }}
		>
			<h3 className={Style.cmpnyHeader}>INITIAL ASSESSMENT REPORT</h3>

			<div>
				{company &&
					company.map((i, index) => {
						return (
							<span key={index}>
								<p style={{ marginBottom: '0px' }}>
									<span className={Style.cmpnyName}>
										{' '}
										{i.company_name}, &nbsp;
									</span>{' '}
									<span className={Style.cmpnyregn}>
										{company && i.region_name},&nbsp;
									</span>
									<span className={Style.cmpnycntry}>{i.country_name}</span>
								</p>
								<p
									className={Style.date}
									style={{ marginTop: '170px', marginBottom: '50px' }}
								>
									<span>{i.iav_date}</span>
								</p>
							</span>
						);
					})}
			</div>
			<div
				style={{
					display: 'flex',
					gap: '70px',
					alignItems: 'end',
					justifyContent: 'start',
				}}
			>
				<img src={plasticsLogo} width="200px" />
				<img src={switchAsiaLogo} width="280px" />
			</div>
		</div>
	);
};
export default FrontPagePreview;
