// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationmob_upload__q3g9x {
    padding: 35px 20px;
}

.notificationmob_header__FqUc- {
    color: #5A5A5A;
    font-size: 20px;
    font-family: 'Robotomedium';
    font-weight: 600;
    margin-bottom: 3px;
}

.notificationmob_header_hr__xFVXJ {
    height: 3px;
    border-top: 2px solid #5A5A5A;
    margin: 0px;
}

.notificationmob_notification_hr__KiRDN {
    height: 3px;
    border-top: 2px solid #5A5A5A;
    opacity: 0.1;
    margin: 0px;
}

.notificationmob_notificationMaintxt__7-nQM p {
    padding: 0px;
    margin: 0px;
}

.notificationmob_notificationMaintxt__7-nQM {
    color: #5A5A5A;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 600;
    padding: 5px 0px;
}
.notificationmob_notificationSubtxt__3Oj0P p{
    margin-bottom: 8px;
}
.notificationmob_notificationSubtxt__3Oj0P {
    font-size: 13px;
    color: #6D6D6D;
    font-family: 'Roboto';
    padding: 0px 20px 0px 0px;
    width: 76%;
}

.notificationmob_notificationSubtxtDate__FC-fn span {
    display: block;
    font-family: 'Roboto';
    font-size: 12px;
    color: #8B8B8B;
}


.notificationmob_notificationWrapper__gbvZ- ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

}`, "",{"version":3,"sources":["webpack://./src/components/notificationMobieview/notificationmob.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,2BAA2B;IAC3B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,WAAW;AACf;;AAEA;IACI,WAAW;IACX,6BAA6B;IAC7B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,cAAc;IACd,eAAe;IACf,qBAAqB;IACrB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,cAAc;IACd,qBAAqB;IACrB,yBAAyB;IACzB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,cAAc;AAClB;;;AAGA;IACI,qBAAqB;IACrB,UAAU;IACV,SAAS;;AAEb","sourcesContent":[".upload {\r\n    padding: 35px 20px;\r\n}\r\n\r\n.header {\r\n    color: #5A5A5A;\r\n    font-size: 20px;\r\n    font-family: 'Robotomedium';\r\n    font-weight: 600;\r\n    margin-bottom: 3px;\r\n}\r\n\r\n.header_hr {\r\n    height: 3px;\r\n    border-top: 2px solid #5A5A5A;\r\n    margin: 0px;\r\n}\r\n\r\n.notification_hr {\r\n    height: 3px;\r\n    border-top: 2px solid #5A5A5A;\r\n    opacity: 0.1;\r\n    margin: 0px;\r\n}\r\n\r\n.notificationMaintxt p {\r\n    padding: 0px;\r\n    margin: 0px;\r\n}\r\n\r\n.notificationMaintxt {\r\n    color: #5A5A5A;\r\n    font-size: 16px;\r\n    font-family: 'Roboto';\r\n    font-weight: 600;\r\n    padding: 5px 0px;\r\n}\r\n.notificationSubtxt p{\r\n    margin-bottom: 8px;\r\n}\r\n.notificationSubtxt {\r\n    font-size: 13px;\r\n    color: #6D6D6D;\r\n    font-family: 'Roboto';\r\n    padding: 0px 20px 0px 0px;\r\n    width: 76%;\r\n}\r\n\r\n.notificationSubtxtDate span {\r\n    display: block;\r\n    font-family: 'Roboto';\r\n    font-size: 12px;\r\n    color: #8B8B8B;\r\n}\r\n\r\n\r\n.notificationWrapper ul {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upload": `notificationmob_upload__q3g9x`,
	"header": `notificationmob_header__FqUc-`,
	"header_hr": `notificationmob_header_hr__xFVXJ`,
	"notification_hr": `notificationmob_notification_hr__KiRDN`,
	"notificationMaintxt": `notificationmob_notificationMaintxt__7-nQM`,
	"notificationSubtxt": `notificationmob_notificationSubtxt__3Oj0P`,
	"notificationSubtxtDate": `notificationmob_notificationSubtxtDate__FC-fn`,
	"notificationWrapper": `notificationmob_notificationWrapper__gbvZ-`
};
export default ___CSS_LOADER_EXPORT___;
