import React, { useEffect, useState, useRef, useContext } from 'react';

import AdminDataService from '../../../../services/AdminDataServices';
import { Button, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import TCStyles from '../tc/tc.module.css';
import Search from '../../../../assets/images/admin/search.png';
import ActivityRow from './ActivityRow';
import PaginationComponent from './Pagination';
import { GlobalContext } from '../../../../context/GlobalState';
import { Alert } from 'react-bootstrap';
import { RiCalendar2Fill } from 'react-icons/ri';
import { formatDateWithDateFirst } from '../../../utils/formatDate';

const ActivityLog = () => {
	const { setMessage } = useContext(GlobalContext);
	const columnsArray = ['TC Name', 'Action', 'Date'];
	const adminApi = new AdminDataService();
	const [actions, setActions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const formatDate = (date) => {
		return date
			.toLocaleDateString('en-GB', {
				day: '2-digit',
				month: '2-digit',
				year: 'numeric',
			})
			.split('/')
			.reverse()
			.join('-');
	};
	//getting todays and yesterday's date to send to the api for initial filter
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(today.getDate() - 1);

	const [startDate, setStartDate] = useState(formatDate(today)); //changed from date to today as Abhishek mentioned that today's activity logs would be enough,if the admin wants he can filter with more dates
	const [endDate, setEndDate] = useState(formatDate(today));

	const actionsRef = useRef({});

	function getCurrentTime() {
		const now = new Date();
		const hours = String(now.getHours()).padStart(2, '0');
		const minutes = String(now.getMinutes()).padStart(2, '0');
		const seconds = String(now.getSeconds()).padStart(2, '0');
		return `${hours}:${minutes}:${seconds}`;
	}

	useEffect(() => {
		document.title = 'RECO Wizard :: Activity Log';

		let startDataWithTime = startDate + ' ' + '00:00:00';
		let endDataWithTime = endDate + ' ' + getCurrentTime();

		async function getActivity() {
			setIsLoading(true);
			try {
				const result = await adminApi.getUserActivity({
					startdate: startDataWithTime,
					enddate: endDataWithTime,
				});
				if (!!result?.data?.userLog) {
					let sortedActions = result?.data?.userLog?.sort(
						(a, b) =>
							new Date(b.log_created_date) - new Date(a.log_created_date)
					);
					console.log(sortedActions);
					setActions(sortedActions);
					actionsRef.current = {
						...actionsRef,
						0: sortedActions,
					};
				} else {
					setMessage('Something went wrong. Please try again later');
				}

				setIsLoading(false);
			} catch (error) {
				console.log(error);
				setIsLoading(false);
			}
		}
		getActivity();
	}, []);

	//calendar
	const [showCalendar, setShowCalendar] = useState(false);

	const handleClose = () => setShowCalendar(false);
	const handleShow = () => {
		setShowCalendar(true);
		setError('');
	};

	//second calendar
	const [showEndCalendar, setShowEndCalendar] = useState(false);
	const closeSecondCalendar = () => setShowEndCalendar(false);
	const showSecondCalendar = () => {
		setError('');
		setShowEndCalendar(true);
	};
	const [error, setError] = useState('');
	//calendar finish

	let key = '';

	const handleKeyDown = (e) => {
		key = e.key;
	};

	const filterTable = ({ target: { value } }) => {
		const upperCaseValue = value.toUpperCase();
		if (value.length > 0) {
			if (key === 'Backspace') {
				setActions(actionsRef.current[value.length]);
			} else {
				const filteredResources = actions.filter(
					(resource) =>
						resource?.user_first_name?.toUpperCase().includes(upperCaseValue) ||
						resource?.log_desc?.toUpperCase().includes(upperCaseValue) ||
						String(resource?.log_created_date)
							?.toUpperCase()
							.includes(upperCaseValue)
				);
				setActions(filteredResources);
				actionsRef.current = {
					...actionsRef.current,
					[value.length]: filteredResources,
				};
			}
		} else {
			setActions(actionsRef.current[0]);
		}
	};

	const handleFirstCalendar = (date) => {
		setStartDate(formatDate(date));
	};
	const handleSecondCalendar = (date) => {
		if (formatDate(date) < startDate) {
			setError('To date cannot be earlier than the From Date!');
		} else {
			setEndDate(formatDate(date));
		}
	};

	const getLogByDate = async () => {
		if (!error) {
			try {
				setIsLoading(true);
				let startDataWithTime = startDate + ' ' + '00:00:00';
				let endDataWithTime = endDate + ' ' + '23:59:59';

				const result = await adminApi.getUserActivity({
					startdate: startDataWithTime,
					enddate: endDataWithTime,
				});
				setActions(result?.data?.userLog);
				setIsLoading(false);
				actionsRef.current = { ...actionsRef, 0: result?.data?.userLog };
				setMessage(
					'Activity Log Data successfully fetched according to your Date filter.'
				);
			} catch (error) {
				setIsLoading(false);
				console.log(error);
			}
		} else {
			setError('To date cannot be eariler than the From date !');
		}
	};

	//pagination set up
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// Logic to get the current items to display based on the current page
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = actions?.slice(indexOfFirstItem, indexOfLastItem);

	// Function to handle page changes
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	// if (isLoading) {
	// 	return (
	// 		<div style={{ height: '60vh' }}>
	// 			<div className="loader" style={{ marginTop: '200px' }}></div>
	// 		</div>
	// 	);
	// }

	if (currentItems == null || currentItems == undefined) {
		return (
			<div style={{ marginTop: '50px' }}>
				There is no data to show at the moment.
			</div>
		);
	}

	return (
		<div className="deskWrapper">
			<div className="container">
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<div>
						<div
							className={'input-group' + ' ' + TCStyles.searchBox}
							style={{
								display: 'flex',
								flexDirection: 'row',
								border: '4px solid #ced4da',
								borderRadius: '5px',
							}}
						>
							<span
								className={'input-group-text' + ' ' + TCStyles.searchIcon}
								style={{ border: 'none' }}
							>
								<img src={Search} alt="Search" />
							</span>
							<input
								type="text"
								className={TCStyles.search}
								placeholder="Search by Name, Action, Date"
								onKeyDown={(e) => handleKeyDown(e)}
								onChange={(e) => filterTable(e)}
								style={{ border: 'none' }}
							/>
						</div>
					</div>
					<div>
						<label
							htmlFor="file-input"
							style={{ cursor: 'pointer', fontSize: '16px' }}
						>
							From :&nbsp;
						</label>
						<Button
							style={{
								backgroundColor: '#E4FFFE',
								color: 'gray',
								border: '1px solid #294D8F',
							}}
							onClick={handleShow}
						>
							{formatDateWithDateFirst(startDate)} <RiCalendar2Fill />
						</Button>
						<label
							htmlFor="file-input"
							style={{
								cursor: 'pointer',
								fontSize: '16px',
								margin: '0px 3px 0px 10px',
							}}
						>
							To :
						</label>
						<Button
							style={{
								backgroundColor: '#E4FFFE',
								margin: '0px 15px 0px 0px',
								color: 'gray',
								border: '1px solid #294D8F',
							}}
							onClick={showSecondCalendar}
						>
							{formatDateWithDateFirst(endDate)} <RiCalendar2Fill />
						</Button>
						<Button
							style={{ backgroundColor: '#294D8F', width: '100px' }}
							onClick={getLogByDate}
						>
							Get Log
						</Button>
					</div>
				</div>

				{error && (
					<Alert
						variant="danger"
						style={{
							fontSize: '20px',
							fontWeight: 'bold',
							textAlign: 'center',
						}}
					>
						{error}
					</Alert>
				)}

				{/* table */}
				{isLoading ? (
					<div style={{ height: '60vh' }}>
						<div className="loader" style={{ marginTop: '200px' }}></div>
					</div>
				) : currentItems?.length > 0 ? (
					<div className="row" style={{ marginTop: '30px' }}>
						<div
							className={'col-md-12 mt-3' + ' ' + TCStyles.tableWrapper}
							style={{
								border: '4px solid #b8b8b8',
								borderBottom: '1px solid #b8b8b8',
							}}
						>
							<table
								className={'table table-striped' + ' ' + TCStyles.iarTable}
							>
								<thead>
									<tr className={TCStyles.tr} style={{ height: '60px' }}>
										{columnsArray.map((column, index) => (
											<th
												className={TCStyles.th}
												key={index}
												colSpan={columnsArray.length}
												style={{ fontSize: '18px' }}
											>
												{column}
											</th>
										))}
									</tr>
								</thead>
								<tbody>
									{currentItems?.map((activity, index) => (
										<ActivityRow
											activity={activity}
											index={index}
											key={index}
											span={columnsArray.length}
										/>
									))}
								</tbody>
							</table>
						</div>
						<PaginationComponent
							itemsPerPage={itemsPerPage}
							totalItems={actions?.length}
							paginate={paginate}
							activePage={currentPage}
						/>
					</div>
				) : (
					<div style={{ marginTop: '70px', textAlign: 'center' }}>
						<h4>There is no data to show at the moment.</h4>
					</div>
				)}

				<Modal show={showCalendar} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Start Date</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Calendar
							style={{ color: '#294D8F', backgroundColor: '#FFDE9B' }}
							onChange={handleFirstCalendar}
							maxDate={new Date()}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Submit
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal show={showEndCalendar} onHide={closeSecondCalendar}>
					<Modal.Header closeButton>
						<Modal.Title>End Date</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Calendar
							style={{ color: '#294D8F', backgroundColor: '#FFDE9B' }}
							onChange={handleSecondCalendar}
							maxDate={new Date()}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={closeSecondCalendar}>
							Submit
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};

export default ActivityLog;
