import React, { useState, useContext, useRef } from 'react';
import TCStyles from './tc.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';
import { validateForm } from './validateForm';
import { GlobalContext } from '../../../../context/GlobalState';
import AdminDataService from '../../../../services/AdminDataServices';
import { handleApiError } from '../../../utils/apiErrorHandler';
import './addTc.css';

const AddTc = ({ show, setShow }) => {
	const adminApi = new AdminDataService();

	const [error, setError] = useState(false);
	//importing context variable to set Success Message
	const {
		setMessage,
		countryArray,
		regionArray,
		tcList,
		setTcList,
		setGlobalNetworkError,
	} = useContext(GlobalContext);

	//getting Tc Data
	const [tcData, setTcData] = useState({
		firstname: '',
		lastname: '',
		region: '',
		country: '',
		email: '',
		phone: '',
	});

	const countryRef = useRef(null);
	const regionRef = useRef(null);

	const [countryCode, setCountryCode] = useState('+91');

	const handleTCData = async ({ target: { name, value } }) => {
		const validationErrors = validateForm(tcData);
		if (Object.keys(validationErrors).length > 0) {
			setError(validationErrors);
		} else {
			try {
				const result = await adminApi.addNewTc({
					user_first_name: tcData.firstname,
					user_last_name: tcData.lastname,
					user_contact: tcData.phone,
					user_email: tcData.email,
					user_region: regionRef.current.value,
					user_country: countryRef.current.value,
				});
				setMessage(result.data.message);

				const newTcList = [
					{
						user_first_name: tcData.firstname,
						user_last_name: tcData.lastname,
						user_contact: tcData.phone,
						user_email: tcData.email,
						region_name:
							regionRef.current.selectedOptions[0].getAttribute('name'),
						country_name:
							countryRef.current.selectedOptions[0].getAttribute('name'),
						total_Company: 0,
					},
					...tcList,
				];
				localStorage.setItem('tclist', JSON.stringify(newTcList));
				setTcList(newTcList);
				setTcData({
					firstname: '',
					lastname: '',
					region: '',
					country: '',
					email: '',
					phone: '',
				});
				setShow(false);
			} catch (error) {
				handleApiError(error, setGlobalNetworkError, setError);
			}
		}
	};

	const handleMobileNumber = (e) => {
		const trimmedValue = e.target.value.trim();
		if (trimmedValue.length <= 9) {
			setTcData({ ...tcData, phone: e.target.value });
			validateMobileNumber(e.target.value);
		}
	};

	const validateMobileNumber = (number) => {
		// Regex patterns for Uzbekistan and Tajikistan mobile numbers
		let mobileNumber = countryCode + number;

		const indiaPattern = /^\+?91\d{10}$/;
		const srilankaPattern = /^\+?94\d{9}$/;
		if (mobileNumber === '') {
			setError({ ...error, phone: 'Please enter mobile number' }); // Empty input is considered valid
		} else if (
			mobileNumber.match(indiaPattern) ||
			mobileNumber.match(srilankaPattern)
		) {
			setError({ ...error, phone: '' });
		} else {
			setError({ ...error, phone: 'Phone number is invalid' });
		}
	};

	function handleSelect(event) {
		const selectedOption = event.target.options[event.target.selectedIndex];
		const newCountryCode = selectedOption.getAttribute('data-countrycode');
		setCountryCode(newCountryCode);
	}

	return (
		<>
			<div className="row mt-2">
				<div className="col-md-4 mb-4">
					<label className="form-label">
						First Name<span className="star">*</span>
					</label>
					<input
						type="text"
						name="email"
						className="form-control"
						placeholder="Enter First Name"
						onKeyDown={() => setError({ ...error, firstname: '' })}
						value={tcData.name}
						onChange={(e) =>
							setTcData({ ...tcData, firstname: e.target.value })
						}
					/>
					{error.firstname && (
						<Alert variant="danger">{error?.firstname}</Alert>
					)}
				</div>
				{/* <div className="col-md-4 mb-4">
					<label className="form-label">
						Last Name<span className="star">*</span>
					</label>
					<input
						type="text"
						name="lastname"
						className="form-control"
						placeholder="Enter Last Name"
						value={tcData.name}
						onKeyDown={() => setError({ ...error, lastname: '' })}
						onChange={(e) => setTcData({ ...tcData, lastname: e.target.value })}
					/>
					{error.lastname && <Alert variant="danger">{error?.lastname}</Alert>}
				</div> */}
				<div className="col-md-4 mb-4">
					<label className="form-label">
						Country<span className="star">*</span>
					</label>
					<Form.Group className="mb-3">
						<Form.Select
							id="Select"
							ref={countryRef}
							name="country"
							style={{ textTransform: 'capitalize' }}
							onChange={handleSelect}
						>
							{countryArray?.map((country, index) => (
								<option
									style={{ textTransform: 'capitalize' }}
									value={country?.country_id}
									key={country?.country_id}
									data-countrycode={country?.country_code}
									name={country?.country_name}
								>
									{country?.country_name}
								</option>
							))}
						</Form.Select>
						{error.country && <Alert variant="danger">{error?.country}</Alert>}
					</Form.Group>
				</div>
				<div className="col-md-4 mb-4">
					<label className="form-label">
						Region<span className="star">*</span>
					</label>
					<Form.Group className="mb-3">
						<Form.Select ref={regionRef} id="Select" name="region">
							{regionArray
								?.filter((region) =>
									countryRef?.current?.value === undefined
										? region.country_id == 1
										: region.country_id == countryRef?.current?.value
								)
								?.map((region) => (
									<option
										value={region?.region_id}
										key={region?.region_id}
										name={region?.region_name}
									>
										{region?.region_name}
									</option>
								))}
						</Form.Select>
						{error.region && <Alert variant="danger">{error?.region}</Alert>}
					</Form.Group>
				</div>
				<div className="col-md-4 mb-4">
					<label className="form-label">
						Email<span className="star">*</span>
					</label>
					<input
						type="email"
						name="email"
						className="form-control"
						placeholder="Enter email "
						value={tcData.email}
						onKeyDown={() => setError({ ...error, email: '' })}
						onChange={(e) => setTcData({ ...tcData, email: e.target.value })}
					/>
					{error.email && <Alert variant="danger">{error?.email}</Alert>}
				</div>
				<div className="col-md-4 mb-4">
					<label className="form-label">
						Phone Number<span className="star">*</span>
					</label>
					{/* <input
						type="text"
						name="email"
						className="form-control"
						placeholder="Enter phone number "
						value={tcData.phone}
						onKeyDown={() => setError({ ...error, phone: '' })}
						onChange={(e) => setTcData({ ...tcData, phone: e.target.value })}
					/> */}
					<div className="phone-input">
						<span className="phone-code">{countryCode}</span>
						<input
							type="tel"
							className="form-control"
							placeholder="Enter phone number "
							value={tcData.phone}
							onKeyDown={() => setError({ ...error, phone: '' })}
							onChange={handleMobileNumber}
						/>
					</div>
					{error.phone && <Alert variant="danger">{error?.phone}</Alert>}
				</div>
			</div>
			<div className="row">
				<div className="col-md-12">
					<Form.Group
						className="mb-3 autogenerate"
						controlId="formBasicCheckbox"
					>
						<p style={{ color: 'gray', fontSize: '16px' }}>
							<b
								style={{
									fontWeight: '900',
									marginRight: '5px',
								}}
							>
								i.
							</b>
							The password will be autogenerated and the credentials will be
							sent over the email to the TC
						</p>
					</Form.Group>
				</div>
			</div>
			<div className="row mt-3">
				<div className="col-md-12 text-center">
					<Button
						className={'primary_btn' + ' ' + TCStyles.addnewcomp}
						onClick={handleTCData}
					>
						Save
					</Button>
				</div>
			</div>
		</>
	);
};

export default AddTc;
