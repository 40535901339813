// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-btn {
	position: relative;
	display: inline-block;
	width: 130px;
	height: 35px;

	border-radius: 10px;
	background-color: #fffcf4;
	cursor: pointer;
	overflow: hidden;
	border: 2px solid #ffde9b;
	padding-bottom: 10px;
}

.toggle-btn.toggled {
	background-color: #294d8f;
}

.toggle-ball {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 20px;
	height: 27px;
	border-radius: 30%;
	background-color: #327072;
	transition: all 0.3s ease-in-out;
}

.toggle-btn.toggled .toggle-ball {
	left: calc(100% - 24px);
	background-color: white;
}

.Master_btn {
	position: relative;
	display: inline-block;
	width: 130px;
	height: 35px;

	border-radius: 10px;
	background-color: #294d8f;
	cursor: pointer;
	overflow: hidden;
	border: 2px solid #ffde9b;
	padding-bottom: 10px;
	vertical-align: center;
	text-align: center;
	padding: 4px 0px 0px 0px;
	font-size: 16px;
	color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/components/database/ToggleButton/toggle.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,qBAAqB;CACrB,YAAY;CACZ,YAAY;;CAEZ,mBAAmB;CACnB,yBAAyB;CACzB,eAAe;CACf,gBAAgB;CAChB,yBAAyB;CACzB,oBAAoB;AACrB;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,QAAQ;CACR,SAAS;CACT,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,yBAAyB;CACzB,gCAAgC;AACjC;;AAEA;CACC,uBAAuB;CACvB,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,qBAAqB;CACrB,YAAY;CACZ,YAAY;;CAEZ,mBAAmB;CACnB,yBAAyB;CACzB,eAAe;CACf,gBAAgB;CAChB,yBAAyB;CACzB,oBAAoB;CACpB,sBAAsB;CACtB,kBAAkB;CAClB,wBAAwB;CACxB,eAAe;CACf,YAAY;AACb","sourcesContent":[".toggle-btn {\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\twidth: 130px;\r\n\theight: 35px;\r\n\r\n\tborder-radius: 10px;\r\n\tbackground-color: #fffcf4;\r\n\tcursor: pointer;\r\n\toverflow: hidden;\r\n\tborder: 2px solid #ffde9b;\r\n\tpadding-bottom: 10px;\r\n}\r\n\r\n.toggle-btn.toggled {\r\n\tbackground-color: #294d8f;\r\n}\r\n\r\n.toggle-ball {\r\n\tposition: absolute;\r\n\ttop: 2px;\r\n\tleft: 2px;\r\n\twidth: 20px;\r\n\theight: 27px;\r\n\tborder-radius: 30%;\r\n\tbackground-color: #327072;\r\n\ttransition: all 0.3s ease-in-out;\r\n}\r\n\r\n.toggle-btn.toggled .toggle-ball {\r\n\tleft: calc(100% - 24px);\r\n\tbackground-color: white;\r\n}\r\n\r\n.Master_btn {\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\twidth: 130px;\r\n\theight: 35px;\r\n\r\n\tborder-radius: 10px;\r\n\tbackground-color: #294d8f;\r\n\tcursor: pointer;\r\n\toverflow: hidden;\r\n\tborder: 2px solid #ffde9b;\r\n\tpadding-bottom: 10px;\r\n\tvertical-align: center;\r\n\ttext-align: center;\r\n\tpadding: 4px 0px 0px 0px;\r\n\tfont-size: 16px;\r\n\tcolor: white;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
