import React, { useState, useContext } from 'react';
import IARStyles from '../iar/iar.module.css';
import AdminDataService from '../../../../services/AdminDataServices';
import { MemoizedEditCompany } from './../EditCompany/EditCompany';
import { GlobalContext } from '../../../../context/GlobalState';
import produce from 'immer';
import isEqual from 'lodash/isEqual';
import Form from 'react-bootstrap/Form';
import { Alert } from 'react-bootstrap';

const TableRow = ({ item, index, steList }) => {
	const adminApi = new AdminDataService();
	const [viewDetailsIndex, setViewDetailsIndex] = useState(null);

	const [tcEditable, setTcEditable] = useState(false);
	const [defaultSte, setDefaultSte] = useState(item?.ste_name);

	const [error, setError] = useState('');

	//set Message from context
	const {
		setMessage,
		companyEditIndex,
		setCompanyEditMode,
		activeCompanies,
		iarApprovedComapnies,
		setIarApprovedCompanies,
		setActiveCompanies,
		currentTab,
		tcList,
		setTcId,
		setTcName,
		setSteId,
		setSteName,
	} = useContext(GlobalContext);

	let companyType =
		currentTab == 'tab1'
			? 'active companies'
			: currentTab == 'tab2'
			? 'approved companies'
			: 'deactivated companies';

	const viewDetailsBTN = (index) => {
		setTcName(item?.user_first_name);
		if (index == viewDetailsIndex) {
			setViewDetailsIndex(null);
			setCompanyEditMode(null);
		} else {
			setViewDetailsIndex(index);
		}
	};

	const formatDate = (date) => {
		if (date != null && typeof date == 'string') {
			let splitArray = date.split(' ')[0].split('-');
			const dateFormat =
				splitArray[2] + '-' + splitArray[1] + '-' + splitArray[0];
			return dateFormat;
		} else {
			return date;
		}
	};

	const assignSte = async ({ target: { value } }, company_id, type) => {
		let username = '';
		let result = '';
		let userId = '';
		const name = value.split('.');

		try {
			if (type == 'ste') {
				userId = 'ste_id';
				username = 'ste_name';
				result = await adminApi.assignSte({
					company_id: company_id,
					ste_id: name[0],
				});
			} else {
				userId = 'user_id';
				username = 'user_first_name';
				result = await adminApi.assignTC({
					company_id: company_id,
					user_id: name[0],
				});
			}

			const assignedCompany = activeCompanies?.find(
				(company) => company.company_id === company_id
			);

			const updatedElement = {
				...assignedCompany,
				[username]: name[1],
				[userId]: name[0],
			}; // the updated version of the element

			const newArray = produce(activeCompanies, (draftArray) => {
				draftArray.splice(
					activeCompanies?.indexOf(assignedCompany),
					1,
					updatedElement
				);
			});
			setActiveCompanies(newArray);

			setMessage(result.data.message);
		} catch (error) {
			console.log(error);
		}
	};

	const approveCompany = async (id) => {
		try {
			const result = await adminApi.approveCompanyApi(id);

			//company_checkpoints_status
			const approvedCompany = activeCompanies?.find(
				(company) => company.company_id === id
			);
			const updatedElement = {
				...approvedCompany,
				company_checkpoints_status: 2,
			}; // the updated version of the element
			let newIarArray = [];
			if (iarApprovedComapnies == null) {
				newIarArray = [...newIarArray, updatedElement];
			} else {
				newIarArray = [...iarApprovedComapnies, updatedElement];
			}
			console.log(newIarArray);
			setIarApprovedCompanies(newIarArray);

			const newArray = produce(activeCompanies, (draftArray) => {
				draftArray.splice(activeCompanies?.indexOf(approvedCompany), 1);
			});
			setActiveCompanies(newArray);
			setMessage(result.data.message);
		} catch (error) {
			console.log(error);

			if (
				error.code == 'ERR_BAD_REQUEST' &&
				error?.response?.data?.error?.message
			) {
				setError(error?.response?.data?.error?.message);
			} else {
				setError('Bad Request ');
			}
			setTimeout(() => {
				setError('');
			}, 3000);
		}
	};

	return (
		<React.Fragment key={index}>
			<tr
				key={index}
				className={index % 2 == 0 ? IARStyles.treven : IARStyles.trodd}
			>
				<td className={IARStyles.td}>{formatDate(item?.company_iav_date)}</td>
				<td className={IARStyles.td}>{item?.company_code}</td>
				<td className={IARStyles.td}>{item?.company_name}</td>
				{/* <td className={IARStyles.td}>{item?.user_first_name}</td> */}

				{(item?.user_first_name && index != companyEditIndex) ||
				item?.company_checkpoints_status != 0 ? (
					<td
						className={IARStyles.td}
						onClick={() =>
							setMessage(
								'You cannot change TC now since IAR preparation is already started!'
							)
						}
					>
						{item?.user_first_name}
					</td>
				) : (
					<td className={IARStyles.td}>
						<Form.Group className=" assignedSTE">
							<Form.Select
								size="md"
								className="mb-2 pr-4"
								onChange={(e) => {
									assignSte(e, item?.company_id, 'tc');
								}}
								defaultValue={item?.user_id + '.' + item?.user_first_name}
							>
								<option key="9384948">Select TC</option>
								{tcList?.map((tcItem, index) => {
									return (
										<option
											key={index}
											name={tcItem?.user_first_name}
											value={tcItem?.user_id + '.' + tcItem?.user_first_name}
										>
											{tcItem?.user_first_name}
										</option>
									);
								})}
							</Form.Select>
						</Form.Group>
						{/* <Link className="primary_btn small_btn">approve</Link> */}
					</td>
				)}
				{companyType == 'active companies' ? (
					<>
						{item?.ste_name && index != companyEditIndex ? (
							<td className={IARStyles.td}>
								{item?.ste_name}
								<br></br>
								{item?.company_checkpoints_status == 1 &&
								item?.company_checkpoints_status != 0 ? (
									<>
										<div
											style={{
												backgroundColor: '#294D8F',
												borderRadius: '5px',
												padding: '3px 8px',
												color: 'white',
												boxShadow: 'none',
												cursor: 'pointer',
											}}
											onClick={() => approveCompany(item?.company_id)}
										>
											Approve
										</div>
										{error && (
											<Alert
												variant="danger"
												style={{
													fontSize: '16px',
													fontWeight: '400',
													textAlign: 'center',
												}}
											>
												{error}
											</Alert>
										)}
									</>
								) : (
									<></>
								)}
							</td>
						) : (
							<td className={IARStyles.td}>
								<Form.Group className=" assignedSTE">
									<Form.Select
										size="md"
										className="mb-2 pr-3"
										onChange={(e) => {
											assignSte(e, item?.company_id, 'ste');
										}}
										defaultValue={
											item.ste_d !== 'null'
												? item?.ste_id + '.' + item?.ste_name
												: 'Select RC'
										}
									>
										<option key="2498230">Select RC</option>
										{steList?.map((ste) => {
											return (
												<option
													key={ste?.ste_id}
													name={ste?.ste_name}
													value={ste?.ste_id + '.' + ste?.ste_name}
												>
													{ste?.ste_name}
												</option>
											);
										})}
									</Form.Select>
								</Form.Group>
								{/* <Link className="primary_btn small_btn">approve</Link> */}
							</td>
						)}

						<td className={IARStyles.td}>
							{item?.company_checkpoints_status == 1
								? 'IA Visit Done'
								: item?.company_checkpoints_status == 2
								? 'IAR Approved'
								: 'IA Visit to be Done'}
							<br />
							{item?.company_checkpoints_status == 1 && (
								<span style={{ color: '#294D8F' }}>{item?.iar_version}</span>
							)}
						</td>

						<td className={IARStyles.td}>
							{formatDate(item?.company_created_date)}
						</td>
					</>
				) : companyType == 'deactivated companies' ? (
					<>
						<td className={IARStyles.td}>{item?.ste_name}</td>
						<td className={IARStyles.td}>
							{formatDate(item?.deactivation_date)}
						</td>
						<td className={IARStyles.td}>{item?.deactivation_reason}</td>
					</>
				) : (
					<>
						<td className={IARStyles.td}>{item?.ste_name}</td>
						<td className={IARStyles.td}>{item?.company_iar_version}</td>
						<td className={IARStyles.td}>
							{item?.approval_date && formatDate(item?.approval_date)}
						</td>
					</>
				)}

				<td className={IARStyles.td}>
					{/* <Link className={IARStyles.viewDetails}>View Details</Link> */}
					<button
						onClick={() => viewDetailsBTN(index)}
						className={IARStyles.viewDetails}
						style={{ whiteSpace: 'nowrap' }}
					>
						{viewDetailsIndex == index ? 'Hide' : 'View'} Details
					</button>
				</td>
			</tr>
			{/* {companyType == 'active companies' && ( */}
			<tr style={{ textAlign: 'center', border: 'none' }}>
				<td
					colSpan={8}
					style={{
						textAlign: 'center',
						margin: '0px',
						padding: '0px',
						border: 'none',
					}}
				>
					{viewDetailsIndex == index ? (
						<div className={IARStyles.subTable}>
							<MemoizedEditCompany
								id={item?.company_id}
								setViewDetailsIndex={setViewDetailsIndex}
								index={index}
							/>
						</div>
					) : null}
				</td>
			</tr>

			{/* )} */}
		</React.Fragment>
	);
};

export const MemoizedTableRow = React.memo(TableRow, (prevProps, nextProps) => {
	const previousPropsCopy = produce(prevProps, (draft) => {});
	// Compare the previousPropsCopy with the next props
	const isEqualObjects = isEqual(previousPropsCopy, nextProps);

	if (isEqualObjects) {
		return true;
	}
	return false;
});
