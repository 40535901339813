import Style from './NotePreview.module.css';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
// import logo from '../../../assets/pdf_images/logo.png';
import plasticLogo from '../../../assets/pdf_images/Picture1.jpg';
import project_implement_img8 from '../../../assets/pdf_images/Partner_Logos_Combined.png';
import LogowithoutDescription from '../../../assets/pdf_images/LogoWithoutDescription.png';
import switchAsiaNew from '../../../assets/pdf_images/SwitchAsiaNew.png';
import PartnerLogoNew from '../../../assets/pdf_images/PartnerLogosNew.png';

const NotePreview = ({ tcname, company, userSignature }) => {
	return (
		<div className={Style.SCP_Section}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					gap: '20px',
					alignItems: 'center',
					textAlign: 'end',
					paddingLeft: '13%',
					marginBottom: '20px',
					opacity: '0.7',
					margin: '20px 140px 20px 0px',
				}}
			>
				{' '}
				<span style={{ color: '#50AB92' }}>Project implemented by</span>
				<img src={PartnerLogoNew} width="350px" alt="headerImg" />
			</div>
			<div className={Style.custom_container_table}>
				<div className={Style.note_text_header}>
					<p className={Style.note_txt} style={{ color: '#50AB92' }}>
						Note:
					</p>
					<p className={Style.note_desc}>
						The savings total shown above does not include the savings potential
						from several other possible measures that have been not quantified
						yet. Actual savings potential is higher than the total shown above;
						likewise, the total investment required may vary.
					</p>
					<p className={Style.note_desc}>
						Some of the savings have been estimated based on presently available
						data.
					</p>
					<p className={Style.note_desc}>
						In general, RECP team has been conservative in estimating the
						monetary savings. Where the resource saving is shown as a range, the
						lower figure is used for calculating monetary savings. The company
						will certainly be able to achieve higher monetary savings than shown
						in above table, by implementing all the listed recommendations.
					</p>
				</div>
				<div className={Style.sign_box}>
					<div className={Style.submitd_box}>
						<p className={Style.submitdby_txt}>Submitted by:</p>
						{userSignature && (
							<img
								src={`data:image/jpg;base64,${userSignature}`}
								style={{ width: '120px', height: '50px' }}
								alt="Sign"
							/>
						)}
						<hr className={Style.sign_line} />
						{tcname &&
							tcname.map((i, index) => (
								<p className={Style.teconslt_txt} key={index}>
									Technical Consultant: {i.tc_name}
								</p>
							))}
					</div>
					<div className={Style.submitd_box}>
						<p className={Style.submitdby_txt}>Approved by:</p>
						{company &&
							company.map((i, index) => (
								<p className={Style.ste_name} key={index}>
									{i.approved_by}
								</p>
							))}
						<hr className={Style.sign_line} />
						<p className={Style.ste_txt}>Review Consultant</p>
						{company &&
							company.map((i, index) => (
								<p className={Style.ste_txt} key={index}>
									Approval Date:{i.approved_date}
								</p>
							))}
					</div>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'end',
					margin: '20px 90px',
					opacity: '0.6',
				}}
			>
				<img src={LogowithoutDescription} width="120px" />
				<img src={switchAsiaNew} width="200px" />
			</div>
		</div>
	);
};
export default NotePreview;
