import React from 'react';

const ExecutiveSummeryPDF = ({ executiveData, company }) => {
	return (
		<div
			className="dummy"
			style={{
				display: 'block',
				pageBreakBefore: 'auto',
				position: 'relative',
			}}
		>
			<div>
				<div
					style={{
						color: '#3B7E80',
						fontSize: '18px',
						fontFamily: 'Roboto',
						fontWeight: '500',
					}}
				>
					<span>1. Executive Summary</span>
				</div>
				{company &&
					company.map((i, index) => {
						return (
							<p
								style={{
									color: '#000000',
									fontSize: '14px',
									fontFamily: 'Roboto',
									marginTop: '15px',
								}}
								key={index}
							>
								The Resource Efficient Cleaner Production (RECP) approach was
								used during on-site consulting visits to{' '}
								<span>
									<b>{i.company_name}</b>
								</span>
								&nbsp; on &nbsp;<b>{i.company_iav_date}</b> RECP team members
								studied the process of production at the company to identify
								opportunities for reducing the resource use at the company. The
								focus was on identifying low cost or no cost options for
								improvements and for reducing resource use at the source
							</p>
						);
					})}

				<p
					style={{
						color: 'black',
						fontSize: '14px',
						fontFamily: 'Roboto',
					}}
				>
					A summary of the identified improvement opportunities is provided in
					the tables to help the company owners or top management to obtain a
					quick overview of savings potential identified at this stage. The
					improvement opportunities are described in brief in section 3 of this
					report. RECP team members may be contacted for any clarification or
					technical support. The company management is urged to study this
					report in detail and to select those options they wish to implement
				</p>
				<p
					style={{
						color: 'black',
						fontSize: '14px',
						fontFamily: 'Roboto',
					}}
				>
					The management is also requested to assign clear responsibilities to
					internal team members for early implementation of the selected
					options. Based on the selected areas or options, RECP team will
					prepare more detailed steps explaining the steps to be taken for the
					implementation of the selected options, where needed
				</p>

				<p
					style={{
						color: '#3B7E80',
						fontSize: '18px',
						fontFamily: 'Roboto',
						fontWeight: '500',
					}}
				>
					2. Summary of Resource Saving Potential
				</p>
				<p
					style={{
						color: 'black',
						fontSize: '14px',
						fontFamily: 'Roboto',
					}}
				>
					A summary of resource and cost saving potential along with estimation
					of investment is provided in Table 1.
				</p>
			</div>
			<p
				style={{
					color: '#ffe3e3',

					fontFamily: 'Roboto',
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '13px',
					marginBottom: '20px',
				}}
			>
				Table 1 Summary of resource saving potential
			</p>
			<div>
				<table>
					<thead>
						<tr style={{ textAlign: 'center', color: 'black' }}>
							<th
								style={{
									border: '0.1px solid black',
									fontSize: '14px',
									background: 'lightgray',
									color: 'black',
									padding: '10px',
									fontFamily: 'Roboto',
									fontWeight: '500',
								}}
								rowSpan="2"
							>
								S. NO
							</th>
							<th
								style={{
									border: '0.1px solid black',
									fontSize: '14px',
									background: 'lightgray',
									color: 'black',
									padding: '10px',
									fontFamily: 'Roboto',
									fontWeight: '500',
								}}
								rowSpan="2"
							>
								Resources
							</th>
							{/* <th
								style={{
									border: '0.1px solid black',
									fontSize: '14px',
									background: 'lightgray',
									color: 'black',
									padding: '10px',
									fontFamily: 'Roboto',
									fontWeight: '500',
								}}
								rowSpan="2"
							>
								Unit Cost
							</th> */}
							<th
								style={{
									border: '0.1px solid #294D8F',
									fontSize: '14px',
									background: 'lightgray',
									color: '#294D8F',
									padding: '10px',
									fontFamily: 'Roboto',
									fontWeight: '500',
								}}
								colSpan="2"
							>
								Potential &nbsp;Saving
							</th>
							<th
								style={{
									border: '0.1px solid black',
									fontSize: '14px',
									background: 'lightgray',
									color: 'black',
									padding: '10px',
									fontFamily: 'Roboto',
									fontWeight: '500',
								}}
								colSpan="1"
							>
								Estimated Investment
							</th>
						</tr>

						{company &&
							company.map((i, index) => {
								return (
									<tr
										style={{ textAlign: 'center', color: 'black' }}
										key={index}
									>
										<th
											style={{
												border: '0.1px solid black',
												fontFamily: 'Roboto',
												fontSize: '14px',
												background: 'lightgray',
												color: 'black',
												padding: '6px 10px ',
											}}
											width="10%"
										>
											Quantity
										</th>
										<th
											style={{
												border: '0.1px solid black',
												fontFamily: 'Roboto',
												fontSize: '14px',
												background: 'lightgray',
												color: 'black',
												padding: '6px 10px',
											}}
											width="10%"
										>
											Monetary ({i.currency})
										</th>
										{
											<th
												style={{
													border: '0.1px solid black',
													fontFamily: 'Roboto',
													fontSize: '14px',
													background: 'lightgray',
													color: 'black',
													padding: '6px 10px',
												}}
												width="30%"
											>
												{i.currency}
											</th>
										}
									</tr>
								);
							})}
					</thead>
					<tbody>
						{executiveData?.map((i, index) => {
							return (
								<tr
									style={{ textAlign: 'center', color: 'black' }}
									key={i.executive_summary_id}
								>
									<td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{index + 1}
									</td>
									<td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{i.executive_summary_resource_name}
									</td>
									{/* <td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{i.executive_summary_unit_cost}
									</td> */}
									<td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{i.executive_summary_quantity}
									</td>
									<td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{i.executive_summary_monetary}
									</td>
									<td
										style={{
											border: '0.1px solid black',
											fontFamily: 'Roboto',
											fontSize: '14px',
											padding: '10px',
										}}
										width="10%"
									>
										{i.executive_summary_estimated_investment}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default ExecutiveSummeryPDF;
