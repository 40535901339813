import React, { useState } from 'react';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import IARPreparationStyles from './IARPreparation.module.css';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ExecutiveSummary from '../../components/iar/executiveSummary';
import ProcessFlowDiagram from '../../components/iar/processFlowDiagram';
import ScpImprovement from '../../components/iar/scpImprovement';
import OnsitePhotographs from '../../components/iar/onsitePhotographs';
import Modal from 'react-bootstrap/Modal';
// import ToggleButton from 'react-bootstrap/ToggleButton';
// import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import FrontPagePDF from '../pdf/FrontPage/FrontPage';
import LoaderImg from '../../assets/images/loadin_gif.gif';
import ExecutiveSummeryPDF from '../pdf/ExecutiveSummery/ExecutiveSummery';
import ProcessFlowPDF from '../pdf/ProcessFlow/ProcessFlow';
import SCPImprovementPDF from '../pdf/SCPImprovement/SCPImprovement';
import NotePDF from '../pdf/Note/Note';
import GalleryPDF from '../pdf/Gallery/Gallery';
import { useLocation } from 'react-router-dom';
import TcBreadCrumb from '../tc_breadcrumb/tc_breadcrumb';
import PfdObTab from '../pfdObTab/pfdObTab';
import FrontPagePreview from '../pdfPreview/FrontPagePreview/FrontPagePreview';
import ExecutiveSummeryPreview from '../pdfPreview/ExecutiveSummeryPreview/ExecutiveSummeryPreview';
import ProcessFlowPreview from '../pdfPreview/ProcessFlowPreview/ProcessFlowPreview';
import SCPImprovementPreview from '../pdfPreview/SCPImprovementPreview/SCPImprovementPreview';
import NotePreview from '../pdfPreview/NotePreview/NotePreview';
import GalleryPreview from '../pdfPreview/GalleryPreview/GalleryPreview';
import Footer from '../../assets/pdf_images/footerPP.png';
import LogoWithoutDescription from '../../assets/pdf_images/LogoWithoutDescription.png';
import switchAsiaNew from '../../assets/pdf_images/SwitchAsiaNew.png';
import Header from '../../assets/pdf_images/PartnerLogosNew.png';
// import Header from '../pdf/HeaderFooter/Header';
import logoLeft from '../../assets/pdf_images/logo.png';
import DataService from '../../services/DataServices';
import AuthUser from '../authUser/authuser';
import { useTranslation } from 'react-i18next';
import NoteIcon from '../../assets/images/notes.png';
import GalleryIcon from '../../assets/images/gallery.png';
import useIframeResize from '../customHooks/useIframeResize';
import useNavigation from '../customHooks/useLocationHook';
import { useCookies } from 'react-cookie';

const IARPreparation = () => {
	// if (window.innerHeight > 768) {
	// 	useIframeResize();
	// }
	const [, setCookie] = useCookies(['lastLocation']); // Define the cookie name

	//settting the current location info in cookie for enabler to pick it up
	useNavigation((location) => {
		const currentPath = location.pathname;

		setCookie('lastLocation', currentPath, {
			path: '/', // Set the cookie to be accessible across the domain
			// domain: 'localhost',
			domain: 'sustent.in', // Replace with your actual domain
			secure: true, // Set to true for secure cookies (HTTPS)
			sameSite: 'none',
		});
		// You can store the currentPath in state or perform other actions here
	});

	const api = new DataService();
	const { logout } = AuthUser();
	const { t } = useTranslation();
	let currentLocation = useLocation();
	let pathName = currentLocation.pathname;
	let com_id = pathName.substring(pathName.lastIndexOf('/') + 1);
	const [tcname, setTcname] = useState();
	const [executiveData, setexecutiveData] = useState();
	const [company, setCompany] = useState();
	const [scpImrovment, setScpImprovement] = useState();
	const [userSignature, setUserSignature] = useState();
	const [gallery, setGallery] = useState();
	const [pdfDataProcessflow, setPdfDataProcessflow] = useState();
	const [iarName, setIARName] = useState('');
	const [modalLoader, setModalLoader] = useState(false);
	const [IarVersionNumber, setIarVersionNumber] = useState();

	const GetPdfData = async (status) => {
		setModalLoader(true);
		let data = {
			company_id: com_id,
			isPreview: status === 1 ? 1 : 0,
		};
		await api
			.pdfdata(data)
			.then((response) => {
				let responsedata = response.data;
				setexecutiveData(JSON.parse(responsedata.pdfData[0].EXECUTIVE_SUMMARY));
				setCompany(JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY));
				const comapnyname = JSON.parse(responsedata.pdfData[0].COMPANY_ARRAY);
				setIARName(comapnyname[0].iar_version);
				localStorage.setItem('IARVersionPDF', comapnyname[0].iar_version);
				setIarVersionNumber(comapnyname[0].iar_version);
				let iarVerstion = comapnyname[0].iar_version;
				if (data.isPreview == 0 && iarVerstion != undefined) {
					setTimeout(() => {
						generatePDF(comapnyname[0].iar_version);
					}, 1000);
				}
				setPdfDataProcessflow(
					JSON.parse(responsedata.pdfData[0].PROCESS_FLOW_ARRAY)
				);
				const processFLow = JSON.parse(
					response?.data?.pdfData[0]?.PROCESS_FLOW_ARRAY
				);
				let resourceArray = [];
				processFLow.map((process) => {
					resourceArray.push(JSON.parse(process?.resource_json));
				});
				setTcname(JSON.parse(responsedata.pdfData[0].USER_SIGNATURE));
				setScpImprovement(
					JSON.parse(responsedata.pdfData[0].SCP_IMPROVEMENT_ARRAY)
				);
				setUserSignature(responsedata.base64Signature);
				setGallery(responsedata.linkedImg);
				setModalLoader(false);
			})
			.catch((error) => {
				if (error.code !== 'ERR_NETWORK') {
					let errorCode = error.response.status;
					if (errorCode === 401) {
						logout();
					}
				}
			});
	};
	const [DefaultTab, setDefaultTab] = useState(
		localStorage.getItem('defaultTab')
			? localStorage.getItem('defaultTab')
			: 'es'
	);

	const [showPDF, setShowPDF] = useState(false);
	const handleClosePDF = () => setShowPDF(false);

	const handleShowPDF = (status) => {
		setShowPDF(true);
		GetPdfData(status);
	};
	const tabClick = (key) => {
		let defaultTab = key.target.dataset.rrUiEventKey;
		setDefaultTab(defaultTab);
		localStorage.setItem('defaultTab', defaultTab);
	};
	const [loader, setLoader] = useState(false);
	const generatePDF = (Vnumber) => {
		setLoader(true);
		var element = document.getElementById('pdfContent');
		if (!element) {
			console.error("Element with id 'pdfContent' not found");
			setLoader(false);
			return;
		}

		var opt = {
			html2canvas: {
				dpi: 192,
				scale: 1.2,
				letterRendering: true,
				useCORS: true,
				scrollY: 0,
			},
			pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
			filename: Vnumber,
			margin: [26, 20],
			image: { type: 'jpeg', quality: 1 },
			jsPDF: { orientation: 'landscape' },
		};

		html2pdf()
			.from(element)
			.set(opt)
			.toPdf()
			.get('pdf')
			.then((pdf) => {
				var totalPages = pdf.internal.getNumberOfPages();
				for (let i = 2; i <= totalPages; i++) {
					// set footer to every page
					pdf.setPage(i);
					pdf.setFillColor(30, 152, 154);
					pdf.rect(0, 290, 600, 10, 'F');
					// set footer font
					pdf.setFontSize(11);
					pdf.setTextColor('#50AB92');
					// this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
					pdf.text(21, 10, '' + i + '');
					pdf.text(80, 10, 'Project implemented by');
					pdf.addImage(Header, 'png', 127, 1, 140, 15);
					pdf.addImage(LogoWithoutDescription, 'png', 19, 185, 30, 15);
					pdf.addImage(switchAsiaNew, 'png', 209, 185, 60, 25);
				}
				setLoader(false);
			})
			.catch((error) => {
				console.error('Error generating PDF', error);
				setLoader(false);
			})
			.save();
	};

	const downloadPDF = () => {
		GetPdfData();
	};

	return (
		<div className="deskWrapper">
			<div className="container">
				{window.innerWidth > 768 ? <TcBreadCrumb /> : null}
				<div className="row mt-2">
					<div
						className={
							window.innerWidth < 768
								? 'col-md-12 px-0' + ' ' + IARPreparationStyles.iar_preparation
								: 'col-md-12' + ' ' + IARPreparationStyles.iar_preparation
						}
					>
						{window.innerWidth > 768 ? (
							<Tabs
								defaultActiveKey={DefaultTab}
								justify
								className="tabPadding tabWebview"
								onClick={(key) => tabClick(key)}
							>
								<Tab
									eventKey="es"
									title={t('ExecutiveSummary')}
									className="tabItem"
								>
									<ExecutiveSummary />
								</Tab>
								<Tab
									eventKey="pfd"
									title={t('ProcessFlowDiagram')}
									className="tabItem"
								>
									<ProcessFlowDiagram />
								</Tab>
								<Tab
									eventKey="scpi"
									title={t('SCPImprovementOptions')}
									className="tabItem"
								>
									<ScpImprovement />
								</Tab>
								<Tab
									eventKey="osp"
									title={t('OnsitePhotographs')}
									className="tabItem"
								>
									<OnsitePhotographs tabActive={DefaultTab} />
								</Tab>
							</Tabs>
						) : (
							<PfdObTab />
						)}
					</div>
				</div>

				{/* <div className="row my-4">
                    <div className="col-md-4">
                    <button className='success_btn'>
                    Add New Observation
                    </button>
                    </div>
                    <div className="col-md-8 text-end">
                    <button className='secondary_btn small_btn'>
                    Redo
                    </button>
                    <button className='secondary_btn small_btn'>
                    Undo
                    </button>
                    <button className='primary_btn small_btn'>
                    Save
                    </button>
                    </div>
                    </div> */}

				<div className="row mt-4">
					<div className="col-md-6">
						{window.innerWidth > 768 ? (
							<div>
								<button className="secondary_btn">
									<Link to={'/checkpoints/notes/' + com_id}>
										<img src={NoteIcon} alt="notes" style={{ width: '17px' }} />{' '}
										{t('Notes')}
									</Link>
								</button>
								<button className="secondary_btn">
									<Link to={'/checkpoints/addgallery/' + com_id}>
										<img
											src={GalleryIcon}
											alt="Gallery"
											style={{ width: '17px' }}
										/>{' '}
										{t('Gallery')}
									</Link>
								</button>
							</div>
						) : null}
					</div>

					{window.innerWidth > 768 ? (
						<div className="col-md-6 text-end">
							<button
								className="secondary_btn"
								onClick={() => handleShowPDF(1)}
							>
								{t('PreviewDocument')}
							</button>
							<>
								{!loader ? (
									<button
										className="secondary_btn"
										style={{ backgroundColor: '#294D8F' }}
										onClick={() => downloadPDF(0)}
									>
										<span style={{ color: 'white' }}>
											{t('DownloadDraftIAR')}
										</span>
									</button>
								) : (
									<button
										className="secondary_btn pdfdownload"
										type="button"
										disabled
									>
										<span
											className="spinner-border spinner-border-sm"
											role="status"
											aria-hidden="true"
										></span>
										Downloading...
									</button>
								)}
							</>
						</div>
					) : null}
				</div>

				<div className="row">
					<div className="col-md-12"></div>
				</div>
			</div>
			<div className="pdf">
				<div id="content">
					<div id="pdfContent">
						<FrontPagePDF company={company} />
						<ExecutiveSummeryPDF
							executiveData={executiveData}
							company={company}
						/>
						<ProcessFlowPDF
							pdfDataProcessflow={pdfDataProcessflow}
							company={company}
						/>
						<SCPImprovementPDF scpImrovment={scpImrovment} />
						<NotePDF
							tcname={tcname}
							company={company}
							userSignature={userSignature}
						/>
						<GalleryPDF gallery={gallery} />
					</div>
				</div>
			</div>
			{modalLoader ? (
				<div className="loaderPDF">
					<img src={LoaderImg} alt="loaderImg" />
				</div>
			) : (
				<Modal
					className="galleryList newObservation"
					show={showPDF}
					onHide={handleClosePDF}
					size="xl"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title className="text-center">
							<h2>IAR Preview-{com_id}</h2>
						</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<div className="row mt-2">
							<div className="col-12 mb-3 pdfPreview">
								<div className="border">
									<FrontPagePreview company={company} />
								</div>
								<div className="border">
									<ExecutiveSummeryPreview
										executiveData={executiveData}
										company={company}
									/>
								</div>
								<div className="border">
									<ProcessFlowPreview
										pdfDataProcessflow={pdfDataProcessflow}
										company={company}
									/>
								</div>
								<div className="border">
									<SCPImprovementPreview scpImrovment={scpImrovment} />
								</div>
								<div className="border">
									<NotePreview
										tcname={tcname}
										company={company}
										userSignature={userSignature}
									/>
								</div>
								<div className="border">
									<GalleryPreview gallery={gallery} />
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			)}
		</div>
	);
};

export default IARPreparation;
