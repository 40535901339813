// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_headerText__zVyxh {
	color: #294d8f;
	font-weight: bold;
}
.style_label__GLZo9 {
	font-weight: 700;
	font-size: 22px;
	color: #294d8f;
}
.style_passWordStrongList__1yDcx {
	padding: 0px;
}
.style_passWordStrongList__1yDcx li {
	font-family: 'Roboto';
	position: relative;
	padding: 4px 0px;
	font-size: 12px;
}
.style_passWordStrongList__1yDcx li span:nth-child(2) {
	margin-top: 0px;
	font-size: 20px;
	position: absolute;
	right: 0;
	top: 0px;
}
.style_secondary_btnTwo__182uK {
	background-color: #ffffff;
	color: #294d8f;
	border: #8a9192 1px solid;
	padding: 8px 15px;
	font-size: 14px;
	border-radius: 6px;
	font-weight: 500;
	line-height: 1.5;
	margin: 2px;
	position: relative;
	display: inline-block;
	text-align: center;
	min-width: 80px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ChangePassword/style.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,iBAAiB;AAClB;AACA;CACC,gBAAgB;CAChB,eAAe;CACf,cAAc;AACf;AACA;CACC,YAAY;AACb;AACA;CACC,qBAAqB;CACrB,kBAAkB;CAClB,gBAAgB;CAChB,eAAe;AAChB;AACA;CACC,eAAe;CACf,eAAe;CACf,kBAAkB;CAClB,QAAQ;CACR,QAAQ;AACT;AACA;CACC,yBAAyB;CACzB,cAAc;CACd,yBAAyB;CACzB,iBAAiB;CACjB,eAAe;CACf,kBAAkB;CAClB,gBAAgB;CAChB,gBAAgB;CAChB,WAAW;CACX,kBAAkB;CAClB,qBAAqB;CACrB,kBAAkB;CAClB,eAAe;AAChB","sourcesContent":[".headerText {\r\n\tcolor: #294d8f;\r\n\tfont-weight: bold;\r\n}\r\n.label {\r\n\tfont-weight: 700;\r\n\tfont-size: 22px;\r\n\tcolor: #294d8f;\r\n}\r\n.passWordStrongList {\r\n\tpadding: 0px;\r\n}\r\n.passWordStrongList li {\r\n\tfont-family: 'Roboto';\r\n\tposition: relative;\r\n\tpadding: 4px 0px;\r\n\tfont-size: 12px;\r\n}\r\n.passWordStrongList li span:nth-child(2) {\r\n\tmargin-top: 0px;\r\n\tfont-size: 20px;\r\n\tposition: absolute;\r\n\tright: 0;\r\n\ttop: 0px;\r\n}\r\n.secondary_btnTwo {\r\n\tbackground-color: #ffffff;\r\n\tcolor: #294d8f;\r\n\tborder: #8a9192 1px solid;\r\n\tpadding: 8px 15px;\r\n\tfont-size: 14px;\r\n\tborder-radius: 6px;\r\n\tfont-weight: 500;\r\n\tline-height: 1.5;\r\n\tmargin: 2px;\r\n\tposition: relative;\r\n\tdisplay: inline-block;\r\n\ttext-align: center;\r\n\tmin-width: 80px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerText": `style_headerText__zVyxh`,
	"label": `style_label__GLZo9`,
	"passWordStrongList": `style_passWordStrongList__1yDcx`,
	"secondary_btnTwo": `style_secondary_btnTwo__182uK`
};
export default ___CSS_LOADER_EXPORT___;
