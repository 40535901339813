import Style from './GalleryPreview.module.css';
import project_implement_img8 from '../../../assets/pdf_images/Partner_Logos_Combined.png';
import switchasia from '../../../assets/pdf_images/Logo_-_Grants_-_transparent-01.png';
import logo from '../../../assets/pdf_images/logo.png';
import PartnerLogoNew from '../../../assets/pdf_images/PartnerLogosNew.png';
import LogowithoutDescription from '../../../assets/pdf_images/LogoWithoutDescription.png';
import switchAsiaNew from '../../../assets/pdf_images/SwitchAsiaNew.png';

const GalleryPreview = ({ gallery }) => {
	const perChunk = 2;
	//const [gallery, setGallery] = useState([])
	const result =
		gallery &&
		gallery.reduce((resultArray, item, index) => {
			const chunkIndex = Math.floor(index / perChunk);

			if (!resultArray[chunkIndex]) {
				resultArray[chunkIndex] = []; // start a new chunk
			}

			resultArray[chunkIndex].push(item);
			return resultArray;
		}, []);

	let tabeleContent = '';
	tabeleContent = (
		<>
			{result &&
				result.map((item, index) => (
					<tr key={index}>
						{item.map((subItem, idx) => (
							<td
								key={idx}
								style={{
									width: '50%',
									border: '1px solid black',
									textAlign: 'center',
								}}
							>
								<img
									src={`data:image/jpg;base64,${subItem.linkedImg}`}
									alt={subItem.gallery_caption}
									className="img-fluid "
									style={{
										width: 'auto',
										height: '200px',
										objectFit: 'contain',
										padding: '5px',
									}}
								/>
								<p className={Style.imgCaption}>
									<span>{subItem.gallery_name}: </span>
									{subItem.gallery_caption}
								</p>
							</td>
						))}
					</tr>
				))}
		</>
	);
	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					gap: '20px',
					alignItems: 'center',
					textAlign: 'end',
					paddingLeft: '13%',
					margin: '20px 100px 15px 10px',

					opacity: '0.7',
				}}
			>
				{' '}
				<span style={{ color: '#50AB92' }}>Project implemented by</span>
				<img src={PartnerLogoNew} width="370px" alt="headerImg" />
			</div>
			<div className={Style.custom_container_pageNine}>
				<p className={Style.header}>4.On-site Photographs</p>
				{/* <div>
                <div className={Style.custom_container_pageNine}>
                    {gallery && gallery.map((i, index) => {
                        return (
                            <div key={index} className={Style.gallery_img_div} style={{ display: "flex", pageBreakBefore: "auto", position: "relative" }}>
                                <div className={Style.imgdiv}>
                                    <img src={`data:image/jpg;base64,${i.linkedImg}`} width="130px" alt="galleryImg" />
                                    <p className={Style.imgCaption} >{i.gallery_name}:{i.gallery_caption}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}
				<table>
					<tbody>{tabeleContent}</tbody>
				</table>
				<div></div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'end',
						margin: '20px auto',
						opacity: '0.6',
					}}
				>
					<img src={LogowithoutDescription} width="120px" />
					<img src={switchAsiaNew} width="200px" />
				</div>
			</div>
			<div
				style={{
					backgroundColor: '#294D8F',
					marginTop: '18px',
					width: '815px',
				}}
			>
				<p
					style={{
						color: 'white',
						textAlign: 'center',
						fontSize: '12px',
						marginBottom: '0',
					}}
				>
					1
				</p>
			</div>
		</div>
	);
};
export default GalleryPreview;
